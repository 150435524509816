import { QuestionAverages } from '../../../../entities/v1/candidate_experience/QuestionAverages';
import { ApiService } from '../../../ApiService';
import { FilterParams, toJsonParams } from '../filterParams';

export class QuestionAveragesService {
  public static get(params: FilterParams): Promise<QuestionAverages> {
    return ApiService.get<QuestionAverages>(
      '/api/v1/candidate_experience/analytics/question_averages',
      toJsonParams(params),
    );
  }
}
