import React from 'react';
import { SelectOption } from '../../../../components/Select';
import { LabelledSelectFormHook } from '../../../../components/LabelledSelectFormHook';
import { Control } from 'react-hook-form';

interface PropTypes {
  label: string;
  required: boolean;
  formControl: Control<any>;
  disabled?: boolean;
}

export function YearsExperienceInput(props: PropTypes) {
  return (
    <LabelledSelectFormHook<any>
      label={props.label}
      placeholder={'Select years of relevant experience'}
      formHookContext={{
        controllerName: 'yearsExperience',
        formControl: props.formControl,
        required: props.required,
      }}
      options={YEAR_OPTIONS.map((year) => mapToSelectOption(year))}
      disabled={props.disabled}
      autoHideKeyboardOnSmallerScreen={true}
    />
  );
}

const YEAR_OPTIONS = ['0-2', '3-5', '6-10', '+10'];

function mapToSelectOption(year: string): SelectOption {
  return {
    value: year,
    label: year.split('-').join(' - '),
  };
}
