import React from 'react';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { User } from '../../../../../../entities/User';
import { Button } from 'reactstrap';
import { TextAreaInputFormField } from '../../../../../../components/TextAreaInputFormField';
import { useForm, useWatch } from 'react-hook-form';
import { AlertObject } from '../../../../../../components/Alert';
import { JobRequisitionService } from '../../../../../../services/v1/applicant_tracking/JobRequisitionService';
import { buildAndSetAlertMessage } from './ApproverActions';

const MAX_NOTE_LENGTH = 255;

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  onClose: () => void;
  setLoadingState: (loading: boolean) => void;
  setAlertMessage: (message: AlertObject) => void;
  reloadJobRequisition: () => void;
}

function ModalBody(props: PropTypes) {
  const { register, control } = useForm({ mode: 'onChange' });
  const note = useWatch({ name: 'declineReason', control });
  const declineRequisition = () => {
    JobRequisitionService.decline(props.jobRequisition.id, note)
      .then(() => {
        buildAndSetAlertMessage(
          props.setAlertMessage,
          'declined',
          props.jobRequisition,
          false,
        );
      })
      .catch(() => {
        buildAndSetAlertMessage(
          props.setAlertMessage,
          'declined',
          props.jobRequisition,
          true,
        );
      })
      .finally(() => {
        props.reloadJobRequisition();
        props.onClose();
      });
  };

  return (
    <div className='fs-5'>
      Are you sure you want to decline this job requisition?
      <div className='my-4'>
        <div className='d-flex justify-content-between'>
          <div className='fw-semibold'>
            Reason <span className='text-danger'>*</span>
          </div>
          <div className='fs-6 text-dark-200'>
            {note?.length ?? 0}/{MAX_NOTE_LENGTH}
          </div>
        </div>
        <div className='mt-2'>
          <TextAreaInputFormField
            registerReturn={register('declineReason', { required: true })}
            placeholder='Insert reason'
            classNames='fs-5'
            rows={3}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <Button color='secondary' className='me-3' onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={declineRequisition}
          disabled={
            !note || note?.length === 0 || note?.length > MAX_NOTE_LENGTH
          }
        >
          Decline
        </Button>
      </div>
    </div>
  );
}

export function DeclineModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={true}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Decline'
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
