import React, { useState } from 'react';
import { CloseableModal } from '../../CloseableModal';
import { ModalBody } from './ModalBody';
import { Alert, AlertType } from '../../Alert';

interface PropTypes {
  isOpen: boolean;
  mode: 'Add' | 'Edit';
  initNote?: string;
  disableAlerts?: boolean;
  onSubmit: (note: string) => Promise<void>;
  onClose: (succeeded?: boolean) => void;
  customValidator?: (note: string) => boolean;
}

export function NoteModal(props: PropTypes) {
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Info);
  const [alertMessage, setAlertMessage] = useState(null);
  const alertAction = props.mode === 'Add' ? 'added' : 'saved';

  const handleClose = (succeeded?: boolean) => {
    if (!props.disableAlerts) {
      if (succeeded) {
        setAlertType(AlertType.Success);
        setAlertMessage(<span>The note was successfully {alertAction}.</span>);
        setAlertActive(true);
      } else if (succeeded === false) {
        setAlertType(AlertType.Danger);
        setAlertMessage(<span>The note could not be {alertAction}.</span>);
        setAlertActive(true);
      }
    }

    props.onClose(succeeded);
  };

  return (
    <>
      {alertActive && (
        <Alert
          type={alertType}
          autoClearTimeout={3000}
          clearable={true}
          onClose={() => setAlertActive(false)}
        >
          {alertMessage}
        </Alert>
      )}
      {props.isOpen && (
        <CloseableModal
          className='modal-dialog-centered'
          onClose={props.onClose}
          isOpen={true}
          size='lg'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle={`${props.mode} Note`}
          bodyChildren={
            <ModalBody
              mode={props.mode}
              initNote={props.initNote}
              onSubmit={props.onSubmit}
              onClose={handleClose}
              customValidator={props.customValidator}
            />
          }
        />
      )}
    </>
  );
}
