import React from 'react';
import { Col, Row } from 'reactstrap';
import { TimezoneSelect } from '../../../../components/TimezoneSelect';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { IconSpan } from '../../../../components/IconSpan';
import {
  AvailabilityEvent,
  CronofyAvailabilityRules,
} from '../../../../components/cronofy/CronofyAvailabilityRules';
import { CronofyOptions } from '../../../../components/cronofy/CronofyOptions';

interface PropTypes {
  cronofyOptions: CronofyOptions;
  onCallback: (event: AvailabilityEvent) => void;
  timezone: string;
  setTimezone: (t: string) => void;
}

export function AvailabilityGrid(props: PropTypes) {
  return (
    <>
      <TopRow timezone={props.timezone} setTimezone={props.setTimezone} />
      <CronofyAvailabilityRules
        options={props.cronofyOptions}
        timezone={props.timezone}
        onCallback={props.onCallback}
      />
    </>
  );
}
function TopRow(props: {
  timezone: string;
  setTimezone: (timezone: string) => void;
}) {
  return (
    <Row className={'mb-4'}>
      <Col lg={5} className={'d-flex gap-3'}>
        <LegendIconSpan
          title={'Available'}
          iconName={'bi-square-fill'}
          iconClassName={styles['legend-icon-available']}
          className={'ps-2'}
        />
        <LegendIconSpan
          title={'Unavailable'}
          iconName={'bi-square'}
          iconClassName={'text-blue-gray-500'}
        />
      </Col>
      <Col lg={7} className={'d-flex'}>
        <TimezoneSelect
          timezone={props.timezone}
          setTimezone={props.setTimezone}
          className={'d-flex'}
          titleClassName={classNames('text-dark-500', 'fs-5', 'me-2h')}
          selectClassName={classNames('fs-6', styles['timezone-select'])}
        />
      </Col>
    </Row>
  );
}

function LegendIconSpan(props: {
  title: string;
  iconName: string;
  iconClassName: string;
  className?: string;
}) {
  return (
    <IconSpan
      spanText={props.title}
      icon={{
        name: props.iconName,
        className: classNames(props.iconClassName, 'border-radius-2'),
      }}
      className={props.className}
    />
  );
}
