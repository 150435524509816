import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { TriggersIcon } from '../../TriggersIcon';
import { JobStage } from '../../../../../entities/JobStage';
import { Trigger } from '../../../../../entities/applicant_tracking/Trigger';

interface PropTypes {
  jobStage: JobStage;
  triggers: Trigger[];
  jobPipelineTriggerIconEnabled: boolean;
}

export function JobStageHeader(props: PropTypes) {
  return (
    <div className={'d-flex align-items-center border-bottom'}>
      <h4
        className={classNames(
          'p-4',
          'mb-0',
          'text-truncate',
          'text-start',
          'bg-white',
          'fw-bold',
          styles['pipeline-job-stage-header'],
        )}
      >
        {props.jobStage.name}
      </h4>
      {props.jobPipelineTriggerIconEnabled && (
        <TriggersIcon jobStage={props.jobStage} triggers={props.triggers} />
      )}
    </div>
  );
}
