import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { SelectFormHook } from '../../../../../../../components/SelectFormHook';
import {
  AVAILABLE_CURRENCIES,
  DEFAULT_CURRENCY,
} from '../../../../../../../utils/currency';

interface PropTypes {
  setValue: UseFormSetValue<JobRequisitionDto>;
  formControl: Control<JobRequisitionDto>;
  formResetField: (controllerName: string) => void;
}

export function SalaryCurrency(props: PropTypes) {
  const required = false;

  return (
    <Col xs={3}>
      <FormFieldHeader
        fieldName='Currency'
        isRequired={required}
        classNames='fw-semibold mb-2h'
      />
      <SelectFormHook<JobRequisitionDto>
        placeholder={'Select currency'}
        formHookContext={{
          controllerName: 'salaryRangeCurrency',
          formControl: props.formControl,
          required: required,
        }}
        options={AVAILABLE_CURRENCIES}
        formResetField={props.formResetField}
        defaultValue={DEFAULT_CURRENCY.value}
        clearable={false}
      />
    </Col>
  );
}
