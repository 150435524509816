import React from 'react';
import { Label } from 'reactstrap';
import { JobPostingDetailsForm } from '..';
import { Select, SelectOption } from '../../../../../../components/Select';
import {
  AVAILABLE_CURRENCIES,
  DEFAULT_CURRENCY,
} from '../../../../../../utils/currency';

interface PropTypes {
  form: JobPostingDetailsForm;
  mandatory?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function SalaryCurrency(props: PropTypes) {
  const field = 'salaryCurrency';

  return (
    <div>
      <Label>
        Salary Currency{' '}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </Label>
      <div className='d-flex fs-5'>
        <Select
          placeholder={'Select currency'}
          options={AVAILABLE_CURRENCIES}
          onChange={(option: SelectOption) => {
            props.onInputChange(field, option.value?.toUpperCase());
          }}
          selected={{
            label: props.form.salaryCurrency || DEFAULT_CURRENCY.label,
            value: props.form.salaryCurrency || DEFAULT_CURRENCY.value,
          }}
          isClearable={false}
          className='w-100'
        />
      </div>
    </div>
  );
}
