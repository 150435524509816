import React from 'react';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { Step } from './Step';
import { User } from '../../../../../entities/v1/User';

interface PropTypes {
  steps: ApprovalFlowStep[];
  approversBreakLine?: boolean;
  className?: string;
  currentUser?: User;
  currentStepOrder?: number;
  declinedStepOrder?: number;
}

export function ApprovalFlowSummary(props: PropTypes) {
  return (
    <div className={props.className}>
      {props.steps.map((step, index) => (
        <Step
          key={index}
          index={index}
          step={step}
          totalSteps={props.steps.length}
          approversBreakLine={props.approversBreakLine}
          currentUser={props.currentUser}
          currentStepOrder={props.currentStepOrder}
          declinedStepOrder={props.declinedStepOrder}
        />
      ))}
    </div>
  );
}
