import { GlobalState, useStateMachine } from 'little-state-machine';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { IconButton } from '../../../../../components/IconButton';
import { IconSpan } from '../../../../../components/IconSpan';
import { RichTextEditor } from '../../../../../components/RichTextEditor';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { buildUrl, replaceFinalSegment } from '../../../../../utils/url';
import { JobPostPageHeader } from './JobPostPageHeader';
import { JobAndJobPost, initCreateJobDto } from '..';
import { getReferrer } from '../referrers';
import { LocationInput, loadLocationOptions } from './LocationInput';
import { EmploymentTypeInput } from './EmploymentTypeInput';
import { SelectOption } from '../../../../../components/Select';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { FormErrorMessage } from '../../../../../components/FormErrorMessage';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { AreaRichTextComposer } from '../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import { ExperienceLevelSelect } from './ExperienceLevelSelect';
import { JOB_POST_EDITOR_DEFAULT_STATE } from '../../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  editorState?: string;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
}

export function SetupJobPostPage(props: PropTypes) {
  const navigate = useNavigate();
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });
  const [createJobStore, setCreateJobStore] = useState<GlobalState>(state);

  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    resetField,
    setError,
    watch,
  } = useForm<CreateJobDto>({
    mode: 'onChange',
    defaultValues: props.initJobDto,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      setLocationOptions(await loadLocationOptions());

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    reset(props.initJobDto);
  }, [props.initJobDto]);

  const onSubmit = handleSubmit(async () => {
    try {
      const newstate = { ...state.setupJob, ...getValues() };
      const { jobPost } = await props.onSubmit(newstate);
      const nextStepUrl = replaceFinalSegment('application_form');

      actions.updateLocalStorage({ ...newstate, jobPostId: jobPost.id });

      props.setSetupStep(props.setupStep + 1, state.setupJob);
      navigate(nextStepUrl);
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }
  });

  const updateGlobalStateCallback = useCallback(
    (data: any) => {
      const updatedState = {
        ...createJobStore,
        setupJob: { ...createJobStore.setupJob, ...getValues(), ...data },
      };
      setCreateJobStore(updatedState);
      actions.updateLocalStorage(updatedState.setupJob);
    },
    [createJobStore],
  );

  const previewUrl = buildUrl(
    `${window.location.origin}/applicant_tracking/job_posts/preview`,
    [
      { key: 'name', value: watch('name') },
      { key: 'department_id', value: watch('departmentId') },
      { key: 'location_id', value: watch('locationId') },
      { key: 'location_type', value: watch('locationType') },
    ],
  );
  const onNavigate = handleSubmit(async () => {
    actions.updateLocalStorage({ ...getValues() });
  });

  return (
    <DeprecatedPanel className={'px-4 pt-4 pb-5 mb-5'}>
      <form onSubmit={onSubmit}>
        <FormErrorMessage error={formState.errors} />
        <JobPostPageHeader
          formState={formState}
          jobDetails={props.jobDetails}
          setupStep={props.setupStep}
          setSetupStep={props.setSetupStep}
          onCancel={() => {
            actions.updateLocalStorage(initCreateJobDto);
            window.location.href = getReferrer();
          }}
          onNavigate={onNavigate}
          isEditFlow={props.isEditFlow}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <LocationInput
              formControl={control}
              options={locationOptions}
              formResetField={resetField}
            />
            <EmploymentTypeInput
              formControl={control}
              formResetField={resetField}
            />
            <ExperienceLevelSelect formControl={control} />
            <Row>
              <div className='d-flex justify-content-between align-items-center col-8 mt-4 '>
                <div>
                  <FormFieldHeader
                    fieldName={'Description'}
                    isRequired={true}
                    classNames={'fw-semibold'}
                    fontSize={'fs-3'}
                  />
                  <small className='text-muted'>
                    Add job description, requirements and benefits
                  </small>
                </div>
                <IconButton
                  iconName='bi-box-arrow-up-right'
                  color='secondary'
                  buttonClass='text-info border-info'
                  buttonText='Preview'
                  disabled={!formState.isValid}
                  onClick={() => {
                    window.open(previewUrl, '_blank');
                  }}
                />
              </div>
            </Row>
            <Row className='mt-3'>
              <Col xs={8}>
                <RichTextEditor<CreateJobDto>
                  formHookContext={{
                    controllerName: 'editorState',
                    formControl: control,
                    required: true,
                  }}
                  render={(field) => (
                    <AreaRichTextComposer
                      defaultEditorState={
                        (field.value as string) ?? JOB_POST_EDITOR_DEFAULT_STATE
                      }
                      size='lg'
                      onChangeJson={(value: string) => field.onChange(value)}
                      onChangeHtml={(html: string) =>
                        updateGlobalStateCallback({ editorStateHtml: html })
                      }
                    />
                  )}
                />
              </Col>
              <Col xs={4}>
                <IconSpan
                  spanText='Consider adding dedicated sections to help candidates understand the role better.'
                  icon={{
                    name: 'bi-info-circle',
                    className: 'text-info-500 me-1',
                  }}
                  className={'fw-normal fs-5 bg-light-info px-3 py-2 rounded'}
                />
              </Col>
            </Row>
          </>
        )}
      </form>
    </DeprecatedPanel>
  );
}
