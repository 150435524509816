import { StylesConfig } from 'react-select';
import stylesheetExports from '../../stylesheets/export.module.scss';
import { Size } from '../../utils/types/Size';
import { capitalize } from 'humanize-plus';

function optionColor(state) {
  if (state.isDisabled) return stylesheetExports.dark200;

  if (state.isFocused || state.isSelected) return stylesheetExports.blue;

  return stylesheetExports.dark500;
}

function optionBackgroundColor(state) {
  if (state.isSelected && !state.isMulti) return stylesheetExports.blue;

  if (state.isFocused) return stylesheetExports.blueLight;

  return stylesheetExports.white;
}

function padding(size: Size) {
  switch (size) {
    case 'sm':
      return '4px 8px';
    case 'lg':
      return '8px 16px';
    case 'md':
    default:
      return '8px 12px';
  }
}

function fontSize(size: Size) {
  switch (size) {
    case 'lg':
      return stylesheetExports.fontSize4;
    default:
      return stylesheetExports.fontSize5;
  }
}

export const selectStyle = (size: Size): StylesConfig => {
  return {
    clearIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? stylesheetExports.blue
        : stylesheetExports.dark500,
      '&:hover': {
        color: state.isFocused
          ? stylesheetExports.blue
          : stylesheetExports.gray900,
      },
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled
        ? stylesheetExports.gray
        : stylesheetExports.white,
      borderColor: state.isDisabled
        ? stylesheetExports.gray300
        : state.isFocused
        ? stylesheetExports.blue
        : stylesheetExports.blueGray500,
      boxShadow: 'none',
      alignContent: 'center',
      height: stylesheetExports[`height${capitalize(size)}`],
      minHeight: stylesheetExports[`height${capitalize(size)}`],
      padding: padding(size),
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? stylesheetExports.blue
        : stylesheetExports.dark500,
      '&:hover': {
        color: state.isFocused
          ? stylesheetExports.blue
          : stylesheetExports.gray900,
      },
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%',
      alignSelf: 'auto',
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'normal',
      textOverflow: 'ellipsis',
      '&:hover': {
        color: optionColor(state),
        backgroundColor: stylesheetExports.blueLight,
      },
      color: state.isDisabled
        ? stylesheetExports.dark200
        : state.isMulti
        ? stylesheetExports.dark500
        : base.color,
      backgroundColor: optionBackgroundColor(state),
      fontSize: fontSize(size),
    }),
    placeholder: (base) => ({
      ...base,
      color: stylesheetExports.dark200,
      fontWeight: stylesheetExports.fontWeightRegular,
      fontSize: fontSize(size),
    }),
    singleValue: (base, state) => ({
      ...base,
      margin: 0,
      color: state.isDisabled
        ? stylesheetExports.gray700
        : stylesheetExports.dark500,
      fontSize: fontSize(size),
    }),
    valueContainer: (base) => ({
      ...base,
      alignSelf: 'center',
      padding: '0px',
      margin: 0,
      gap: '4px',
    }),
    menuList: (base) => ({
      ...base,
      color: stylesheetExports.dark500,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 102, // when opened needs to be be above all components, except modals and alerts.
    }),
  };
};
