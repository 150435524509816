import React, { useEffect, useState } from 'react';
import * as CronofyElements from 'cronofy-elements';
import { CronofyOptions } from '../CronofyOptions';

const ELEMENT_DIV_ID = 'cronofy-availability-rules';
export const AVAILABILITY_RULE_ID = 'interview_availability';

interface PropTypes {
  options: CronofyOptions;
  timezone: string;
  onCallback: (event: AvailabilityEvent) => void;
}

interface EventNotification {
  type: string;
}

interface WeeklyPeriod {
  day: string;
  start_time: string;
  end_time: string;
}

export interface AvailabilityRule {
  availability_rule_id: string;
  tzid: string;
  weekly_periods: WeeklyPeriod[];
}

export interface AvailabilityEvent {
  notification: EventNotification;
  availability_rule?: AvailabilityRule;
}

function defaultWeeklyPeriod(weekDay: string) {
  return {
    day: weekDay,
    start_time: '09:00',
    end_time: '18:00',
  };
}

export function defaultWeeklyPeriods() {
  return [
    defaultWeeklyPeriod('monday'),
    defaultWeeklyPeriod('tuesday'),
    defaultWeeklyPeriod('wednesday'),
    defaultWeeklyPeriod('thursday'),
    defaultWeeklyPeriod('friday'),
  ];
}

function buildOptions(props: PropTypes) {
  return {
    element_token: props.options.elementToken,
    target_id: ELEMENT_DIV_ID,
    availability_rule_id: AVAILABILITY_RULE_ID,
    config: {
      start_time: '07:00',
      end_time: '19:00',
      duration: 30,
      default_weekly_periods: defaultWeeklyPeriods(),
    },
    styles: {
      colors: {
        available: '#21D9BDB2',
        unavailable: 'white',
      },
      prefix: 'cronofy',
    },
    tzid: props.timezone,
    callback: (event) => props.onCallback(event),
    data_center: props.options.dataCenter,
  };
}

export function CronofyAvailabilityRules(props: PropTypes) {
  const [element, setElement] = useState(null);

  const options = buildOptions({ ...props });

  useEffect(() => {
    if (!element) {
      setElement(CronofyElements.AvailabilityRules(options));
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.update(options);
    }
  }, [props.timezone]);

  return <div id={ELEMENT_DIV_ID}></div>;
}
