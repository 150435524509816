import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { EmptyState } from '../../../../components/EmptyState';
import { CreateJobPageRoutes } from '../CreateJobPageRoutes';
import { SetupJobPageRoutes } from '../SetupJobPageRoutes';
import { JobPage } from '../../../JobPage';
import { EditJobPage } from '../EditJobPage';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { JobPageTab } from '../../../JobPage/JobPageTab';
import { AccountIntegration } from '../../../../entities/AccountIntegration';

interface PropTypes {
  emailAccountIntegration: AccountIntegration;
  jobBoardEnabled: boolean;
  publishEnabled: boolean;
  standardNpsCalculation: boolean;
  hrisProvider?: HrisProvider;
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  organicPostSkillsEnabled: boolean;
  selfSchedulingAutomationEnabled: boolean;
  jobPipelineTriggerIconEnabled: boolean;
}

function JobPageRouteTab(props: PropTypes & { activeTab: JobPageTab }) {
  return (
    <JobPage
      activeTab={props.activeTab}
      jobBoardEnabled={props.jobBoardEnabled}
      publishEnabled={props.publishEnabled}
      emailAccountIntegration={props.emailAccountIntegration}
      standardNpsCalculation={props.standardNpsCalculation}
      jobPipelineTriggerIconEnabled={props.jobPipelineTriggerIconEnabled}
    />
  );
}

export function ApplicantTrackingJobsApp(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'jobs'}>
          <Route path={'new/*'} element={<CreateJobPageRoutes />} />
          <Route
            path={'setup/*'}
            element={
              <SetupJobPageRoutes
                hrisProvider={props.hrisProvider}
                jobRequisitionEnabled={props.jobRequisitionEnabled}
                jobRequisitionMandatory={props.jobRequisitionMandatory}
                updateJobRequisitionEnabled={true}
                organicPostSkillsEnabled={props.organicPostSkillsEnabled}
                selfSchedulingAutomationEnabled={
                  props.selfSchedulingAutomationEnabled
                }
              />
            }
          />
          <Route
            path={':id/edit/*'}
            element={
              <EditJobPage
                hrisProvider={props.hrisProvider}
                jobRequisitionMandatory={props.jobRequisitionMandatory}
                organicPostSkillsEnabled={props.organicPostSkillsEnabled}
                selfSchedulingAutomationEnabled={
                  props.selfSchedulingAutomationEnabled
                }
              />
            }
          />
          <Route
            path={':id/overview'}
            element={<JobPageRouteTab {...props} activeTab='Overview' />}
          />
          <Route
            path={':id/pipeline'}
            element={<JobPageRouteTab {...props} activeTab='Pipeline' />}
          />
          {props.jobBoardEnabled && (
            <Route
              path={':id/job_boards'}
              element={<JobPageRouteTab {...props} activeTab='Job Boards' />}
            />
          )}
          <Route
            path={':id'}
            element={<JobPageRouteTab {...props} activeTab='Overview' />}
          />
          <Route
            path='*'
            element={
              <EmptyState
                title={'Page not found!'}
                text={
                  'For more information contact our support team at support@screenloop.com'
                }
                cardless={true}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
