import snakecaseKeys from 'snakecase-keys';
import { JobList } from '../../../../entities/v1/JobList';
import { ApiService } from '../../../ApiService';
import { HiringMemberList } from '../../../../entities/v1/HiringMemberList';

interface Params {
  name?: string;
  page?: number;
  pageSize?: number;
}

export class AnalyticsFiltersService {
  public static jobs(params: Params) {
    return ApiService.get<JobList>(
      '/api/v1/interview_intelligence/analytics/jobs',
      snakecaseKeys(params),
    );
  }

  public static interviewers(params: Params) {
    return ApiService.get<HiringMemberList>(
      '/api/v1/interview_intelligence/analytics/interviewers',
      snakecaseKeys(params),
    );
  }
}
