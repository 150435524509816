import React from 'react';
import classNames from 'classnames';
import { CompanyInfo } from '../../../entities/CompanyInfo';
import logo from '../../../images/logo-white.png';
import styles from './styles.module.scss';

interface PropTypes {
  companyInfo?: CompanyInfo;
  whitelabelEnabled: boolean;
}

function getBackgroundStyle(
  whitelabelEnabled: boolean,
  backgroundColor?: string,
) {
  if (!whitelabelEnabled) return {};

  return { backgroundColor: backgroundColor };
}

export function LogoWithBackgroundHeader(props: PropTypes) {
  const backgroundColor = props.companyInfo?.backgroundColor;
  const backgroundStyle = getBackgroundStyle(
    props.whitelabelEnabled,
    backgroundColor,
  );

  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center py-3h py-sm-5',
        {
          [styles['default-background']]: !props.whitelabelEnabled,
        },
      )}
      style={backgroundStyle}
    >
      <img
        src={props.companyInfo?.logoUrl || logo}
        className={classNames(styles['responsive-image'])}
      />
    </div>
  );
}
