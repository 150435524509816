import React, { useEffect } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { Trigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapIntToSelectOptions } from '../../triggerSelectOptions';

interface PropTypes {
  trigger: Trigger;
  delayOptions: SelectOption[];
  setDelayOptions: (options: SelectOption[]) => void;
}

export function DelayDropdown(props: PropTypes) {
  const initDelayOptions = mapIntToSelectOptions(
    props.trigger.delayDays?.toString() || '0',
  );

  useEffect(() => {
    props.setDelayOptions(initDelayOptions);
  }, []);

  return (
    <TriggerRowDropdown className={'col-1'}>
      <LabelledSelect
        options={props.delayOptions}
        selected={props.delayOptions.find((v: SelectOption) => v.selected)}
        labelClassName='fs-5'
        label={'Delay Days'}
        placeholder='Select days'
        onChange={(o) => props.setDelayOptions(mapIntToSelectOptions(o.value))}
      />
    </TriggerRowDropdown>
  );
}
