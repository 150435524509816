import {
  FilterStates,
  OnChangeFilterStatesCallback,
  SingleValueFilterState,
} from '../../../../components/candidate_experience/Filters/filterState';
import { Filters } from '../../../../components/candidate_experience/Filters';
import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';
import { HiringManagerAnalytics } from '../../../../entities/candidate_experience/HiringManagerAnalytics';
import { QuestionAveragesPanel } from '../../shared_panels/QuestionAveragesPanel';
import { OverallNpsPanels } from '../../../../components/survey_engine/OverallNpsPanels';

interface PagePropTypes {
  analytics: HiringManagerAnalytics;
  filterStates: FilterStates;
  showMoreFilters: boolean;
  pulseStandardNpsCalculation: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
}

function hasAnalytics(analytics: HiringManagerAnalytics) {
  return Object.keys(analytics).length > 0 && analytics.rps.value !== null;
}

function getTitleFromTag(title: string) {
  switch (title) {
    case 'hiring_manager_overall_assessment':
      return 'Overall Assessment';
    case 'hiring_manager_candidate_quality':
      return 'Candidate Quality';
    case 'hiring_manager_job_spec':
      return 'Job Spec';
    default:
      return 'Process';
  }
}

function Body(props: PagePropTypes) {
  return (
    <div className='d-flex flex-column mt-3'>
      <OverallNpsPanels
        npsTitle='Recruiter NPS'
        npsDescription="Recruiter NPS is the hiring manager's score of the overall process experience."
        npsPromotersText='Promoters'
        npsNeutralsText='Neutrals'
        npsDetractorsText='Detractors'
        nps={props.analytics.rps}
        npsHistory={props.analytics.rpsHistory}
        npsTrend={props.analytics.rpsTrend}
        npsBenchmark={props.analytics.rpsBenchmark}
        dateRange={props.filterStates.dateRange}
        exportToPDF={false}
        standardNpsCalculation={props.pulseStandardNpsCalculation}
      />
      {props.analytics.questionAverages.map((questionAverageDto) => (
        <QuestionAveragesPanel
          key={questionAverageDto.tag}
          questionAverages={questionAverageDto.questionAverages}
          benchmarkType={props.filterStates.benchmarkType.selected}
          title={getTitleFromTag(questionAverageDto.tag)}
        />
      ))}
    </div>
  );
}

function hideHiringMemberFilters(
  filterSelector: SingleValueFilterState,
): SingleValueFilterState {
  const filtersToHide = ['hiring_managers', 'recruiters'];
  const defaultSelection = {
    label: 'Departments',
    value: 'departments',
  };

  filterSelector.all = filterSelector.all.filter(
    (option) => !filtersToHide.includes(option.value),
  );

  if (filtersToHide.includes(filterSelector.selected.value)) {
    filterSelector.selected = defaultSelection;
  }

  return filterSelector;
}

export function HiringManagerPulseAnalytics(props: PagePropTypes) {
  const filterStates = props.filterStates;
  filterStates.filterSelector = hideHiringMemberFilters(
    props.filterStates.filterSelector,
  );

  return (
    <main>
      <Filters
        filterStates={filterStates}
        onApplyFilter={props.onApplyFilter}
        moreFiltersShown={props.showMoreFilters}
        className='mb-4'
        hideMoreFiltersButton={true}
        hideHireTeam={true}
      />
      {hasAnalytics(props.analytics) ? (
        <Body {...props} />
      ) : (
        <EmptyState
          title='No hiring manager feedback available yet'
          text={
            <p>
              Please try a different combination of filters or{' '}
              <a className='link-info' href='mailto:support@screenloop.com'>
                talk with us
              </a>{' '}
              to start collecting hiring manager feedback in every step of the
              way.
            </p>
          }
        />
      )}
    </main>
  );
}
