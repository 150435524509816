import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { JobPost } from '../../../../../../../entities/applicant_tracking/JobPost';
import { JobPostCardChip } from './JobPostCardChip';
import { JobPostCardTitle } from './JobPostCardTitle';
import styles from './styles.module.scss';
import { buildUrl, getUrlOriginPath } from '../../../../../../../utils/url';

interface PropTypes {
  jobPost: JobPost;
  whitelabelEnabled: boolean;
}

interface JobPostCardContainerPropTypes {
  jobPost: JobPost;
  whitelabelEnabled: boolean;
  children: React.ReactNode;
}

function JobPostCardContainer(props: JobPostCardContainerPropTypes) {
  const jobPostUrl = buildUrl(
    `${getUrlOriginPath()}/job_posts/${props.jobPost.id}?tab=overview`,
    [],
  );

  return (
    <a
      className={classNames(
        'card mb-3 app-card-body py-3 ps-4 pe-6h ps-sm-6h border rounded-3 shadow-sm',
        props.whitelabelEnabled ? styles['card-whitelabelled'] : styles.card,
      )}
      href={jobPostUrl}
    >
      {props.children}
    </a>
  );
}

export function JobPostCard(props: PropTypes) {
  return (
    <JobPostCardContainer
      whitelabelEnabled={props.whitelabelEnabled}
      jobPost={props.jobPost}
    >
      <Row>
        <Col xs={8}>
          <JobPostCardTitle
            title={props.jobPost.name}
            whitelabelEnabled={props.whitelabelEnabled}
          />
          <div className='d-flex flex-nowrap gap-2 pt-3'>
            <JobPostCardChip
              title={props.jobPost.job.department.name}
              whitelabelEnabled={props.whitelabelEnabled}
            />
            <JobPostCardChip
              title={props.jobPost.location.name}
              whitelabelEnabled={props.whitelabelEnabled}
            />
            <JobPostCardChip
              title={props.jobPost.job.locationType}
              whitelabelEnabled={props.whitelabelEnabled}
            />
          </div>
        </Col>
        <div className='col-4 align-self-center text-end'>
          <span className='fs-5 fw-normal text-info'>Apply</span>
        </div>
      </Row>
    </JobPostCardContainer>
  );
}
