import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { SelectOption } from '../../../components/Select';
import { JobSelect } from '../../Filters/JobSelect';
import { parsePage } from '../../FilterStates/PageFilter';
import { InterviewerSelect } from '../../Filters/InterviewerSelect';
import { parseJob } from '../../FilterStates/JobFilter';
import { parseInterviewer } from '../../FilterStates/InterviewerFilter';

export interface FilterStates {
  page?: number;
  job?: SelectOption;
  interviewer?: SelectOption;
}

export function parseCurrentFilterState(): FilterStates {
  return {
    page: parsePage(),
    job: parseJob(),
    interviewer: parseInterviewer(),
  };
}

export default function InterviewDropdownFilters() {
  const [filterStates, setFilterStates] = useState<FilterStates>(
    parseCurrentFilterState(),
  );
  const [navigate, setNavigate] = useState(false);

  const navigateToInterviews = useCallback(() => {
    const { job, interviewer } = filterStates;
    let url = '/interviews';

    if (job || interviewer) {
      const params = new URLSearchParams();
      if (job) {
        params.append('job_id', job.value);
        params.append('job_name', job.label);
      }
      if (interviewer) {
        params.append('interviewer_id', interviewer.value);
        params.append('interviewer_name', interviewer.label);
      }
      url = `/interviews?${params.toString()}`;
    }

    window.location.href = url;
    setNavigate(false);
  }, [filterStates]);

  useEffect(() => {
    if (navigate) {
      navigateToInterviews();
    }
  }, [navigate, navigateToInterviews]);

  const handleJobChange = (opt: SelectOption) => {
    if (filterStates.job?.value !== opt?.value) {
      setFilterStates({
        ...filterStates,
        job: opt,
        page: 1,
      });
      setNavigate(true);
    }
  };

  const handleInterviewerChange = (opt: SelectOption) => {
    if (filterStates.interviewer?.value !== opt?.value) {
      setFilterStates({
        ...filterStates,
        interviewer: opt,
        page: 1,
      });
      setNavigate(true);
    }
  };

  return (
    <Row className='justify-content-end gx-3'>
      <Col xs={6}>
        <JobSelect
          disabled={false}
          selected={filterStates.job ?? null}
          onChange={handleJobChange}
        />
      </Col>
      <Col xs={6}>
        <InterviewerSelect
          disabled={false}
          selected={filterStates.interviewer ?? null}
          onChange={handleInterviewerChange}
        />
      </Col>
    </Row>
  );
}
