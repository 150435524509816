import React from 'react';
import { PropTypes } from '../AttachmentInputBody';
import { Path } from 'react-hook-form';
import { Button } from 'reactstrap';
import { TruncateText } from '../../TruncateText';
import { sizeSmallerThan, useScreenSize } from '../../../utils/useScreenSize';
import { IconSpan } from '../../IconSpan';

export function ResumeParserInputBody<E>(props: PropTypes<E>) {
  const smallerScreen = sizeSmallerThan(useScreenSize(), 'sm');

  return (
    <div className='d-flex align-items-center gap-2 bg-light-info p-3 rounded border flex-column flex-sm-row align-content-center'>
      <input
        type='hidden'
        {...props.formRegister(props.controllerContentName as Path<E>, {
          required: props.isRequired,
        })}
        disabled={props.disabled}
      />
      <div className='fw-semibold fs-5 d-flex align-items-center'>
        <IconSpan
          spanText='Autofill with Resume'
          icon={{ name: 'bi-file-earmark-arrow-up', className: 'fs-4' }}
        />
      </div>
      <div className='fw-normal fs-5 text-muted ms-3'>
        <TruncateText
          text={props.value?.toString() ?? 'Upload resume to autofill'}
          ellipsize={true}
          tooltip={true}
          maxChars={25}
        />
      </div>
      <div className={smallerScreen ? 'w-100 mt-2' : 'ms-auto'}>
        <Button
          className={smallerScreen ? 'w-100' : ''}
          size={smallerScreen ? 'lg' : 'sm'}
          color='primary'
          disabled={props.disabled}
          onClick={() => props.openFileSelector()}
        >
          Browse File
        </Button>
      </div>
    </div>
  );
}
