import React from 'react';
import { Control } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { SelectFormHook } from '../../../../../../components/SelectFormHook';
import { IconButton } from '../../../../../../components/IconButton';

interface PropTypes {
  index: number;
  memberOptions: SelectOption[];
  visibleOptions?: SelectOption[];
  selectedMember?: SelectOption;
  roleOptions: SelectOption[];
  selectedRole?: SelectOption;
  formControl: Control<CreateJobDto>;
  onDeleteElement: () => void;
  arrayData: any;
  updateArrayData: (data: any) => void;
}

export function HiringTeamMemberInput(props: PropTypes) {
  const arrayName = 'hiringTeamMembers';
  const userIdAttribute = 'userId';
  const roleIdAttribute = 'roleId';

  return (
    <Row className='mt-3 align-items-center'>
      <Col xs={3}>
        <SelectFormHook<CreateJobDto>
          placeholder={'Select team member'}
          formHookContext={{
            controllerName:
              `${arrayName}.${props.index}.${userIdAttribute}` as `hiringTeamMembers.${number}.userId`,
            formControl: props.formControl,
            required: true,
          }}
          options={props.memberOptions}
          visibleOptions={props.visibleOptions}
          arrayContext={{
            arrayData: props.arrayData,
            updateArrayData: props.updateArrayData,
            getArrayMetadata: () => ({
              name: arrayName,
              index: props.index,
              attribute: userIdAttribute,
            }),
          }}
          clearable={false}
        />
      </Col>
      <Col xs={3} className={''}>
        <SelectFormHook<CreateJobDto>
          placeholder={'Select Role'}
          formHookContext={{
            controllerName:
              `${arrayName}.${props.index}.${roleIdAttribute}` as `hiringTeamMembers.${number}.roleId`,
            formControl: props.formControl,
            required: true,
          }}
          options={props.roleOptions}
          arrayContext={{
            arrayData: props.arrayData,
            updateArrayData: props.updateArrayData,
            getArrayMetadata: () => ({
              name: arrayName,
              index: props.index,
              attribute: roleIdAttribute,
            }),
          }}
          clearable={false}
        />
      </Col>
      <Col xs={'auto'} className={'ps-0'}>
        <IconButton
          color='danger-borderless'
          iconName='bi-trash'
          iconClass='fs-4'
          onClick={props.onDeleteElement}
        />
      </Col>
    </Row>
  );
}
