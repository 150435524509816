import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { DataPrivacySettingsForm } from '..';
import { Header } from '../Header';
import { LabelledToggle } from '../../../../../components/LabelledToggle';
import { Chip } from '../../../../../components/Chip';
import classNames from 'classnames';

export function Redaction(props: {
  form: UseFormReturn<DataPrivacySettingsForm, any>;
}) {
  const enabled = !!props.form.watch('redactionEnabled');

  return (
    <div>
      <Row>
        <Col xs='10'>
          <Header
            title='Anonymized Candidate Screening'
            titleChip={
              <Chip
                className={classNames(
                  'text-primary',
                  enabled ? 'bg-light-success' : 'bg-light-danger',
                )}
              >
                {enabled ? 'Enabled' : 'Disabled'}
              </Chip>
            }
            subtitle='Enable to allow the candidate information anonymisation in specific job stages while editing your job workflow.'
          />
        </Col>
        <Col className='align-self-center' xs='2'>
          <LabelledToggle
            inputName='redactionEnabled'
            className='justify-content-end'
            size='sm'
            formHookContext={{
              controllerName: 'redactionEnabled',
              formControl: props.form.control,
            }}
          />
        </Col>
      </Row>
    </div>
  );
  // SL-4963
  // Uncomment and add above <RedactInfoDetail form={props.form} />
}

// const redactedInfo = [
//   'headshot',
//   'personalDetails',
//   'workDetails',
//   'referees',
//   'educationDetails',
//   'locations',
//   'dates',
//   'gender',
// ] as const;

// function label(controllerName: typeof redactedInfo[number]): string {
//   switch (controllerName) {
//     case 'headshot':
//       return 'Headshot';
//     case 'personalDetails':
//       return 'Personal Details';
//     case 'workDetails':
//       return 'Work Details';
//     case 'referees':
//       return 'Referees';
//     case 'educationDetails':
//       return 'Education Details';
//     case 'locations':
//       return 'Locations';
//     case 'dates':
//       return 'Dates';
//     case 'gender':
//       return 'Gender';
//   }
// }

// function subLabel(controllerName: typeof redactedInfo[number]): string {
//   switch (controllerName) {
//     case 'headshot':
//       return 'Candidates photo';
//     case 'personalDetails':
//       return 'Name, Phone Number, Email';
//     case 'workDetails':
//       return 'Portfolio, LinkedIn profile, Company names, Years of Relevant Experience';
//     case 'referees':
//       return 'Redact referee details';
//     case 'educationDetails':
//       return 'Colleges and Universities';
//     case 'locations':
//       return 'Candidates locations, both personal and professional';
//     case 'dates':
//       return 'Candidates dates, both personal and professional';
//     case 'gender':
//       return 'Candidates gender';
//     default:
//       return '';
//   }
// }

// function Checkbox(props: {
//   controllerName: typeof redactedInfo[number];
//   disabled: boolean;
//   register: UseFormRegister<DataPrivacySettingsForm>;
// }) {
//   const { onChange, onBlur, name, ref } = props.register(props.controllerName);

//   return (
//     <div>
//       <CheckboxButton
//         label={
//           <label className='fs-5 fw-semibold'>
//             {label(props.controllerName)}
//           </label>
//         }
//         labelClasses='fs-5 fw-semibold'
//         onBlur={onBlur}
//         name={name}
//         disabled={props.disabled}
//         checkboxRef={ref}
//         onChange={(ev: React.FormEvent<HTMLInputElement>) => {
//           onChange(ev ?? null);
//         }}
//       />
//       <label className='mt-2 text-dark-200 fw-normal fs-5'>
//         {subLabel(props.controllerName)}
//       </label>
//     </div>
//   );
// }

// function RedactInfoDetail(props: {
//   form: UseFormReturn<DataPrivacySettingsForm, any>;
// }) {
//   const enabled = !!props.form.watch('redactionEnabled');

//   return (
//     <div className='d-flex flex-column gap-3 border rounded-2 border-blue-gray-500 px-4 py-3'>
//       <span>
//         <label className='fs-5 fw-semibold'>Information to redact</label>
//         <InfoTooltip tooltipInnerClassName={styles['tooltip']} className='ms-2'>
//           Select fields to redact on the UI and CV. Redacting candidate
//           information will anonymise or remove them from the user interface.
//         </InfoTooltip>
//       </span>
//       {redactedInfo.map(
//         (controllerName: typeof redactedInfo[number], index: number) => (
//           <Checkbox
//             key={index}
//             controllerName={controllerName}
//             disabled={!enabled}
//             register={props.form.register}
//           />
//         ),
//       )}
//     </div>
//   );
// }
