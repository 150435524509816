import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { AreaRichTextComposer } from '../../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { RichTextEditor } from '../../../../../../../components/RichTextEditor';
import { ExportPlainTextPlugin } from '../../../../../../../components/RichTextEditor/plugins/ExportPlainTextlPlugin';
import { MaxLengthPlugin } from '../../../../../../../components/RichTextEditor/plugins/MaxLengthPlugin';

const MAX_LENGTH = 255;

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
  getValues: UseFormGetValues<JobRequisitionDto>;
  setValue: UseFormSetValue<JobRequisitionDto>;
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function Description(props: PropTypes) {
  const required = false;

  const description = useWatch({
    control: props.formControl,
    name: 'description',
  });

  return (
    <Col xs={6}>
      <div className='d-flex justify-content-between'>
        <FormFieldHeader
          fieldName='Description'
          isRequired={required}
          classNames='fw-semibold mb-0'
          fontSize='fs-5'
        />
        <div className='fs-6 text-dark-200'>
          {description?.length ?? 0}/{MAX_LENGTH}
        </div>
      </div>
      <RichTextEditor<JobRequisitionDto>
        formHookContext={{
          controllerName: 'descriptionEditorState',
          formControl: props.formControl,
          required: required,
        }}
        render={(field) => (
          <AreaRichTextComposer
            defaultEditorState={field.value?.toString()}
            placeholder='Insert description'
            onChangeJson={(value: string) => field.onChange(value)}
            onChangeHtml={(html: string) =>
              props.setValue('descriptionHtml', html)
            }
            size='xs'
            externalPlugins={[
              <ExportPlainTextPlugin
                key={`${field.name}ExportPlainTextPlugin`}
                onChangeText={(text) => props.setValue('description', text)}
              />,
              <MaxLengthPlugin
                key={`${field.name}MaxLengthPlugin`}
                maxLength={MAX_LENGTH}
              />,
            ]}
          />
        )}
      />
    </Col>
  );
}
