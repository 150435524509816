import React, { useEffect, useState } from 'react';
import { SurveyMetricsTitle } from './SurveyMetricsTitle';
import { AnalyticsPanel } from '../../AnalyticsPanel';
import { SurveyMetrics } from '../../../entities/survey_engine/SurveyMetrics';
import { SurveyMetricsContent } from './SurveyMetricsContent';
import { LazyContent } from '../LazyContent';
import { FilterStates } from '../../candidate_experience/Filters/filterState';
import { SurveyMetricsService } from '../../../services/v1/candidate_experience/SurveyMetricsService';
import { fromFilterState } from '../../../services/v1/candidate_experience/filterParams';

interface PropTypes {
  // Either the surveyMetrics (for sync rendering) or the filterStates (for
  // async rendering) need to be passed.
  surveyMetrics?: SurveyMetrics;
  filterStates?: Partial<FilterStates>;
  benchmarkLabel: string;
  isLoading?: boolean;
}

export function SurveyMetricsPanel(props: PropTypes) {
  const [data, setData] = useState<SurveyMetrics>(null);

  useEffect(() => {
    setData(null);

    if (!props.filterStates) {
      setData(props.surveyMetrics);
      return;
    }

    SurveyMetricsService.get(fromFilterState(props.filterStates)).then(
      (analytics) => {
        setData(analytics);
      },
    );
  }, [props.filterStates]);

  return (
    <AnalyticsPanel
      title={
        <SurveyMetricsTitle
          surveyMetrics={data}
          benchmarkLabel={props.benchmarkLabel}
        />
      }
    >
      <LazyContent isLoading={!data} isEmpty={data?.totalSent === 0}>
        <SurveyMetricsContent surveyMetrics={data} />
      </LazyContent>
    </AnalyticsPanel>
  );
}
