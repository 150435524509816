import snakecaseKeys from 'snakecase-keys';
import { DateFilter } from '../../../components/DateRangePicker';
import { FilterStates } from '../../../components/candidate_experience/Filters/filterState';

export interface FilterParams {
  filterSelector: string;
  jobNames: string[];
  departmentNames: string[];
  hiringManagerNames: string[];
  recruiterNames: string[];
  locationNames: string[];
  surveyTemplateIds: string[];
  benchmarkType: string;
  dateRange: DateFilter;
  ethnicities: string[];
  genders: string[];
  ageRanges: string[];
}

export function fromFilterState(filters: Partial<FilterStates>): FilterParams {
  return {
    filterSelector: filters.filterSelector?.selected?.value,
    jobNames: filters.jobs?.selected?.map((option) => option.value),
    departmentNames: filters.departments?.selected?.map(
      (option) => option.value,
    ),
    hiringManagerNames: filters.hiringManagers?.selected?.map(
      (option) => option.value,
    ),
    recruiterNames: filters.recruiters?.selected?.map((option) => option.value),
    locationNames: filters.locations?.selected?.map((option) => option.value),
    surveyTemplateIds: filters.surveyTemplates?.selected?.map(
      (option) => option.value,
    ),
    benchmarkType: filters.benchmarkType?.selected?.value,
    dateRange: filters.dateRange,
    ethnicities: filters.ethnicities?.selected?.map((option) => option.value),
    genders: filters.genders?.selected?.map((option) => option.value),
    ageRanges: filters.ageRanges?.selected?.map((option) => option.value),
  };
}

export function toJsonParams(params: FilterParams) {
  return snakecaseKeys({
    filterSelector: params.filterSelector,
    jobNames: params.jobNames,
    departmentNames: params.departmentNames,
    hiringManagerNames: params.hiringManagerNames,
    recruiterNames: params.recruiterNames,
    locationNames: params.locationNames,
    surveyTemplateIds: params.surveyTemplateIds,
    benchmarkType: params.benchmarkType,
    startDate: params.dateRange?.start?.toJSON(),
    endDate: params.dateRange?.end?.toJSON(),
    dateRange: params.dateRange?.range,
    ethnicities: params.ethnicities,
    genders: params.genders,
    ageRanges: params.ageRanges,
  });
}
