import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  fieldName?: string;
  isRequired?: boolean;
  hideRequireIndicator?: boolean;
  classNames?: string;
  fontSize?: string;
  maximumCharacters?: number;
  wrapText?: boolean;
}

export function FormFieldHeader(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex text-start mb-2 align-items-center',
        props.classNames,
      )}
    >
      {props.fieldName != null && (
        <label
          className={classNames(
            props.fontSize || 'fs-5',
            !props.wrapText && 'text-nowrap',
          )}
        >
          {props.fieldName}
        </label>
      )}
      {props.isRequired && !props.hideRequireIndicator && (
        <span
          className={classNames(
            props.fontSize,
            'd-inline text-danger fs-4 ps-1 mt-0',
          )}
        >
          *
        </span>
      )}
      {props.maximumCharacters && (
        <span className='text-dark-200 text-nowrap fs-6 ms-auto'>
          {`Maximum ${props.maximumCharacters} characters`}
        </span>
      )}
    </div>
  );
}
