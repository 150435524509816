import React, { useEffect, useState } from 'react';
import { FilterStates } from '../../../../../components/candidate_experience/Filters/filterState';
import { Nps } from '../../../../../entities/survey_engine/Nps';
import { CpsService } from '../../../../../services/v1/candidate_experience/CpsService';
import { fromFilterState } from '../../../../../services/v1/candidate_experience/filterParams';
import { OverallNps } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNps';

interface PropTypes {
  title: string;
  description: string;
  detractorsText: string;
  neutralsText: string;
  promotersText: string;
  filterStates: Partial<FilterStates>;
  standardNpsCalculation: boolean;
}

export function CpsPanel(props: PropTypes) {
  const [data, setData] = useState<{ cps: Nps; trend: number }>(null);

  useEffect(() => {
    setData(null);

    CpsService.get(fromFilterState(props.filterStates)).then((analytics) => {
      setData({
        cps: {
          detractors: analytics.detractors,
          neutrals: analytics.neutrals,
          promoters: analytics.promoters,
          value: analytics.value,
          total: analytics.total,
          question_text: analytics.questionText,
        },
        trend: analytics.trend,
      });
    });
  }, [props.filterStates]);

  return (
    <OverallNps
      nps={data?.cps}
      npsTrend={data?.trend}
      title={`Overall ${props.title}`}
      detractorsText={props.detractorsText}
      neutralsText={props.neutralsText}
      promotersText={props.promotersText}
      tooltipText={
        <>
          <p>{props.description}</p>
          <p className='mb-0'>
            ({props.title} = % {props.promotersText.toLowerCase()} - %{' '}
            {props.detractorsText.toLowerCase()})
          </p>
        </>
      }
      standardNpsCalculation={props.standardNpsCalculation}
      hasCandidatePulseCnps={true}
    />
  );
}
