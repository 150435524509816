import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { SendSelfSchedulingEmailTrigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapInterviewTemplatesToSelectOptions } from '../../triggerSelectOptions';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { InterviewTemplateService } from '../../../../../../../../services/v1/applicant_tracking/InterviewTemplateService';
import { InterviewTemplate } from '../../../../../../../../entities/v1/applicant_tracking/InterviewTemplate';

interface PropTypes {
  trigger: SendSelfSchedulingEmailTrigger;
  interviewTemplateOptions: SelectOption[];
  setInterviewTemplateOptions: (options: SelectOption[]) => void;
  interviewTemplatesLoading: boolean;
  setInterviewTemplatesLoading: (b: boolean) => void;
}

export function InterviewTemplateDropdown(props: PropTypes) {
  const [isDisabled, setDisabled] = useState<boolean>(true);

  const [interviewTemplates, setInterviewTemplates] = useState<
    InterviewTemplate[]
  >([]);

  useEffect(() => {
    if (interviewTemplates.length == 0) {
      InterviewTemplateService.list()
        .then((interviewTemplateList) => {
          setInterviewTemplates(interviewTemplateList.interviewTemplates);
        })
        .finally(() => props.setInterviewTemplatesLoading(false));
    }
  }, []);

  useEffect(() => {
    props.setInterviewTemplateOptions(
      mapInterviewTemplatesToSelectOptions(
        interviewTemplates,
        props.trigger.interviewTemplateId?.toString(),
      ),
    );

    setDisabled(!interviewTemplates || interviewTemplates.length == 0);
  }, [interviewTemplates]);

  return (
    <TriggerRowDropdown>
      <LabelledSelect
        options={props.interviewTemplateOptions}
        selected={props.interviewTemplateOptions.find(
          (v: SelectOption) => v.selected,
        )}
        mandatory={true}
        labelClassName='fs-5'
        label={'Interview Template'}
        placeholder='Select template'
        onChange={(options) =>
          props.setInterviewTemplateOptions(
            mapInterviewTemplatesToSelectOptions(
              interviewTemplates,
              options.value,
            ),
          )
        }
        isLoading={props.interviewTemplatesLoading}
        disabled={isDisabled}
        tooltipText={'An interview template needs to be created first'}
      />
    </TriggerRowDropdown>
  );
}
