import { Department } from '../Department';
import { Location } from '../Location';
import { User } from '../User';

export interface JobRequisition {
  id: number;
  name: string;
  status: JobRequisitionStatus;
  uid: string;
  creatorUserId: number;
  ownerUserId: number;
  ownerUser: User;
  plannedStartDate?: Date;
  reason: string;
  attachmentUrl?: string;
  attachmentContent?: string;
  description?: string;
  descriptionEditorState?: string;
  descriptionHtml?: string;
  departmentId: number;
  department?: Department;
  openings: number;
  availableOpenings: number;
  hiringManagerUserId: number;
  hiringManagerUser: User;
  locationId: number;
  location?: Location;
  employmentType?: string;
  locationType?: string;
  salaryRangeFrom: number;
  salaryRangeTo: number;
  salaryRangeCurrency: string;
  approver_ids: number[];
  approvers?: JobRequisitionApprover[];
  reminderSentAt?: Date;
}

export interface JobRequisitionApprover {
  id: number;
  user: User;
  order: number;
  answer?: ApproverAnswer;
  declineReason?: string;
}

export enum JobRequisitionStatus {
  Approved = 'approved',
  Archived = 'archived',
  Completed = 'completed',
  Declined = 'declined',
  Pending = 'pending',
}

export enum ApproverAnswer {
  Approved = 'approved',
  Declined = 'declined',
}
