import { SurveyMetrics } from '../../../../entities/survey_engine/SurveyMetrics';
import { ApiService } from '../../../ApiService';
import { FilterParams, toJsonParams } from '../filterParams';

export class SurveyMetricsService {
  public static get(params: FilterParams): Promise<SurveyMetrics> {
    return ApiService.get<SurveyMetrics>(
      '/api/v1/candidate_experience/analytics/survey_metrics',
      toJsonParams(params),
    );
  }
}
