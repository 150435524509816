import classNames from 'classnames';
import React from 'react';
import { Controller, Path } from 'react-hook-form';
import GroupedReactSelect from 'react-select';
import { ControllerContext } from '../../utils/reactHookForms';
import { SelectOption } from '../Select';
import { selectStyle } from '../Select/selectStyle';
import { selectTheme } from '../Select/selectTheme';
import { Option } from '../Select/Option';
import { ClearIndicator } from '../Select/ClearIndicator';
import { DropdownIndicator } from '../Select/DropdownIndicator';
import { mapDisabledOptionKeys } from './utils';
import { isSearchable } from '../../utils/reactSelect';

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly SelectOption[];
}

export interface PropTypes<ControlType> {
  /** Options available to be selected. */
  options: GroupedOption[];
  selected?: SelectOption;
  defaultValue?: SelectOption;
  formHookContext?: ControllerContext<ControlType>;
  /** Custom name for the select component. */
  name?: string;
  /** It will appear a * to tell that the field is mandatory. */
  mandatory?: boolean;
  /** Label. */
  label?: string;
  /** Classes to apply to the label of this component. */
  labelClassName?: string;
  /** Custom class name for the select component. */
  className?: string;
  /** Indicates if the selected option is clearable. Defaults to true */
  isClearable?: boolean;
  /** Placeholder for the selected value */
  placeholder?: string;
  /** Called when the input changes, with the newly selected option. */
  onChange?: (newOption: SelectOption) => void;
  /** Called when the input is cleared. */
  onClear?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  autoHideKeyboardOnSmallerScreen?: boolean;
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textTransform: 'capitalize' as const,
};

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span className='fw-bold fs-5 text-dark-500'>{data.label}</span>
  </div>
);

function SelectBody<ControlType>(
  props: PropTypes<ControlType> & { field?: any },
) {
  return (
    <GroupedReactSelect<SelectOption, false, GroupedOption>
      defaultValue={props.defaultValue}
      options={mapDisabledOptionKeys(props.options)}
      formatGroupLabel={formatGroupLabel}
      name={props.name}
      value={props.selected}
      className={classNames(props.className, 'fs-5')}
      classNamePrefix='tags'
      placeholder={props.placeholder}
      onChange={(option: SelectOption) => {
        props.field
          ? props.field.onChange(option?.value ?? null)
          : props.onChange?.(option);
      }}
      isClearable={props.isClearable ?? true}
      isDisabled={props.disabled}
      isSearchable={isSearchable(
        props.isSearchable,
        props.autoHideKeyboardOnSmallerScreen,
      )}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      theme={selectTheme}
      styles={selectStyle('md')}
      isLoading={props.isLoading}
    />
  );
}

export function GroupedSelect<ControlType>(props: PropTypes<ControlType>) {
  return (
    <div className={classNames(props.className)}>
      {props.label && (
        <div className='mb-2 d-flex align-items-end'>
          <label className={classNames('me-auto', props.labelClassName)}>
            {props.label}
            {props.mandatory && <span className='ps-1 text-danger'>*</span>}
          </label>
        </div>
      )}
      {props.formHookContext == null ? (
        <SelectBody {...props} />
      ) : (
        <Controller
          control={props.formHookContext.formControl}
          name={props.formHookContext.controllerName as Path<ControlType>}
          rules={{
            required: props.formHookContext.required,
            validate: props.formHookContext.validate,
          }}
          render={({ field }) => <SelectBody {...props} field={field} />}
        />
      )}
    </div>
  );
}
