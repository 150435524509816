import React, { useEffect, useState } from 'react';
import { FilterStates } from '../../../../../components/candidate_experience/Filters/filterState';
import { fromFilterState } from '../../../../../services/v1/candidate_experience/filterParams';
import { OverallNpsHistory } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNpsHistory';
import { NpsHistory } from '../../../../../entities/survey_engine/NpsHistory';
import { NpsBenchmark } from '../../../../../entities/candidate_experience/NpsBenchmark';
import { CpsHistoryService } from '../../../../../services/v1/candidate_experience/CpsHistoryService';
import { DateFilter } from '../../../../../components/DateRangePicker';

interface PropTypes {
  npsTitle: string;
  dateRange: DateFilter;
  exportToPDF: boolean;
  standardNpsCalculation: boolean;
  filterStates: Partial<FilterStates>;
}

export function CnpsHistoryPanel(props: PropTypes) {
  const [data, setData] = useState<{
    npsHistory: NpsHistory[];
    npsBenchmark: NpsBenchmark;
  }>(null);

  useEffect(() => {
    setData(null);

    CpsHistoryService.get(fromFilterState(props.filterStates)).then(
      (analytics) => {
        setData({
          npsHistory: analytics.cpsHistory?.map((cpsHistory) => ({
            label: cpsHistory.label,
            data: cpsHistory.data.map((d) => ({
              created_at_date: d.createdAtDate,
              total: d.total,
            })),
          })),
          npsBenchmark: analytics.cpsBenchmark,
        });
      },
    );
  }, [props.filterStates]);

  return (
    <OverallNpsHistory
      npsHistory={data?.npsHistory}
      npsBenchmark={data?.npsBenchmark}
      dateRange={props.dateRange}
      title={`${props.npsTitle} History`}
      exportToPDF={props.exportToPDF}
      standardNpsCalculation={props.standardNpsCalculation}
    />
  );
}
