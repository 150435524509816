import { CpsHistory } from '../../../../entities/v1/candidate_experience/CpsHistory';
import { ApiService } from '../../../ApiService';
import { FilterParams, toJsonParams } from '../filterParams';

export class CpsHistoryService {
  public static get(params: FilterParams): Promise<CpsHistory> {
    return ApiService.get<CpsHistory>(
      '/api/v1/candidate_experience/analytics/cps_history',
      toJsonParams(params),
    );
  }
}
