export interface CurrencyOption {
  id: string;
  label: string;
  value: string;
  symbol: string;
}

export const AVAILABLE_CURRENCIES: CurrencyOption[] = [
  { id: 'gbp', label: 'GBP', value: 'gbp', symbol: '£' },
  { id: 'eur', label: 'EUR', value: 'eur', symbol: '€' },
  { id: 'usd', label: 'USD', value: 'usd', symbol: '$' },
  { id: 'jpy', label: 'JPY', value: 'jpy', symbol: '¥' },
  { id: 'cny', label: 'CNY', value: 'cny', symbol: 'CN¥' },
  { id: 'aud', label: 'AUD', value: 'aud', symbol: 'A$' },
  { id: 'cad', label: 'CAD', value: 'cad', symbol: 'C$' },
  { id: 'aed', label: 'AED', value: 'aed', symbol: 'د.إ' },
  { id: 'inr', label: 'INR', value: 'inr', symbol: '₹' },
  { id: 'sgd', label: 'SGD', value: 'sgd', symbol: 'S$' },
  { id: 'krw', label: 'KRW', value: 'krw', symbol: '₩' },
];

export const DEFAULT_CURRENCY: CurrencyOption = AVAILABLE_CURRENCIES.find(
  (c) => c.value === 'gbp',
);

export function getCurrencySymbol(currency?: string): string {
  return (
    AVAILABLE_CURRENCIES.find(
      (c) => c.value === currency || c.label === currency,
    )?.symbol || ''
  );
}
