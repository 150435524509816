import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { JobRequisition } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionDto } from '../../../applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { JobRequisitionStatus } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionList } from '../../../../entities/v1/applicant_tracking/JobRequisitionList';

interface Params {
  expand?: string[];
}

export interface ShowParams extends Params {
  id: string;
}

export interface ListParams extends Params {
  status?: JobRequisitionStatus;
  jobCreationEligible?: boolean;
}

export class JobRequisitionService {
  public static create(
    jobRequisitionRequest: JobRequisitionDto,
  ): Promise<void> {
    return ApiService.post<void>(
      '/api/v1/applicant_tracking/job_requisitions',
      snakecaseKeys({ jobRequisitionRequest }),
    );
  }

  public static update(
    id: number,
    jobRequisitionRequest: JobRequisitionDto,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/job_requisitions/${id}`,
      snakecaseKeys({ jobRequisitionRequest }),
    );
  }

  public static show(params: ShowParams): Promise<JobRequisition> {
    return ApiService.get<JobRequisition>(
      `/api/v1/applicant_tracking/job_requisitions/${params.id}`,
      snakecaseKeys(params),
    );
  }

  public static list(params: ListParams): Promise<JobRequisitionList> {
    return ApiService.get<JobRequisitionList>(
      '/api/v1/applicant_tracking/job_requisitions',
      snakecaseKeys(params),
    );
  }

  public static approve(id: number): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/job_requisitions/${id}/approve`,
    );
  }

  public static decline(id: number, declineReason: string): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/job_requisitions/${id}/decline`,
      snakecaseKeys({ declineReason }),
    );
  }

  public static sendReminder(id: number): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/job_requisitions/${id}/send_reminder`,
    );
  }
}
