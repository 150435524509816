import { AlertType } from '../../components/Alert';
import { appendQueryParam, appendRedirectUri } from '../../utils/url';

export function redirectWithAlert(
  url: string,
  message: string,
  type?: AlertType,
): void {
  const redirectUri = new URL(url, window.location.origin).href;
  let constructedUrl = appendRedirectUri('/flash', redirectUri);

  constructedUrl = appendQueryParam(
    constructedUrl,
    'type',
    type || AlertType.Success,
  );
  constructedUrl = appendQueryParam(constructedUrl, 'message', message);

  window.location.href = constructedUrl;
}
