import React, { useState, useCallback, useEffect, createContext } from 'react';
import {
  StateMachineProvider,
  createStore,
  GlobalState,
  useStateMachine,
} from 'little-state-machine';
import { Routes, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { EmptyState } from '../../../../components/EmptyState';
import { SetupJobPageLayout } from './SetupJobPageLayout';
import { SetupJobPostPage } from './SetupJobPostPage';
import { SetupJobApplicationFormPage } from './SetupJobApplicationFormPage';
import { SetupJobWorkflowPage } from './SetupJobWorkflowPage';
import { SetupJobDetailsPage } from './SetupJobDetailsPage';
import { getPathnameLastSegment } from '../../../../utils/url';
import { ItemPropTypes } from '../../../../components/HorizontalStepper';
import { CreateJobDto } from '../../../../services/applicant_tracking/JobService/CreateJobDto';
import { keyedUpdateAction } from '../../../../utils/reactHookForms';
import { JobDetails } from '../../../../services/applicant_tracking/JobService/EditJobDto';
import { JobPostService } from '../../../../services/applicant_tracking/JobPostService';
import { JobService } from '../../../../services/applicant_tracking/JobService';
import { Job } from '../../../../entities/applicant_tracking/Job';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { SetupJobOrganicPostsPage } from './SetupJobOrganicPostsPage';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { JobRequisition } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionService } from '../../../../services/v1/applicant_tracking/JobRequisitionService';
import { toIntOrNull } from '../../../../utils/toIntOrNull';
import { JOB_POST_EDITOR_DEFAULT_STATE } from '../../../../utils/applicant_tracking/jobDetails';

interface Proptypes {
  jobDetails?: JobDetails;
  jobFormDTO?: CreateJobDto;
  hrisProvider?: HrisProvider;
  jobRequisition?: JobRequisition;
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  updateJobRequisitionEnabled: boolean;
  organicPostSkillsEnabled?: boolean;
  selfSchedulingAutomationEnabled: boolean;
}

interface Context {
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  updateJobRequisitionEnabled: boolean;
  jobRequisition?: JobRequisition;
}

export const SetupJobContext = createContext<Context>(null);

export interface JobAndJobPost {
  job: Job;
  jobPost: JobPost;
}

export const initCreateJobDto: CreateJobDto = {
  jobId: null,
  jobPostId: null,
  name: null,
  departmentId: null,
  locationId: null,
  locationType: 'onsite',
  employmentType: 'full_time',
  experienceLevel: null,
  jobOpenings: null,
  hiringTeamRecruiterId: null,
  hiringTeamMembers: [],
  editorState: JOB_POST_EDITOR_DEFAULT_STATE,
  editorStateHtml: null,
  applicationFormTemplateId: null,
  applicationFormTemplateConsent: false,
  jobWorkflowId: null,
  organicPostForm: null,
  triggers: [],
  jobRequisitionId: null,
};

function buildJobDto(jobRequisition?: JobRequisition): CreateJobDto {
  const createJobDto = initCreateJobDto;

  if (!jobRequisition) {
    return createJobDto;
  }

  return {
    ...createJobDto,
    jobRequisitionId: jobRequisition.id.toString(),
    jobOpenings: jobRequisition.availableOpenings?.toString(),
    departmentId: jobRequisition.departmentId.toString(),
    locationId: jobRequisition.locationId.toString(),
    locationType: jobRequisition.locationType,
    employmentType: jobRequisition.employmentType,
    hiringTeamRecruiterId: jobRequisition.ownerUserId.toString(),
    hiringTeamMembers: [
      {
        userId: jobRequisition.hiringManagerUserId.toString(),
        roleId: 'hiring_manager',
      },
    ],
  };
}

function getStepperItems(): ItemPropTypes[] {
  const stepperItems: ItemPropTypes[] = [
    {
      title: 'Job Details',
      subtitle: 'Add job main information',
      slug: 'job_details',
    },
    { title: 'Job Post', subtitle: 'Define job post', slug: 'job_post' },
    {
      title: 'Application Form',
      subtitle: 'Create application form',
      slug: 'application_form',
    },
    {
      title: 'Job Workflow',
      subtitle: 'Define workflow and actions',
      slug: 'job_workflow',
    },
    {
      title: 'Organic Posts',
      subtitle: 'Increase your reach',
      slug: 'organic_posts',
    },
  ];

  return stepperItems;
}

function getRouteStep(routeName: string, stepperItems: ItemPropTypes[]) {
  return stepperItems.findIndex((item) => item.slug == routeName) || 0;
}

createStore(
  {
    setupJob: initCreateJobDto,
  },
  {
    storageType: localStorage,
  },
);

function Body(props: Proptypes) {
  const [jobDTO] = useState<CreateJobDto>(
    props.jobFormDTO ?? buildJobDto(props.jobRequisition),
  );

  const isEditFlow: boolean = props.jobFormDTO != null;
  const stepperItems = getStepperItems();

  const location = useLocation();
  const [setupStep, setSetupStep] = useState<number>(0);
  const boundedSetSetupStep = useCallback(
    (newStep: number, _jobDTO: CreateJobDto) => {
      if (newStep >= 0 && newStep < stepperItems.length) {
        setSetupStep(newStep);
      }
    },
    [setupStep],
  );
  const updateLocalStorage: (state: GlobalState, payload: any) => GlobalState =
    keyedUpdateAction('setupJob');

  const { actions, state } = useStateMachine({ updateLocalStorage });

  useEffect(() => {
    const routeName = getPathnameLastSegment(location.pathname);

    setSetupStep(getRouteStep(routeName, stepperItems));
  }, [location]);

  useEffect(() => {
    actions.updateLocalStorage(jobDTO);
  }, [jobDTO]);

  const handleSubmit = async (newState: CreateJobDto) => {
    const job = await JobService.upsert({
      id: newState.jobId,
      name: newState.name,
      departmentId: newState.departmentId,
      hiringTeamRecruiterId: newState.hiringTeamRecruiterId,
      hiringTeamMembers: newState.hiringTeamMembers,
      locationType: newState.locationType,
      employmentType: newState.employmentType,
      jobOpenings: newState.jobOpenings,
      planTemplateId: newState.jobWorkflowId,
      triggers: newState.triggers,
      isEditing: isEditFlow,
      organicPostDetails: {
        companyId: newState.organicPostForm?.companyId,
        address: newState.organicPostForm?.address1,
        addressDetail: newState.organicPostForm?.address2,
        city: newState.organicPostForm?.city,
        postcode: newState.organicPostForm?.postcode,
        country: newState.organicPostForm?.country,
        contactName: newState.organicPostForm?.contactName,
        contactEmail: newState.organicPostForm?.contactEmail,
        organicPostSkills: newState.organicPostForm?.organicPostSkills?.map(
          (v) => v.id,
        ),
        salaryPeriod: newState.organicPostForm?.salaryPeriod,
        salaryCurrency: newState.organicPostForm?.salaryCurrency,
        salaryRangeFrom: toIntOrNull(newState.organicPostForm?.salaryRangeFrom),
        salaryRangeTo: toIntOrNull(newState.organicPostForm?.salaryRangeTo),
        weeklyHoursFrom: toIntOrNull(newState.organicPostForm?.weeklyHoursFrom),
        weeklyHoursTo: toIntOrNull(newState.organicPostForm?.weeklyHoursTo),
        industryId: +newState.organicPostForm?.industry?.id,
        industryName: newState.organicPostForm?.industry?.description,
        jobCategoryId: +newState.organicPostForm?.jobCategory?.id,
        jobCategoryName: newState.organicPostForm?.jobCategory?.description,
        organicEnabled: newState.organicPostForm?.organicEnabled,
      },
      jobRequisitionId: newState.jobRequisitionId,
    });

    const jobPost = newState.jobId
      ? await JobPostService.upsert({
          name: newState.name,
          id: newState.jobPostId?.toString(),
          jobId: newState.jobId,
          locationId: newState.locationId,
          experienceLevel: newState.experienceLevel,
          applicationFormTemplateId: newState.applicationFormTemplateId,
          editorState: newState.editorState,
          editorStateHtml: newState.editorStateHtml,
        })
      : null;

    return { job, jobPost };
  };

  const [jobRequisition, setJobRequisition] = useState<JobRequisition>(
    props.jobRequisition,
  );

  useEffect(() => {
    if (
      jobDTO.jobRequisitionId &&
      props.jobRequisition?.id.toString() !== jobDTO.jobRequisitionId
    ) {
      JobRequisitionService.show({
        id: jobDTO.jobRequisitionId,
        expand: [],
      }).then(setJobRequisition);
    }
  }, [jobDTO.jobRequisitionId]);

  const setupJobContext = {
    jobRequisitionEnabled: props.jobRequisitionEnabled,
    jobRequisitionMandatory: props.jobRequisitionMandatory,
    updateJobRequisitionEnabled: props.updateJobRequisitionEnabled,
    jobRequisition: jobRequisition,
  };

  return (
    <SetupJobContext.Provider value={setupJobContext}>
      <Routes>
        <Route
          element={
            <SetupJobPageLayout
              stepperItems={stepperItems}
              setupStep={setupStep}
              setSetupStep={boundedSetSetupStep}
              isEditFlow={isEditFlow}
              {...props}
            />
          }
        >
          <Route
            path={'job_details'}
            element={
              <SetupJobDetailsPage
                setupStep={setupStep}
                setSetupStep={boundedSetSetupStep}
                updateLocalStorage={updateLocalStorage}
                initJobDto={state.setupJob}
                isEditFlow={isEditFlow}
                jobDetails={props.jobDetails}
                onSubmit={handleSubmit}
                setJobRequisition={setJobRequisition}
              />
            }
          />
          <Route
            path={'job_post'}
            element={
              <SetupJobPostPage
                setupStep={setupStep}
                setSetupStep={boundedSetSetupStep}
                updateLocalStorage={updateLocalStorage}
                initJobDto={state.setupJob}
                isEditFlow={isEditFlow}
                jobDetails={props.jobDetails}
                onSubmit={handleSubmit}
              />
            }
          />
          <Route
            path={'application_form'}
            element={
              <SetupJobApplicationFormPage
                setupStep={setupStep}
                setSetupStep={boundedSetSetupStep}
                updateLocalStorage={updateLocalStorage}
                initJobDto={state.setupJob}
                isEditFlow={isEditFlow}
                jobDetails={props.jobDetails}
                onSubmit={handleSubmit}
              />
            }
          />
          <Route
            path={'job_workflow'}
            element={
              <SetupJobWorkflowPage
                setupStep={setupStep}
                setSetupStep={boundedSetSetupStep}
                updateLocalStorage={updateLocalStorage}
                initJobDto={state.setupJob}
                isEditFlow={isEditFlow}
                jobDetails={props.jobDetails}
                onSubmit={handleSubmit}
                hrisProvider={props.hrisProvider}
                selfSchedulingAutomationEnabled={
                  props.selfSchedulingAutomationEnabled
                }
              />
            }
          />
          <Route
            path={'organic_posts'}
            element={
              <SetupJobOrganicPostsPage
                setupStep={setupStep}
                setSetupStep={boundedSetSetupStep}
                updateLocalStorage={updateLocalStorage}
                initJobDto={state.setupJob}
                isEditFlow={isEditFlow}
                jobDetails={props.jobDetails}
                organicPostSkillsEnabled={
                  props.organicPostSkillsEnabled ?? false
                }
                onSubmit={handleSubmit}
              />
            }
          />
          <Route
            path={'*'}
            element={
              <EmptyState
                title={'Page not found!'}
                text={
                  'For more information contact our support team at support@screenloop.com'
                }
                cardless={true}
              />
            }
          />
        </Route>
      </Routes>
    </SetupJobContext.Provider>
  );
}

export function SetupJobPageRoutes(props: Proptypes) {
  return (
    <StateMachineProvider>
      <Body {...props} />
    </StateMachineProvider>
  );
}
