import React from 'react';
import classNames from 'classnames';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { IconLink } from '../../../../components/IconLink';
import styles from './styles.module.scss';
import womanTipImg from '../../../../images/woman-tip.svg';

interface Tip {
  text: string;
  link: {
    displayText: string;
    url: string;
  };
}

const TIPS: Tip[] = [
  {
    text: 'Reduce time to fill in scorecards by using our Scorecard Assistant feature.',
    link: {
      displayText: 'Discover our Scorecard assistant',
      url: 'https://help.screenloop.com/en/articles/9184284-filling-in-scorecards',
    },
  },
  {
    text: 'Capture interview insights with automated summaries, questions and attributes recognition.',
    link: {
      displayText: 'Find out more about Screenloop AI',
      url: 'https://help.screenloop.com/en/articles/8087114-product-updates-july-11th-2023#:~:text=following%20product%20enhancements%3A-,1.%20Interview%20AI%20Notes,-We%20are%20excited',
    },
  },
  {
    text: 'Improve the way your team interviews and accesses candidates by leveraging our Training sessions.',
    link: {
      displayText: 'Learn about our Training content',
      url: 'https://help.screenloop.com/en/articles/6714732-product-updates-november-10th-2022#:~:text=ATS Pulse Insights-,1. Training%3A New product,-It is now',
    },
  },
  {
    text: 'Start measuring your hiring process and candidate experience with Screenloop’s self service Pulse surveys.',
    link: {
      displayText: 'Read more about Pulse',
      url: 'https://help.screenloop.com/en/articles/6887557-pulse-self-service',
    },
  },
  {
    text: 'Make confident staffing decisions with our modern Reference checks.',
    link: {
      displayText: 'Discover how we do reference checks',
      url: 'https://help.screenloop.com/en/articles/6830082-how-does-success-support-you',
    },
  },
  {
    text: 'Ensure trust and security in your hires with our reliable Background checks.',
    link: {
      displayText: 'Learn more about our Background checks',
      url: 'https://help.screenloop.com/en/articles/7019731-product-updates-february-28th-2023#:~:text=Hiring team Pulse-,1. Background checks,-Because background checks',
    },
  },
  {
    text: 'Monitor your Candidate experience feedback by leveraging our Pulse Analytics dashboards.',
    link: {
      displayText: 'Find out more about Candidate Pulse',
      url: 'https://help.screenloop.com/en/articles/6269717-candidate-pulse-analytics-and-data-insights',
    },
  },
];

function getTipOfTheDay() {
  const todayDay = new Date().getDate();
  const tipIndex = todayDay % TIPS.length;
  return TIPS[tipIndex];
}

export function TipsPanel() {
  const tip = getTipOfTheDay();

  return (
    <DeprecatedPanel className='p-4 mb-0' style={{ height: '240px' }}>
      <div className='d-flex flex-column gap-3 h-100'>
        <div className='fs-3 fw-semibold'>Screenloop Tips</div>
        <TipContent tip={tip} />
        <TipLink tip={tip} />
      </div>
    </DeprecatedPanel>
  );
}

function TipContent(props: { tip: Tip }) {
  return (
    <div className={classNames('d-flex p-3 bg-light-info', styles.content)}>
      <div className={classNames(styles['content-text'], 'fs-5 fw-semibold')}>
        {props.tip.text}
      </div>
      <img
        src={womanTipImg}
        className={classNames(styles.image)}
        style={{ maxWidth: '250px' }}
      />
    </div>
  );
}

function TipLink(props: { tip: Tip }) {
  return (
    <IconLink
      text={props.tip.link.displayText}
      textClassName={'fs-6 text-info text-truncate'}
      iconClassName={'bi-box-arrow-up-right text-info fs-6'}
      url={props.tip.link.url}
      target='_blank'
    />
  );
}
