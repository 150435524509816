import { PlanTemplate } from '../../../../../../entities/applicant_tracking/PlanTemplate';
import { SelectOption } from '../../../../../../components/Select';
import { JobStageTemplate } from '../../../../../../entities/applicant_tracking/JobStageTemplate';
import { EmailTemplate } from '../../../../../../entities/EmailTemplate';
import { InterviewTemplate } from '../../../../../../entities/v1/applicant_tracking/InterviewTemplate';
import { humanize } from '../../../../../../utils/humanize';

export function mapStagesToSelectOption(
  planTemplate?: PlanTemplate,
  selectedValues?: string[],
  onlyStagesWithInterviews: boolean = false,
): SelectOption[] {
  let jobStages = planTemplate?.jobStages;
  if (!jobStages) {
    return [];
  }

  if (onlyStagesWithInterviews) {
    jobStages = jobStages.filter((jobStage: JobStageTemplate) =>
      containsInterviews(jobStage),
    );
  }

  return jobStages.map((jobStage: JobStageTemplate) => {
    return {
      value: jobStage.jobStageId.toString(),
      label: jobStage.name,
      selected: selectedValues?.some(
        (v) => v === jobStage.jobStageId.toString(),
      ),
    };
  });
}

function containsInterviews(jobStage: JobStageTemplate): boolean {
  return !!(jobStage.interviewKitTemplate || jobStage.scorecardTemplate);
}

export function mapEmailTemplatesToSelectOptions(
  emailTemplates: EmailTemplate[],
  selectedValue?: string,
): SelectOption[] {
  return emailTemplates.map((emailTemplate: EmailTemplate) => {
    return {
      value: emailTemplate.id.toString(),
      label: emailTemplate.name,
      selected: emailTemplate.id.toString() === selectedValue,
    };
  });
}

export function mapInterviewTemplatesToSelectOptions(
  interviewTemplates: InterviewTemplate[],
  selectedValue?: string,
): SelectOption[] {
  return interviewTemplates.map((interviewTemplate: InterviewTemplate) => {
    return {
      value: interviewTemplate.id.toString(),
      label: interviewTemplate.name,
      selected: interviewTemplate.id.toString() === selectedValue,
    };
  });
}

export function mapIntToSelectOptions(selectedValue?: string): SelectOption[] {
  return [...Array(8).keys()].map((value: number) => {
    return {
      value: value.toString(),
      label: value.toString(),
      selected: value.toString() === selectedValue,
    };
  });
}

const ApplicationStatusOptions = ['active', 'hired', 'rejected'];

export function mapApplicationStatusesToSelectOptions(
  selectedValues?: string[],
): SelectOption[] {
  return ApplicationStatusOptions.map((status: string) => {
    return {
      value: status,
      label: humanize(status),
      selected: selectedValues?.some((v) => v === status),
    };
  });
}
