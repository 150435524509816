import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './styles.module.scss';
import stylesheetExports from '../../../stylesheets/export.module.scss';

interface PropTypes {
  valueAsPercentage: number;
  text: string;
  color?: string;
  labelText?: string;
  labelClassName?: string;
  labelColor?: string;
  rating_legend: {
    minScore: number;
    label: string;
    color: string;
    bgColor: string;
  }[];
  hasCandidatePulseCnps?: boolean;
}

export function GaugeChart(props: PropTypes) {
  const scoreAboveIndex = props.rating_legend.findIndex(
    (v) => v.minScore > props.valueAsPercentage,
  );
  const scoreIndex =
    scoreAboveIndex === -1
      ? props.rating_legend.length - 1
      : scoreAboveIndex - 1;
  const scoreLegend = props.rating_legend[scoreIndex];
  const labelText = props.labelText || scoreLegend.label;

  useEffect(() => {
    const textElement = document.querySelector(
      '.CircularProgressbar-text',
    ) as HTMLElement;
    if (textElement) {
      textElement.style.transform = 'rotate(90deg)';
      textElement.style.transformOrigin = 'center';
    }
  }, []);

  const svgRef = useRef(null);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.style.transform = 'rotate(0.75turn)';
    }
  }, []);

  return (
    <div>
      <svg
        viewBox='20 0 100 100'
        ref={svgRef}
        style={{ minHeight: props.hasCandidatePulseCnps ? undefined : '200px' }}
      >
        <CircularProgressbar
          value={Math.abs(props.valueAsPercentage)}
          text={props.text}
          circleRatio={0.5}
          styles={{
            ...buildStyles({
              pathColor: props.color || scoreLegend.color,
              textColor: props.color || stylesheetExports.darkPrimary,
              trailColor: '#FAFCFF',
            }),
            root: {
              transform: 'rotate(0.75turn)',
            },
          }}
          className={classNames('h2 px-4')}
        />
      </svg>

      <div className='text-center'>
        <h3
          className={classNames(
            'mb-0 fs-4 fw-normal',
            props.labelClassName,
            styles.label,
          )}
          style={{ whiteSpace: 'nowrap' }}
        >
          {props.hasCandidatePulseCnps && 'Your overall CNPS is '}
          <span
            className='fw-bold'
            style={{ color: props.labelColor || scoreLegend.color }}
            title={labelText}
          >
            {labelText}
          </span>
        </h3>
      </div>
    </div>
  );
}
