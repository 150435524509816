import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { FilterState } from '..';
import { LabelledDatePicker } from '../../../../components/LabelledDatePicker';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../components/Select';
import { MoreFilters } from '../../../../components/MoreFilters';
import { AnalyticsFiltersService } from '../../../../services/v1/quality_of_hire/Analytics';
import {
  FilterResult,
  MultiSelectAsyncFilter,
} from '../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../utils/mapResultToSelectOption';

interface PropTypes {
  filterState: FilterState;
  onFilterUpdate: (
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) => void;
  onApplyClick: (appliedHiddenFilters: number) => void;
  onResetClick: () => void;
  appliedHiddenFilters: number;
}

interface FiltersConfig {
  [key: string]: {
    label: string;
    load: (search: string, page: number) => Promise<FilterResult>;
  };
}

const filtersConfig: FiltersConfig = {
  department: {
    label: 'Department',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        AnalyticsFiltersService.departments({ name, page }),
        (filter) => filter.departments,
        true,
      ),
  },
  job: {
    label: 'Job',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        AnalyticsFiltersService.jobs({ name, page }),
        (filter) => filter.jobs,
        true,
      ),
  },
  hiring_manager: {
    label: 'Hiring Manager',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        AnalyticsFiltersService.hiringManagers({ name, page }),
        (filter) => filter.hiringMembers,
        true,
      ),
  },
  recruiter: {
    label: 'Recruiter',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        AnalyticsFiltersService.recruiters({ name, page }),
        (filter) => filter.hiringMembers,
        true,
      ),
  },
};

function DynamicDataMultiSelect(props: PropTypes) {
  const selectedFilter = props.filterState.selectedFilter;

  return (
    <MultiSelectAsyncFilter
      isMulti
      label={selectedFilter.label}
      loadOptions={filtersConfig[selectedFilter.value].load}
      onChange={(newValues) =>
        props.onFilterUpdate('selectedFilterData', newValues)
      }
      selected={props.filterState.selectedFilterData}
      max={10}
    />
  );
}

function GeneralLabelledMultiSelect(props: {
  loadOptions: (search: string, page: number) => Promise<FilterResult>;
  selectOptions: SelectOption[];
  label: string;
  onFilterUpdate: (
    name: keyof FilterState,
    value: SelectOption | SelectOption[],
  ) => void;
  selectOptionsName: keyof FilterState;
}) {
  return (
    <MultiSelectAsyncFilter
      isMulti
      label={props.label}
      loadOptions={props.loadOptions}
      onChange={(newValues) =>
        props.onFilterUpdate(props.selectOptionsName, newValues)
      }
      selected={props.selectOptions}
      max={10}
    />
  );
}

export function Filters(props: PropTypes) {
  const selectedFilter = props.filterState.selectedFilter;
  const [moreFilters, setMoreFilters] = useState(false);

  const selectedHiddenFiltersCount = +(
    props.filterState.selectedLocations.length > 0
  );

  return (
    <div className='mb-3'>
      <Row className='fs-5 h-100'>
        <Col xs='12' md='4' xl='2' className='mt-2'>
          <LabelledSelect
            options={Object.keys(filtersConfig).map((key) => ({
              value: key,
              label: filtersConfig[key].label,
            }))}
            selected={selectedFilter}
            onChange={(newFilter) =>
              props.onFilterUpdate('selectedFilter', newFilter)
            }
            label='Filter by'
            className={'w-100'}
          />
        </Col>
        <Col xs='12' md='4' xl='2' className='mt-2'>
          <DynamicDataMultiSelect {...props} />
        </Col>
        <Col xs='12' md='4' xl='2' className='mt-2'>
          <GeneralLabelledMultiSelect
            loadOptions={(name: string, page: number) =>
              mapResultToSelectOption(
                AnalyticsFiltersService.triggers({ name, page }),
                (filter) => filter.triggers,
              )
            }
            selectOptions={props.filterState.selectedTriggers}
            label={'Onboarding Stage'}
            onFilterUpdate={props.onFilterUpdate}
            selectOptionsName={'selectedTriggers'}
          />
        </Col>
        <Col xs='12' md='4' xl='2' className='mt-2'>
          <LabelledDatePicker
            label='Date'
            dateFilter={props.filterState.dateRange}
            onChange={(start, end, range) =>
              props.onFilterUpdate('dateRange', { start, end, range })
            }
            className={'w-100'}
          />
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'd-flex text-nowrap mt-2 align-bottom align-self-end',
            styles.filterButtonsColumn,
          )}
        >
          <div
            className={classNames(
              'd-flex gap-3 justify-content-between p-0',
              styles.filterButtons,
            )}
          >
            <Button color='secondary' onClick={props.onResetClick}>
              Reset
            </Button>
            <Button
              color='primary'
              onClick={() => props.onApplyClick(selectedHiddenFiltersCount)}
            >
              Apply
            </Button>
          </div>
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'mt-2 align-bottom align-self-end',
            styles.filterButtonsColumn,
          )}
        >
          <MoreFilters
            moreFilters={moreFilters}
            appliedHiddenFilters={props.appliedHiddenFilters}
            onChange={setMoreFilters}
          />
        </Col>
      </Row>
      <Collapse isOpen={moreFilters}>
        <Row className='fs-5 h-100'>
          <Col xs='12' md='4' xl='2' className='mt-2'>
            <MultiSelectAsyncFilter
              isMulti
              label='Location'
              loadOptions={(name, page) =>
                mapResultToSelectOption(
                  AnalyticsFiltersService.locations({ name, page }),
                  (filter) => filter.locations,
                  true,
                )
              }
              onChange={(opts: SelectOption[]) =>
                props.onFilterUpdate('selectedLocations', opts)
              }
              selected={props.filterState.selectedLocations}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
}
