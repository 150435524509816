import React from 'react';
import { JobPostPageTab } from '../..';
import { Button } from 'reactstrap';

interface PropTypes {
  previewMode: boolean;
  setActiveTab: (tab: JobPostPageTab) => void;
}

export function OverviewApplyButton(props: PropTypes) {
  return (
    <Button
      color={'primary'}
      size={'lg'}
      type={'button'}
      disabled={props.previewMode}
      onClick={() => props.setActiveTab('application_form')}
    >
      Apply
    </Button>
  );
}
