import React, { useCallback, useEffect, useState } from 'react';
import { JobPost } from '../../../../../entities/applicant_tracking/JobPost';
import { DepartmentSelect } from './DepartmentSelect';
import { Col, Row } from 'reactstrap';
import { LocationSelect } from './LocationSelect';
import { JobPostsList } from './JobPostsList';
import { SelectOption } from '../../../../../components/Select';
import {
  appendQueryParamToCurrentTab,
  getQueryParam,
} from '../../../../../utils/url';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {
  sizeSmallerThan,
  useScreenSize,
} from '../../../../../utils/useScreenSize';

interface PropTypes {
  jobPosts: JobPost[];
  whitelabelEnabled: boolean;
}

interface SelectInputPropTypes {
  departmentOptions: SelectOption[];
  locationOptions: SelectOption[];
  currentDepartment: SelectOption;
  currentLocation: SelectOption;
  onDepartmentChange: (opt: SelectOption) => void;
  onLocationChange: (opt: SelectOption) => void;
}

function isOptionUnique(
  option: SelectOption,
  index: number,
  options: SelectOption[],
) {
  return (
    index ===
    options.findIndex(
      (opt) => opt.value === option.value && opt.label === option.label,
    )
  );
}

function mapToLocationOptions(jobPost: JobPost) {
  return {
    label: jobPost.location.name,
    value: jobPost.location.id.toString(),
  };
}

function mapToDepartmentOptions(jobPost: JobPost) {
  return {
    label: jobPost.job.department.name,
    value: jobPost.job.department.id.toString(),
  };
}

function filteredJobPosts(
  jobPosts: JobPost[],
  currentDepartment?: SelectOption,
  currentLocation?: SelectOption,
) {
  let result: JobPost[] = jobPosts;

  if (currentDepartment != null) {
    result = result.filter(
      (jp) => jp.job.department.id.toString() == currentDepartment.value,
    );
  }

  if (currentLocation != null) {
    result = result.filter(
      (jp) => jp.location.id.toString() == currentLocation.value,
    );
  }

  return result;
}

function getLocationOptions(jobPosts: JobPost[]) {
  return jobPosts.map((jp) => mapToLocationOptions(jp)).filter(isOptionUnique);
}

function getDepartmentOptions(jobPosts: JobPost[]) {
  return jobPosts
    .map((jp) => mapToDepartmentOptions(jp))
    .filter(isOptionUnique);
}

function SelectInputs(props: SelectInputPropTypes) {
  const screenSize = useScreenSize();
  const selectSize = sizeSmallerThan(screenSize, 'sm') ? 'lg' : 'md';

  return (
    <>
      <Col className='gx-sm-4 gx-3 ms-1 ms-sm-0'>
        <DepartmentSelect
          options={props.departmentOptions}
          selected={props.currentDepartment}
          size={selectSize}
          onChange={props.onDepartmentChange}
        />
      </Col>
      <Col className='gx-sm-4 gx-3 me-1 me-sm-0'>
        <LocationSelect
          options={props.locationOptions}
          selected={props.currentLocation}
          size={selectSize}
          onChange={props.onLocationChange}
        />
      </Col>
    </>
  );
}

function findOption(options: SelectOption[], name: string) {
  const id = getQueryParam(name);
  if (!id) return null;

  return options.find((option) => option.value === id);
}

export function CareersPageBody(props: PropTypes) {
  const locationOptions: SelectOption[] = getLocationOptions(props.jobPosts);
  const departmentOptions: SelectOption[] = getDepartmentOptions(
    props.jobPosts,
  );

  const [currentDepartment, setCurrentDepartment] = useState<SelectOption>(
    findOption(departmentOptions, 'department_id'),
  );
  const [currentLocation, setCurrentLocation] = useState<SelectOption>(
    findOption(locationOptions, 'location_id'),
  );
  const currentJobPosts = useCallback(filteredJobPosts, [
    currentDepartment,
    currentLocation,
  ]);

  useEffect(() => {
    appendQueryParamToCurrentTab('department_id', currentDepartment?.value);
    appendQueryParamToCurrentTab('location_id', currentLocation?.value);
  }, [currentDepartment, currentLocation]);

  return (
    <div>
      <div className={classNames('fw-bold my-6h my-sm-5', styles.heading)}>
        Job Openings
      </div>
      <div className='d-flex justify-content-center'>
        <Col className={classNames(styles['job-posts-container'], 'px-3')}>
          <Row className='text-start gy-2'>
            <SelectInputs
              departmentOptions={departmentOptions}
              locationOptions={locationOptions}
              currentDepartment={currentDepartment}
              currentLocation={currentLocation}
              onDepartmentChange={setCurrentDepartment}
              onLocationChange={setCurrentLocation}
            />
          </Row>
          <JobPostsList
            jobPosts={currentJobPosts(
              props.jobPosts,
              currentDepartment,
              currentLocation,
            )}
            whitelabelEnabled={props.whitelabelEnabled}
          />
        </Col>
      </div>
    </div>
  );
}
