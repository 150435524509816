import React from 'react';
import { Control } from 'react-hook-form';
import {
  GroupedOption,
  GroupedSelect,
} from '../../../../components/GroupedReactSelect';
import { SelectOption } from '../../../../components/Select';
import {
  ChannelType,
  Source,
} from '../../../../entities/applicant_tracking/Source';
import { humanize } from '../../../../utils/humanize';

interface PropTypes {
  label: string;
  labelClassName?: string;
  sources: Source[];
  formControl: Control<any, any>;
  shouldRender: boolean;
  disabled: boolean;
  required?: boolean;
  initSourceId?: string;
  isLoading?: boolean;
  extraOptions?: GroupedOption[];
}

function mapToSelectOption(source: Source): SelectOption {
  if (source == null) return null;

  return {
    value: source.id.toString(),
    label: source.name,
  };
}

function mapToGroupedOptions(sources: Source[]): GroupedOption[] {
  if (sources == null) return [];

  const channels = Array.from(
    new Set(sources.map((source: Source) => source.channelType.toString())),
  );

  return channels.map((channel: ChannelType) => {
    return {
      label: humanize(channel.toString()),
      options: sources
        .filter((source: Source) => source.channelType === channel)
        .map(mapToSelectOption),
    };
  });
}

export function SourceInput(props: PropTypes) {
  const required = props.required ?? false;

  if (!props.shouldRender) return null;

  const extraOptions = props.extraOptions ?? [];
  const groupedOptions = mapToGroupedOptions(props.sources).concat(
    extraOptions,
  );
  const options = groupedOptions.map((go) => go.options).flat();

  return (
    <div className='fs-5 fw-semibold'>
      <GroupedSelect
        label={props.label}
        labelClassName={props.labelClassName}
        defaultValue={options.find(
          (o) => o.value === props.initSourceId?.toString(),
        )}
        placeholder={'Select source'}
        formHookContext={{
          controllerName: 'sourceId',
          formControl: props.formControl,
          validate: required ? (x) => x != null : undefined,
        }}
        options={groupedOptions}
        mandatory={required}
        disabled={props.disabled}
        isLoading={props.isLoading}
        autoHideKeyboardOnSmallerScreen={true}
      />
    </div>
  );
}
