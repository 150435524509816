import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';
import {
  SendSelfSchedulingEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { DelayDropdown } from '../Dropdown/DelayDropdown';
import { StagesDropdown } from '../Dropdown/StagesDropdown';
import { EmailTemplateDropdown } from '../Dropdown/EmailTemplateDropdown';
import { InterviewTemplateDropdown } from '../Dropdown/InterviewTemplateDropdown';

interface PropTypes {
  emailTemplates: EmailTemplate[];
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  planTemplate?: PlanTemplate;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

export function SendSelfSchedulingLinkTriggerRow(props: PropTypes) {
  const [interviewTemplatesLoading, setInterviewTemplatesLoading] =
    useState<boolean>(true);
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [interviewTemplateOptions, setInterviewTemplateOptions] = useState<
    SelectOption[]
  >([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [delayOptions, setDelayOptions] = useState([]);

  const updateTrigger = () => {
    if (interviewTemplatesLoading) {
      return;
    }

    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'send_self_scheduling_email',
        emailTemplateId: parseInt(
          emailTemplateOptions.find((v) => v.selected)?.value,
        ),
        interviewTemplateId: parseInt(
          interviewTemplateOptions.find((v) => v.selected)?.value,
        ),
        applicationStatuses: ['active'],
        stageIds: stageOptions
          .filter((v) => v.selected)
          .map((v) => parseInt(v.value)),
        delayDays: parseInt(delayOptions.find((v) => v.selected)?.value ?? ''),
      },
      props.counter,
    );
  };

  useEffect(updateTrigger, [
    emailTemplateOptions,
    interviewTemplateOptions,
    stageOptions,
    delayOptions,
  ]);

  return (
    <>
      <EmailTemplateDropdown
        trigger={props.trigger as SendSelfSchedulingEmailTrigger}
        emailTemplates={props.emailTemplates}
        emailTemplateOptions={emailTemplateOptions}
        setEmailTemplateOptions={setEmailTemplateOptions}
        isMandatory={false}
      />
      <InterviewTemplateDropdown
        trigger={props.trigger as SendSelfSchedulingEmailTrigger}
        interviewTemplateOptions={interviewTemplateOptions}
        setInterviewTemplateOptions={setInterviewTemplateOptions}
        interviewTemplatesLoading={interviewTemplatesLoading}
        setInterviewTemplatesLoading={setInterviewTemplatesLoading}
      />
      <StagesDropdown
        trigger={props.trigger}
        planTemplate={props.planTemplate}
        stageOptions={stageOptions}
        setStageOptions={setStageOptions}
        onlyInterviewStages={true}
      />
      <DelayDropdown
        trigger={props.trigger}
        delayOptions={delayOptions}
        setDelayOptions={setDelayOptions}
      />
    </>
  );
}
