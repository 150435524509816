import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { DataPrivacySettingsForm } from '.';
import { RichTextEditor } from '../../../../components/RichTextEditor';
import { AreaRichTextComposer } from '../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { Header } from './Header';

export function CandidateConsent(props: {
  form: UseFormReturn<DataPrivacySettingsForm, any>;
}) {
  return (
    <div>
      <Header
        title='Candidate Consent'
        subtitle='Customise the candidate consent information displayed on your application forms.'
      />
      <Editor form={props.form} />
    </div>
  );
}

function Editor(props: { form: UseFormReturn<DataPrivacySettingsForm, any> }) {
  return (
    <div className='w-50'>
      <RichTextEditor<DataPrivacySettingsForm>
        formHookContext={{
          controllerName: 'candidateConsentEditorState',
          formControl: props.form.control,
        }}
        render={(field) => (
          <AreaRichTextComposer
            defaultEditorState={field.value?.toString()}
            size='xs'
            onChangeJson={(value: string) => field.onChange(value)}
            onChangeHtml={(html: string) =>
              props.form.setValue('candidateConsentHtml', html)
            }
          />
        )}
      />
    </div>
  );
}
