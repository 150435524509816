import React from 'react';
import { Col, Row } from 'reactstrap';
import { UseFormRegister } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { ApplicationFormPreviewButton } from './ApplicationFormPreviewButton';
import classNames from 'classnames';
import { useStateMachine } from 'little-state-machine';
import { buildUrl } from '../../../../../../../utils/url';
import {
  ApplicationFormTemplateView,
  SectionListView,
} from './SectionListView';

interface PropTypes {
  applicationFormTemplate?: ApplicationFormTemplateView;
  isLoading: boolean;
  register: UseFormRegister<CreateJobDto>;
}

export function ApplicationFormTemplateBody(props: PropTypes) {
  const { state } = useStateMachine({});

  if (props.applicationFormTemplate == null) return null;

  return (
    <>
      <Row className='d-flex mt-4 mb-3 flex-nowrap justify-content-between'>
        <Col xs='8' className='align-self-center'>
          <h3 className='m-0'>{props.applicationFormTemplate.name}</h3>
        </Col>
        <Col className='text-end' xs='4'>
          <div
            className={classNames(
              'd-flex',
              'align-items-center',
              'justify-content-end',
            )}
          >
            <ApplicationFormPreviewButton
              previewUrl={buildUrl(
                `${window.location.origin}/applicant_tracking/application_form_templates/${props.applicationFormTemplate.id}/preview`,
                [
                  { key: 'name', value: state.setupJob.name },
                  { key: 'department_id', value: state.setupJob.departmentId },
                  { key: 'location_id', value: state.setupJob.locationId },
                  { key: 'location_type', value: state.setupJob.locationType },
                  {
                    key: 'employment_type',
                    value: state.setupJob.employmentType,
                  },
                  {
                    key: 'application_form_template_candidate_consent',
                    value:
                      props.applicationFormTemplate.candidateConsentEnabled?.toString() ??
                      'false',
                  },
                ],
              )}
              classNames={'ms-3'}
            />
          </div>
        </Col>
      </Row>
      <SectionListView
        applicationFormTemplate={props.applicationFormTemplate}
        isLoading={props.isLoading}
      />
    </>
  );
}
