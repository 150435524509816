import classNames from 'classnames';
import React from 'react';
import {
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';
import { ClearIcon } from '../../ClearIcon';
import { LoadingSpinnerIcon } from '../../LoadingSpinnerIcon';
import { FileError } from 'use-file-picker';
import { MaliciousFileContentError } from '..';

export interface PropTypes<E> {
  classNames?: string;
  allowedFormats: string[];
  controllerContentName: string;
  controllerFileName: string;
  value?: PathValue<E, Path<E>>;
  disabled?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  errors?: FileError[];
  clear: () => void;
  formRegister: UseFormRegister<E>;
  openFileSelector: () => void;
  setValue: UseFormSetValue<E>;
}

function placeholder(
  allowedFormats: string[],
  isLoading?: boolean,
  errors?: FileError[],
) {
  if (isLoading === true) {
    return 'Checking file integrity...';
  }

  if (errors?.[0]?.name === MaliciousFileContentError) {
    return 'File might be corrupt! Upload another file';
  }

  if (allowedFormats?.length === 1) {
    return `Choose file (${allowedFormats.join(',')})`;
  }

  return 'Choose file';
}

function resetFileData<E>(
  controllerContentName: string,
  controllerFileName: string,
  clear: () => void,
  setValue: UseFormSetValue<E>,
) {
  clear();
  setValue(controllerContentName as Path<E>, undefined, {
    shouldValidate: true,
  });
  setValue(controllerFileName as Path<E>, undefined, {
    shouldValidate: true,
  });
}

function InputBody<E>(props: PropTypes<E>) {
  const disabled = props.disabled || props.isLoading;

  return (
    <span
      className={classNames(
        'd-flex form-control fs-5 justify-content-between flex-nowrap',
        styles.placeholder,
        disabled ? 'bg-gray' : 'bg-white',
        { 'is-invalid': props.errors?.length > 0 },
      )}
    >
      {props.isLoading ? (
        <input
          type='text'
          className={classNames(
            'w-100',
            'no-border',
            styles['placeholder-input'],
            'bg-gray',
          )}
          readOnly
          placeholder={placeholder(props.allowedFormats, props.isLoading)}
          disabled={true}
        />
      ) : (
        <input
          type='text'
          className={classNames(
            'w-100',
            'no-border',
            { [styles['pointer-cursor']]: !disabled },
            styles['placeholder-input'],
            { 'bg-gray': disabled },
          )}
          onClick={() => props.openFileSelector()}
          readOnly
          placeholder={placeholder(
            props.allowedFormats,
            props.isLoading,
            props.errors,
          )}
          {...props.formRegister(props.controllerFileName as Path<E>, {
            required: props.isRequired,
          })}
          disabled={disabled}
        />
      )}
      {props.isLoading && (
        <LoadingSpinnerIcon className='text-info my-auto' size='md' />
      )}
      {props.value != null && !props.isLoading && (
        <ClearIcon
          classNames={styles['pointer-cursor']}
          onClick={() =>
            resetFileData(
              props.controllerContentName,
              props.controllerFileName,
              props.clear,
              props.setValue,
            )
          }
        ></ClearIcon>
      )}
    </span>
  );
}

export function AttachmentInputBody<E>(props: PropTypes<E>) {
  const isLoading = props.isLoading ?? false;

  return (
    <div className={classNames('input-group', props.classNames)}>
      <input
        type='hidden'
        {...props.formRegister(props.controllerContentName as Path<E>, {
          required: props.isRequired,
        })}
        disabled={props.disabled || isLoading}
      />
      <InputBody {...props} isLoading={isLoading} />
      {!isLoading && (
        <Button
          size='md'
          className={classNames(
            styles['browser-button'],
            'text-primary',
            'bg-blue-gray-200',
          )}
          disabled={props.disabled}
          onClick={() => props.openFileSelector()}
        >
          Browse
        </Button>
      )}
    </div>
  );
}
