import React, { useEffect } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { LabelledMultiSelect } from '../../../../../../../../components/LabelledMultiSelect';
import { SendEmailTrigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapApplicationStatusesToSelectOptions } from '../../triggerSelectOptions';

interface PropTypes {
  trigger: SendEmailTrigger;
  statusOptions: SelectOption[];
  setStatusOptions: (options: SelectOption[]) => void;
}

export function StatusDropdown(props: PropTypes) {
  const initStatusOptions = mapApplicationStatusesToSelectOptions(
    props.trigger.applicationStatuses,
  );

  useEffect(() => {
    props.setStatusOptions(initStatusOptions);
  }, []);

  return (
    <TriggerRowDropdown>
      <LabelledMultiSelect
        options={props.statusOptions}
        selected={props.statusOptions.filter((v: SelectOption) => v.selected)}
        labelClassName='fs-5'
        label={'If the Candidate status'}
        manySelectedText={`${
          props.statusOptions.filter((v) => v.selected).length
        } selected statuses`}
        singleSelectedText='1 selected status'
        placeholder='Select candidate status'
        min={1}
        hasSelectAll
        selectAllLabel='All Candidate Statuses'
        onChange={(options) =>
          props.setStatusOptions(
            mapApplicationStatusesToSelectOptions(options.map((o) => o.value)),
          )
        }
      />
    </TriggerRowDropdown>
  );
}
