import React from 'react';
import { MultiPaginatedSelect } from './MultiPaginatedSelect';
import classNames from 'classnames';
import { SelectOption } from '.';

interface AdditionalType {
  page: number;
}

interface LoadOptionsResponse {
  options: SelectOption[];
  hasMore: boolean;
  additional?: AdditionalType;
}

export type LoadOptionsType = (
  search: string,
  loadedOptions: SelectOption[],
  additional: AdditionalType,
) => Promise<LoadOptionsResponse>;

export function LabelledMultiPaginatedSelect(props: {
  label: string;
  max: number;
  selectedItems: SelectOption[];
  loadOptions: LoadOptionsType;
  setSelectedItems: (opts: SelectOption[]) => void;
  additional: AdditionalType;
}) {
  return (
    <>
      <div
        className={classNames('px-1 mb-2 d-flex flex-nowrap align-items-end')}
      >
        <>
          <label className='me-auto text-truncate'>{`${props.label}s`}</label>

          <small className='text-muted text-nowrap'>Max. {props.max}</small>
        </>
      </div>
      <MultiPaginatedSelect
        additional={props.additional}
        isMulti={true}
        value={props.selectedItems}
        loadOptions={props.loadOptions}
        onChange={(opts) => {
          if (!props.max || opts.length <= props.max) {
            props.setSelectedItems(opts as SelectOption[]);
          }
        }}
        debounceTimeout={300}
        placeholder={`All ${props.label}s`}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </>
  );
}
