import React, { useCallback, useState } from 'react';
import { EmptyState } from '../../../../../../components/EmptyState';
import { Pagination } from '../../../../../../components/Pagination';
import { JobPost } from '../../../../../../entities/applicant_tracking/JobPost';
import UndrawEmptyState from '../../../../../../images/undraw_empty_state_cactus.svg';
import { JobPostCard } from './JobPostCard';

interface PropTypes {
  jobPosts: JobPost[];
  whitelabelEnabled: boolean;
}

const PAGE_SIZE = 10;

function EmptyList(props: { whitelabelEnabled: boolean }) {
  return (
    <EmptyState
      title={'No jobs found'}
      text={'Use the above filters to refine your search.'}
      cardless={true}
      imageSrc={UndrawEmptyState}
      imageClassNames={'w-auto h-80'}
      excludeImage={props.whitelabelEnabled}
      classNames={'w-100'}
    />
  );
}

function getPageJobPosts(jobPosts: JobPost[], currentPage: number) {
  const prevIndex = (currentPage - 1) * PAGE_SIZE;
  const nextIndex = currentPage * PAGE_SIZE;

  return jobPosts.slice(prevIndex, nextIndex);
}

export function JobPostsList(props: PropTypes) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;
  const totalRows = props.jobPosts.length;
  const currentJobPosts = useCallback(
    (jobPosts: JobPost[], currentPage: number) =>
      getPageJobPosts(jobPosts, currentPage),
    [currentPage],
  );

  if (totalRows === 0) {
    return <EmptyList whitelabelEnabled={props.whitelabelEnabled} />;
  }

  return (
    <div className='mt-6h'>
      {currentJobPosts(props.jobPosts, currentPage).map((jobPost, index) => (
        <JobPostCard
          key={index}
          jobPost={jobPost}
          whitelabelEnabled={props.whitelabelEnabled}
        />
      ))}
      <div className='mb-4 pt-3'>
        <Pagination
          page={currentPage}
          pageCount={Math.ceil(totalRows / pageSize)}
          visiblePageCount={PAGE_SIZE}
          disabled={false}
          onPageClicked={(newPage: number) => setCurrentPage(newPage)}
          align='center'
        />
      </div>
    </div>
  );
}
