import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { JobPost } from '../../../../entities/JobPost';
import { IconSpan } from '../../../../components/IconSpan';
import { jobPostLink } from '../../TopNavbar/NavActions/JobPostButton';
import { Trigger } from '../../../../entities/applicant_tracking/Trigger';
import { TriggerRow } from './TriggerRow';
import classNames from 'classnames';

interface PropTypes {
  applicationForms: string[];
  interviewProcesses: string[];
  jobPosts: JobPost[];
  jobStatus: string;
  triggers: Trigger[];
  subDomain?: string;
}

function showJobPostLink(jobStatus: string, hasLink: boolean) {
  return jobStatus === 'published' && hasLink;
}

export function JobArtefacts(props: PropTypes) {
  const isEmpty =
    props.jobPosts.length +
      props.applicationForms.length +
      props.interviewProcesses.length ===
    0;

  return (
    <DeprecatedPanel className='p-4 my-2'>
      <div className='fs-3 fw-semibold'>Job Setup</div>
      {isEmpty ? (
        <EmptyState
          title='No data here'
          text='For more information contact our team at support@screenloop.com'
          excludeImage={true}
          titleClassName='fs-3'
          textFontSize='fs-6'
          classNames='my-6 py-6'
        />
      ) : (
        <div className='fs-5 overflow-auto'>
          {props.jobPosts && props.jobPosts.length > 0 && (
            <div className='my-2 py-2 border-bottom'>
              <div className='mb-3 text-dark-200 '>Job Posts</div>
              {props.jobPosts.map((jobPost, index) => (
                <div className='mb-2' key={index}>
                  {showJobPostLink(
                    props.jobStatus,
                    !!jobPostLink(props.jobPosts, props.subDomain),
                  ) ? (
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href={jobPostLink(props.jobPosts, props.subDomain)}
                    >
                      <IconSpan
                        className='text-info'
                        position='end'
                        icon={{
                          name: 'bi-box-arrow-up-right',
                          className: 'text-info',
                        }}
                        spanClassName='text-truncate'
                        spanText={jobPost.name}
                      />
                    </a>
                  ) : (
                    <div className='d-flex'>
                      <span className='text-truncate'>{jobPost.name}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {props.applicationForms && props.applicationForms.length > 0 && (
            <div className='my-2 py-2 border-bottom'>
              <div className='mb-3 text-dark-200 '>Application Form</div>
              {props.applicationForms.map((applicationForm, index) => (
                <div className='mb-2' key={index}>
                  {applicationForm}
                </div>
              ))}
            </div>
          )}
          {props.interviewProcesses && props.interviewProcesses.length > 0 && (
            <div
              className={classNames(
                'my-2 py-2',
                props.triggers.length > 0 && 'border-bottom',
              )}
            >
              <div className='mb-3 text-dark-200'>Job Workflow</div>
              {props.interviewProcesses.map((interviewProcess, index) => (
                <div className='mb-2' key={index}>
                  {interviewProcess}
                </div>
              ))}
            </div>
          )}
          {props.triggers && props.triggers.length > 0 && (
            <div className='my-2 py-2'>
              <div className='mb-3 text-dark-200'>Job Workflow Automations</div>
              {props.triggers.map((trigger, index) => (
                <TriggerRow trigger={trigger} key={index} index={index} />
              ))}
            </div>
          )}
        </div>
      )}
    </DeprecatedPanel>
  );
}
