import { sizeSmallerThan, useScreenSize } from './useScreenSize';

export function isSearchable(
  isSearchable: boolean,
  autoHideKeyboard: boolean,
): boolean {
  if (autoHideKeyboard) {
    const screenSize = useScreenSize();
    return sizeSmallerThan(screenSize, 'sm') ? false : isSearchable;
  } else {
    return isSearchable;
  }
}
