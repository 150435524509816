import snakecaseKeys from 'snakecase-keys';
import { DataPrivacySettings } from '../../../entities/v1/DataPrivacySettings';
import { ApiService } from '../../ApiService';

export class DataPrivacySettingsService {
  public static show(): Promise<DataPrivacySettings> {
    return ApiService.get<DataPrivacySettings>('/api/v1/data_privacy_settings');
  }

  public static update(params: DataPrivacySettings): Promise<void> {
    return ApiService.patch(
      '/api/v1/data_privacy_settings',
      snakecaseKeys(params),
    );
  }
}
