import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { Col, Row } from 'reactstrap';
import {
  TopChoices,
  TopChoicesChartType,
} from '../../shared_panels/TopChoices';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import React from 'react';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../PageBreak';

export function SatisfactionTopChoices(props: {
  topPositives?: QuestionTopChoices;
  topNegatives?: QuestionTopChoices;
}) {
  if (props.topPositives == null) return null;
  if (props.topPositives.top_choices.length === 0) return null;

  return (
    <>
      <PageBreak />
      <Row className={classNames('gy-4', REPORTING_SECTION_CLASS)}>
        <Col sm='12' lg='6'>
          <TopChoices
            title='Satisfied'
            subtitle={props.topPositives.question_text}
            topChoices={props.topPositives.top_choices}
            fillColor={stylesheetExports.chartPink}
            type={TopChoicesChartType.Circle}
          />
        </Col>
        <Col sm='12' lg='6'>
          <TopChoices
            title='Dissatisfied'
            subtitle={props.topNegatives.question_text}
            topChoices={props.topNegatives.top_choices}
            fillColor={stylesheetExports.chartBlue}
            type={TopChoicesChartType.Circle}
          />
        </Col>
      </Row>
    </>
  );
}
