import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  className?: string;
  size?: Size;
  truncate?: boolean;
  children: React.ReactNode;
}

function chipSizeClasses(size: Size) {
  switch (size) {
    case 'sm':
      return 'fs-6 px-2 py-1';
    case 'md':
      return 'fs-5 px-2h py-2';
    case 'lg':
      return 'fs-4 px-3 py-2h';
    default:
      return 'fs-6 px-2 py-1';
  }
}

export function Chip(props: PropTypes) {
  const truncate = props.truncate ?? false;
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <>
      <small
        className={classNames(
          'badge',
          'rounded-pill',
          'fw-normal',
          { 'text-truncate': truncate },
          props.className,
          props.size && chipSizeClasses(props.size),
        )}
        ref={ref}
      >
        {props.children}
      </small>
      {truncate && (
        <Tooltip
          target={ref}
          isOpen={isTooltipOpen}
          toggle={() => setIsTooltipOpen(!isTooltipOpen)}
        >
          {props.children}
        </Tooltip>
      )}
    </>
  );
}
