import React, { useEffect, useState } from 'react';
import { LabelledToggle } from '../../../../../../components/LabelledToggle';
import { TriggerRow } from './TriggerRow';
import { AddTriggerButton } from './AddTriggerButton';
import { EmailTemplateService } from '../../../../../../services/EmailTemplateService';
import { PlanTemplate } from '../../../../../../entities/applicant_tracking/PlanTemplate';
import { Control, useWatch } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { Trigger } from '../../../../../../entities/applicant_tracking/Trigger';
import { ApplicationFormTemplate } from '../../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { ApplicationFormTemplateService } from '../../../../../../services/applicant_tracking/ApplicationFormTemplateService';
import { LocationService } from '../../../../../../services/applicant_tracking/LocationService';
import { HrisProvider } from '../../../../../../enums/IntegrationProvider/HrisProvider';
import { createBlankTrigger } from '../../../../../../utils/applicant_tracking/trigger';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  planTemplate: PlanTemplate | undefined;
  triggers: Trigger[];
  triggersEnabled: boolean;
  applicationFormTemplateId: number;
  locationId: string;
  setTriggers: (triggers: Trigger[]) => void;
  setTriggersEnabled: (enabled: boolean) => void;
  hrisProvider?: HrisProvider;
  selfSchedulingAutomationEnabled: boolean;
}

function initTriggers(triggers: Trigger[]) {
  if (triggers.length > 0) {
    return triggers;
  }

  return [createBlankTrigger()];
}

export function JobWorkflowTriggers(props: PropTypes) {
  const jobWorkflowIdControllerName = 'jobWorkflowId';

  const [isLoading, setIsLoading] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState(null);
  const [applicationFormTemplate, setApplicationFormTemplate] =
    useState<ApplicationFormTemplate>(null);
  const [jobLocation, setJobLocation] = useState<string>(null);

  const watchJobWorkflowId = useWatch({
    control: props.formControl,
    name: jobWorkflowIdControllerName,
  });

  useEffect(() => {
    (async () => {
      if (emailTemplates == null || applicationFormTemplate == null) {
        Promise.all([
          EmailTemplateService.list(),
          ApplicationFormTemplateService.get(props.applicationFormTemplateId),
          props.locationId
            ? LocationService.get(props.locationId)
            : Promise.resolve(null),
        ]).then(([emailTemplates, applicationFormTemplate, location]) => {
          setEmailTemplates(emailTemplates);
          setApplicationFormTemplate(applicationFormTemplate);
          setJobLocation(location.country || 'job_location');
          setIsLoading(false);
        });
      }
    })();
  }, []);

  useEffect(() => {
    props.setTriggersEnabled(props.triggersEnabled && !!watchJobWorkflowId);
  }, [watchJobWorkflowId]);

  useEffect(() => {
    !props.triggersEnabled && props.setTriggers([]);
  }, [props.triggersEnabled]);

  const onUpdateTrigger = (trigger: Trigger, index: number) => {
    const newFormData = [...props.triggers];
    newFormData[index] = trigger;
    props.setTriggers(newFormData);
  };

  return (
    <>
      <div className='d-flex flex-nowrap align-items-center'>
        <span className='fs-5 fw-semibold me-2h'>
          Does this job include automations?
        </span>
        <LabelledToggle
          checked={props.triggersEnabled}
          inputName={'triggers'}
          size='sm'
          checkedLabel='Yes'
          uncheckedLabel='No'
          labelPlacement='end'
          labelClassnames='fs-5 text-dark-200'
          disabled={!props.planTemplate}
          onChange={() => props.setTriggersEnabled(!props.triggersEnabled)}
        />
      </div>
      <div className='mt-4'>
        {props.planTemplate != null &&
          !isLoading &&
          initTriggers(props.triggers).map((trigger, index) => (
            <TriggerRow
              emailTemplates={emailTemplates}
              applicationFormTemplate={applicationFormTemplate}
              planTemplate={props.planTemplate}
              disabled={!props.triggersEnabled}
              counter={index}
              trigger={trigger}
              key={trigger.uniqueKey}
              triggers={props.triggers}
              jobLocation={jobLocation}
              setTriggers={props.setTriggers}
              onUpdateTrigger={onUpdateTrigger}
              hrisProvider={props.hrisProvider}
              selfSchedulingAutomationEnabled={
                props.selfSchedulingAutomationEnabled
              }
            />
          ))}
        {props.triggersEnabled && (
          <AddTriggerButton
            triggers={props.triggers}
            setTriggers={props.setTriggers}
          />
        )}
      </div>
    </>
  );
}
