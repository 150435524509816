import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import classNames from 'classnames';
import { LabelledMultiSelect } from '../../LabelledMultiSelect';
import { MoreFilters } from '../../MoreFilters';
import {
  FilterStates,
  generateResetFilterStates,
  OnChangeFilterStatesCallback,
  SelectOption,
} from './filterState';
import styles from './styles.module.scss';
import { LabelledSelect } from '../../LabelledSelect';
import { FilterSelector } from '../../../entities/candidate_experience/FilterSelector';
import { LabelledDatePicker } from '../../LabelledDatePicker';
import cloneDeep from 'lodash.clonedeep';
import { AdditionalLabelledMultiSelectCols } from './AdditionalLabelledMultiSelectCols';
import { DateFilter } from '../../DateRangePicker';
import { AnalyticsFiltersService } from '../../../services/v1/candidate_experience/Analytics';
import {
  FilterResult,
  MultiSelectAsyncFilter,
} from '../../MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../utils/mapResultToSelectOption';

interface PropTypes {
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
  moreFiltersShown: boolean;
  className?: string;
  hideJob?: boolean;
  hideMoreFiltersButton?: boolean;
  candidateDemographicQuestionsEnabled?: boolean;
  hideBenchmark?: boolean;
  hideHireTeam?: boolean;
}

function buildFilterByOptions(
  props: PropTypes,
  state: FilterStates,
): [
  (search: string, page: number) => Promise<FilterResult>,
  SelectOption[],
  string,
  string,
] {
  switch (state.filterSelector.selected.value) {
    case FilterSelector.Departments.valueOf():
      return [
        (name, page) =>
          mapResultToSelectOption(
            AnalyticsFiltersService.departments({ name, page }),
            (filter) => filter.departments,
            true,
          ),
        state.departments.selected,
        'Department',
        'departments',
      ];

    case FilterSelector.HiringManagers.valueOf():
      return [
        (name, page) =>
          mapResultToSelectOption(
            AnalyticsFiltersService.hiringManagers({ name, page }),
            (filter) => filter.hiringMembers,
            true,
          ),
        state.hiringManagers.selected,
        'Manager',
        'hiringManagers',
      ];
    case FilterSelector.Recruiters.valueOf():
      return [
        (name, page) =>
          mapResultToSelectOption(
            AnalyticsFiltersService.recruiters({ name, page }),
            (filter) => filter.hiringMembers,
            true,
          ),
        state.recruiters.selected,
        'Recruiter',
        'recruiters',
      ];
    default:
      return [
        (name, page) =>
          mapResultToSelectOption(
            AnalyticsFiltersService.jobs({ name, page }),
            (filter) => filter.jobs,
            true,
          ),
        state.jobs.selected,
        'Job',
        'jobs',
      ];
  }
}

export function Filters(props: PropTypes) {
  const [state, setState] = useState({ ...props.filterStates });
  const [moreFilters, setMoreFilters] = useState(props.moreFiltersShown);

  const appliedHiddenFilters =
    +(props.filterStates.surveyTemplates.selected.length > 0) +
    +(props.filterStates.locations.selected.length > 0) +
    +(props.filterStates.ethnicities.selected.length > 0) +
    +(props.filterStates.genders.selected.length > 0) +
    +(props.filterStates.ageRanges.selected.length > 0);

  useEffect(() => {
    setState({ ...props.filterStates });
  }, [props.filterStates]);

  function handleFilterUpdate(name: string) {
    return (option: SelectOption | SelectOption[] | DateFilter | boolean) => {
      if (name === 'dateRange') {
        setState({ ...state, [name]: option as DateFilter });
      } else {
        const newState = cloneDeep(state);
        if (name === 'filterSelector') {
          newState.jobs.selected = [];
          newState.departments.selected = [];
          newState.hiringManagers.selected = [];
          newState.recruiters.selected = [];
        }
        newState[name].selected = option;
        setState(newState);
      }
    };
  }

  function handleApplyClick() {
    props.onApplyFilter(state, moreFilters);
  }

  function handleResetClick() {
    props.onApplyFilter(generateResetFilterStates(state), moreFilters);
  }

  const [
    loadOptions,
    filterBySelectedOptions,
    filterByOptionsLabel,
    filterUpdateHandlerName,
  ] = buildFilterByOptions(props, state);

  return (
    <>
      <Row className='fs-5 h-100 mb-3'>
        {!props.hideJob && (
          <>
            <Col xs='12' md='4' xl='2' className='mt-2'>
              <LabelledSelect
                options={props.filterStates.filterSelector.all}
                selected={state.filterSelector.selected}
                onChange={handleFilterUpdate('filterSelector')}
                label='Filter by'
                className={'w-100'}
              />
            </Col>
            <Col xs='12' md='4' xl='2' className='mt-2'>
              <MultiSelectAsyncFilter
                isMulti
                label={filterByOptionsLabel}
                loadOptions={loadOptions}
                onChange={handleFilterUpdate(filterUpdateHandlerName)}
                selected={filterBySelectedOptions}
                max={10}
              />
            </Col>
          </>
        )}
        {!props.hideBenchmark && (
          <Col xs='12' md='4' xl='2' className='mt-2'>
            <LabelledSelect
              options={props.filterStates.benchmarkType.all}
              selected={state.benchmarkType.selected}
              onChange={handleFilterUpdate('benchmarkType')}
              label='Benchmark'
              className={'w-100'}
              testId='benchmark-filter'
            />
          </Col>
        )}
        <Col xs='12' md='4' xl='2' className='mt-2'>
          <LabelledDatePicker
            label='Date'
            dateFilter={state.dateRange}
            onChange={(start, end, range) =>
              handleFilterUpdate('dateRange')({ start, end, range })
            }
            className={'w-100'}
          />
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'd-flex text-nowrap mt-2 align-bottom align-self-end',
            styles.filterButtonsColumn,
          )}
        >
          <div
            className={classNames(
              'd-flex justify-content-between p-0',
              styles.filterButtons,
            )}
          >
            <div>
              <Button
                className={classNames('me-3')}
                color='secondary'
                onClick={() => void handleResetClick()}
              >
                Reset
              </Button>
              <Button color='primary' onClick={() => void handleApplyClick()}>
                Apply
              </Button>
            </div>
          </div>
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'mt-2 align-bottom align-self-end text-end',
            styles.filterButtonsColumn,
          )}
        >
          {!props.hideMoreFiltersButton && (
            <MoreFilters
              moreFilters={moreFilters}
              appliedHiddenFilters={appliedHiddenFilters}
              onChange={setMoreFilters}
            />
          )}
        </Col>
        <Collapse isOpen={moreFilters}>
          <Row>
            <AdditionalLabelledMultiSelectCols
              selectedSurveyTemplates={state.surveyTemplates.selected}
              selectedLocations={state.locations.selected}
              handleFilterUpdate={handleFilterUpdate}
            />
            {props.candidateDemographicQuestionsEnabled && (
              <>
                <Col xs='12' md='4' xl='2' className='mt-2'>
                  <LabelledMultiSelect
                    options={props.filterStates.ethnicities.all}
                    selected={state.ethnicities.selected}
                    label='Ethnicity'
                    placeholder='All Ethnicities'
                    singleSelectedText='1 selected ethnicity'
                    manySelectedText='%d selected ethnicities'
                    onChange={handleFilterUpdate('ethnicities')}
                  />
                </Col>
                <Col xs='12' md='4' xl='2' className='mt-2'>
                  <LabelledMultiSelect
                    options={props.filterStates.genders.all}
                    selected={state.genders.selected}
                    label='Gender'
                    placeholder='All Genders'
                    singleSelectedText='1 selected gender'
                    manySelectedText='%d selected genders'
                    onChange={handleFilterUpdate('genders')}
                  />
                </Col>
                <Col xs='12' md='4' xl='2' className='mt-2'>
                  <LabelledMultiSelect
                    options={props.filterStates.ageRanges.all}
                    selected={state.ageRanges.selected}
                    label='Age'
                    placeholder='All Ages'
                    singleSelectedText='1 selected age range'
                    manySelectedText='%d selected age ranges'
                    onChange={handleFilterUpdate('ageRanges')}
                  />
                </Col>
              </>
            )}
          </Row>
        </Collapse>
      </Row>
    </>
  );
}
