import { Cps } from '../../../../entities/v1/candidate_experience/Cps';
import { ApiService } from '../../../ApiService';
import { FilterParams, toJsonParams } from '../filterParams';

export class CpsService {
  public static get(params: FilterParams): Promise<Cps> {
    return ApiService.get<Cps>(
      '/api/v1/candidate_experience/analytics/cps',
      toJsonParams(params),
    );
  }
}
