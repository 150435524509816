import React from 'react';

import { useAutomaticQuestions } from '../../../../../queries/v1/interview_intelligence/useAutomaticQuestions';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { Interview } from '../../../../../entities/Interview';
import classNames from 'classnames';
import { InterviewMarkerList } from '../../../../../components/Interview/InterviewMarkerList';
import styles from './styles.module.scss';
import {
  AutomaticQuestion,
  AutomaticQuestionList,
} from '../../../../../entities/v1/interview_intelligence/AutomaticQuestion';
import { QueryObserverResult } from 'react-query';

interface PropTypes {
  interview: Interview;
  playTimestamp: number;
  onSelectTimestamp?: (timestamp: number) => void;
}

function QuestionBlock(props: {
  interview: Interview;
  playTimestamp: number;
  onSelectTimestamp?: (timestamp: number) => void;
  automaticQuestions: AutomaticQuestion[];
  refetch: (
    options?: unknown,
  ) => Promise<QueryObserverResult<AutomaticQuestionList, unknown>>;
}) {
  return (
    <div
      className={classNames(
        'mt-2',
        'w-100',
        'overflow-auto',
        'fs-5',
        styles['expandable-questions'],
      )}
      style={{ scrollBehavior: 'smooth' }}
    >
      <InterviewMarkerList
        playTimestamp={props.playTimestamp}
        interview={props.interview}
        selectedHighlightType={'automatic_question'}
        automaticQuestions={props.automaticQuestions}
        refetchAutomaticQuestions={props.refetch}
        onSelectTimestamp={props.onSelectTimestamp}
        showResponses={true}
        showNameAndOffset={false}
        showContextQuestion={false}
      />
    </div>
  );
}

export function Questions(props: PropTypes) {
  const {
    isLoading: isLoadingAutomaticQuestions,
    data: automaticQuestions,
    refetch,
  } = useAutomaticQuestions(props.interview.id, [
    'participant',
    'automatic_notes',
  ]);
  const interviewerAutomaticQuestions =
    automaticQuestions?.automaticQuestions.filter(
      (aq) => aq.automaticNotes.length > 0,
    ) || [];

  return isLoadingAutomaticQuestions ? (
    <div style={{ minHeight: '250px' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <QuestionBlock
      {...props}
      automaticQuestions={interviewerAutomaticQuestions}
      refetch={refetch}
    />
  );
}
