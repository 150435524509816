import { Col, Row } from 'reactstrap';
import { TablePanel } from '../TablePanel';
import React from 'react';
import { QuestionAverage } from '../../../../entities/candidate_experience/QuestionAverage';
import { SelectOption } from '../../../../components/Select';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../../CandidatePulsePage/PageBreak';

export function QuestionAveragesPanel(props: {
  questionAverages: QuestionAverage[];
  benchmarkType: SelectOption;
  title: string;
  isLazyLoading?: boolean;
}) {
  if (
    (!props.questionAverages || props.questionAverages.length === 0) &&
    !props.isLazyLoading
  )
    return null;
  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        <Col xs='12' style={{ minHeight: '250px' }}>
          <TablePanel
            title={props.title}
            textColumnHeader='Questions'
            entries={props.questionAverages?.map((qa) => ({
              text: qa.question_text,
              average: parseFloat(qa.average) / 10,
              benchmarkAverage: parseFloat(qa.benchmark_average) / 10,
              averageTrend: qa.average_trend,
            }))}
            benchmarkType={props.benchmarkType}
            multiplier={10}
            enableBenchmark={true}
            displayAverageTrend={true}
          />
        </Col>
      </Row>
    </>
  );
}
