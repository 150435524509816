import React, { useContext, useEffect } from 'react';
import { Control, UseFormGetValues, UseFormResetField } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SetupJobContext } from '../../index';

interface PropTypes {
  initJobDto: CreateJobDto;
  formControl: Control<CreateJobDto>;
  isEditFlow: boolean;
  getValues: UseFormGetValues<CreateJobDto>;
  resetField: UseFormResetField<CreateJobDto>;
}

export const DEFAULT_MAX_JOB_OPENINGS = 100;

// Generates options from 1 to max
export function buildJobOpeningsOptions(
  maxJobOpenings?: number,
): SelectOption[] {
  return Array.from(
    { length: maxJobOpenings ?? DEFAULT_MAX_JOB_OPENINGS },
    (_, i) => (i + 1).toString(),
  ).map((num: string) => {
    return { value: num, label: num };
  });
}

function getJobOpeningsValue(
  isEditFlow: boolean,
  availableOpenings: number,
  storedJobOpenings: number,
): number {
  if (availableOpenings === undefined || availableOpenings === null) {
    return null;
  }

  if (isNaN(storedJobOpenings)) {
    return availableOpenings;
  }

  return isEditFlow ? availableOpenings + storedJobOpenings : availableOpenings;
}

export function JobOpeningsInput(props: PropTypes) {
  const availableOpenings =
    useContext(SetupJobContext).jobRequisition?.availableOpenings;
  const jobRequisitionId = props.getValues('jobRequisitionId');
  const storedJobOpenings = props.initJobDto.jobOpenings;

  const maxJobOpenings =
    jobRequisitionId &&
    getJobOpeningsValue(
      props.isEditFlow,
      availableOpenings,
      parseInt(storedJobOpenings),
    );
  const required = maxJobOpenings != null;

  useEffect(() => props.resetField('jobOpenings'), [jobRequisitionId]);

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Job Openings'}
          placeholder={'Select number of openings'}
          formHookContext={{
            controllerName: 'jobOpenings',
            formControl: props.formControl,
            required: required,
          }}
          options={buildJobOpeningsOptions(maxJobOpenings)}
        />
      </Col>
    </Row>
  );
}
