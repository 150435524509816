import React, { useEffect, useState } from 'react';
import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { IconButton } from '../../../../../../components/IconButton';
import { Select, SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { HiringTeamMemberInput } from '../HiringTeamMemberInput';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { JobPageGroupHeader } from '../../JobPageGroupHeader';
import { JobHiringMemberService } from '../../../../../../services/applicant_tracking/JobHiringMemberService';
import { UserService } from '../../../../../../services/v1/UserService';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  formWatch: UseFormWatch<CreateJobDto>;
  hiringMemberOptions: SelectOption[];
  jobHiringMemberRoleOptions: SelectOption[];
}

export async function loadHiringMemberOptions(): Promise<SelectOption[]> {
  return (await UserService.list({ pageSize: 1000, page: 1 })).users.map(
    (user) => ({
      value: user.id.toString(),
      label: user.name,
    }),
  );
}

export async function loadJobHiringMemberRoleOptions(): Promise<
  SelectOption[]
> {
  return (await JobHiringMemberService.listRoles()).map(
    (jobHiringMemberRole) => ({
      value: jobHiringMemberRole.id,
      label: jobHiringMemberRole.name,
    }),
  );
}

function availableHiringMemberOptions(
  options: SelectOption[],
  selectedUserIds: string[],
  recruiterId?: string,
) {
  const filterIds = [...selectedUserIds, recruiterId].filter(
    (id) => id != null,
  );
  return options.filter((opt) => !filterIds.includes(opt.value));
}

function addTeamMember(
  append,
  fieldsState: any[],
  setFieldsState: React.Dispatch<React.SetStateAction<any[]>>,
) {
  const newMember = { userId: '', roleId: '' };
  append(newMember);
  const updated = fieldsState.concat(newMember);
  setFieldsState(updated);
}

export function HiringTeamInput(props: PropTypes) {
  const control = props.formControl;
  const { fields, append, remove } = useFieldArray({
    name: 'hiringTeamMembers',
    control,
  });
  const watchRecruiterId = props.formWatch('hiringTeamRecruiterId');
  const watchFieldArray = props.formWatch('hiringTeamMembers');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    fields.map((f) => f.userId.toString()),
  );
  const [fieldsState, setFieldsState] = useState<any[]>(fields);
  const required = true;

  useEffect(() => {
    const fieldsUserIds = controlledFields.map((f) => f.userId);

    if (JSON.stringify(fieldsUserIds) === JSON.stringify(selectedUserIds))
      return;

    setSelectedUserIds(fieldsUserIds);
  }, [controlledFields]);

  return (
    <JobPageGroupHeader
      className='mt-4'
      title={'Hiring Team'}
      mandatory={false}
      description='Select at least one hiring team member'
      lastElement={true}
    >
      <>
        <Row className='mt-3h'>
          <Col xs={3}>
            <LabelledSelectFormHook<CreateJobDto>
              label={'Hiring team members'}
              placeholder={'Select team member'}
              formHookContext={{
                controllerName: 'hiringTeamRecruiterId',
                formControl: props.formControl,
                required: required,
              }}
              options={props.hiringMemberOptions}
              visibleOptions={availableHiringMemberOptions(
                props.hiringMemberOptions,
                selectedUserIds,
              )}
              clearable={false}
            />
          </Col>
          <Col xs={3} className={'align-self-end'}>
            <Select
              options={props.jobHiringMemberRoleOptions}
              selected={props.jobHiringMemberRoleOptions.find(
                (el) => el.value === 'recruiter',
              )}
              isClearable={false}
              disabled={true}
            />
          </Col>
        </Row>
        {controlledFields.map((field, index) => (
          <HiringTeamMemberInput
            key={field.id}
            index={index}
            onDeleteElement={() => {
              fieldsState.splice(index, 1);
              remove(index);
            }}
            memberOptions={props.hiringMemberOptions}
            visibleOptions={availableHiringMemberOptions(
              props.hiringMemberOptions,
              selectedUserIds,
              watchRecruiterId,
            )}
            selectedMember={props.hiringMemberOptions.find(
              (option) => option.value == field.userId,
            )}
            roleOptions={props.jobHiringMemberRoleOptions.filter(
              (option) => option.value !== 'recruiter',
            )}
            selectedRole={props.jobHiringMemberRoleOptions.find(
              (option) => option.value == field.roleId,
            )}
            formControl={props.formControl}
            arrayData={fieldsState}
            updateArrayData={setFieldsState}
          />
        ))}
        <Row className={'mt-3'}>
          <Col xs={'auto'}>
            <IconButton
              color={'borderless'}
              size={'md'}
              iconName={'bi-plus-circle'}
              iconClass={'text-info'}
              iconTextClass={'text-info'}
              buttonText={'Add Team Member'}
              onClick={() => {
                addTeamMember(append, fieldsState, setFieldsState);
              }}
            />
          </Col>
        </Row>
      </>
    </JobPageGroupHeader>
  );
}
