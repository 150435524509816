import { ApiService } from '../../../ApiService';

export class FileService {
  private static readFile(file: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((res, rej) => {
      reader.onload = () => res(reader.result);
      reader.onerror = (error) => rej(error);
    });
  }

  public static virusScan(file: Blob): Promise<void> {
    return FileService.readFile(file).then((x: string) =>
      ApiService.post<void>('/api/v1/security/files/virus_scan', { file: x }),
    );
  }
}
