import React from 'react';
import { SelectOption } from '../Select';
import { LabelledMultiSelect } from '../LabelledMultiSelect';
import { humanize } from '../../utils/humanize';

interface PropTypes {
  options: SelectOption[];
  selected: SelectOption[];
  label: string;
  value: string;
  onChange: (newOptions: SelectOption[]) => void;
  max?: number;
  disabled?: boolean;
}

export function DynamicMultiSelect(props: PropTypes) {
  return (
    <LabelledMultiSelect
      options={props.options}
      selected={props.selected}
      label={props.label}
      placeholder={`All ${humanize(props.value)}s`}
      singleSelectedText={`1 selected ${humanize(
        props.value,
      ).toLocaleLowerCase()}`}
      manySelectedText={`%d selected ${humanize(
        props.value,
      ).toLocaleLowerCase()}s`}
      onChange={props.onChange}
      max={props.max ?? 10}
      disabled={props.disabled}
    />
  );
}
