import React from 'react';
import { Select, SelectOption } from '../../../../../../components/Select';
import { Size } from '../../../../../../utils/types/Size';

interface PropTypes {
  options: SelectOption[];
  selected: SelectOption;
  size: Size;
  onChange: (opt: SelectOption) => void;
}

export function DepartmentSelect(props: PropTypes) {
  return (
    <Select
      name={'Department'}
      placeholder={'All Departments'}
      options={props.options}
      selected={props.selected}
      isClearable={true}
      size={props.size}
      onChange={props.onChange}
      autoHideKeyboardOnSmallerScreen={true}
    />
  );
}
