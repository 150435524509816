import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import { FilterStates } from '../../../components/candidate_experience/Filters/filterState';
import {
  setFilters,
  generateQueryString,
} from '../../../components/candidate_experience/Filters/urlHelper';
import { BenchmarkType } from '../../../entities/candidate_experience/BenchmarkType';
import { HiringTeamPulseAnalytics } from './HiringTeamPulseAnalytics';

interface PropTypes {
  benchmark_types: BenchmarkType[];
  interviewer_pulse_enabled: boolean;
  hiring_manager_pulse_enabled: boolean;
  pulse_standard_nps_calculation: boolean;
  activeTab: string;
}

function StateHolder(props: PropTypes) {
  const initFilters = {
    benchmarkTypes: props.benchmark_types,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterStates, setFilterStates] = useState<FilterStates>(
    setFilters(initFilters, searchParams),
  );
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  function handleApplyFilter(filterStates: FilterStates, moreFilters: boolean) {
    setFilterStates(filterStates);
    setShowMoreFilters(moreFilters);
  }

  useEffect(() => {
    setSearchParams(generateQueryString(filterStates));
  }, [filterStates]);

  return (
    <HiringTeamPulseAnalytics
      {...props}
      filterStates={filterStates}
      moreFiltersShown={showMoreFilters}
      onApplyFilter={handleApplyFilter}
      activeTab={props.activeTab}
      pulseStandardNpsCalculation={props.pulse_standard_nps_calculation}
    />
  );
}

export default function HiringTeamPulsePage(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/analytics/hiring_team_pulse/interviewer'
          element={<StateHolder {...props} activeTab={'Interviewer'} />}
        />
        <Route
          path='/analytics/hiring_team_pulse/hiring_manager'
          element={<StateHolder {...props} activeTab={'Hiring Manager'} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
