import React from 'react';
import { User } from '../../../../../../entities/User';
import { longFormatDate } from '../../../../../../utils/timeFormat';
import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { NoteText } from '../../../../../../components/NoteText';
import { CommentActions } from './CommentActions';
import { ATS_MAX_NOTE_LENGTH } from '../../../../../../components/ApplicantTracking/NoteModal/ModalBody';

interface PropTypes {
  entry: ApplicationFeedComment;
  currentUser: User;
  onEdit: () => void;
  onDelete: () => void;
}

export function CommentBody(props: PropTypes) {
  return (
    <div className='p-3 w-100'>
      <div className='d-flex align-items-center'>
        <span className='fw-bold fs-5 me-2'>{props.entry.authorName}</span>{' '}
        <span className='fs-6 text-dark-200'>
          {longFormatDate(props.entry.createdAt)}
        </span>
        {props.entry.editedAt && (
          <span className='fs-6 text-dark-200'>&nbsp;(Edited)</span>
        )}
        <CommentActions {...props} />
      </div>
      <div className='mt-2'>
        <NoteText
          text={props.entry.text}
          mentionClassName='text-info'
          maxTextLength={ATS_MAX_NOTE_LENGTH}
          mentionTransformer={(mention) => `@${mention}`}
          className='fs-5 text-dark-500'
        />
      </div>
    </div>
  );
}
