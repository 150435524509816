import React from 'react';
import { FileError } from 'use-file-picker';
import { Alert, AlertType } from '../../Alert';
import { MimeTypeAsString } from '../../../utils/fileMimeTypes';
import { UnsupportedFileType, MaliciousFileContentError } from '..';
import { EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME } from '../../../utils/emails';

interface PropTypes {
  allowedFormats?: MimeTypeAsString[];
  errors: FileError[];
  maxFileSizeMb: number;
  maxFiles: number;
  maxFilesSumSizeMb?: number;
}

function ErrorMessage(props: {
  errors: FileError[];
  maxFileSizeMb: number;
  maxFiles: number;
  allowedFormats?: MimeTypeAsString[];
  maxFilesSumSizeMb?: number;
}) {
  const error = props.errors[0];

  if (error.name == EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME) {
    return (
      <span>
        <b>Total files size was exceeded!</b> The maximum allowed total size is{' '}
        {props.maxFilesSumSizeMb}MB.
      </span>
    );
  } else if (error.fileSizeToolarge) {
    return (
      <span>
        <b>File is too large!</b> The maximum allowed file size is{' '}
        {props.maxFileSizeMb}MB.
      </span>
    );
  } else if (error.maxLimitExceeded) {
    return (
      <span>
        <b>Too many files selected!</b> The maximum number of files is{' '}
        {props.maxFiles}.
      </span>
    );
  } else if (
    props.allowedFormats != null &&
    error.name === UnsupportedFileType
  ) {
    return (
      <span>
        <b>File not supported!</b> The upload is only available for{' '}
        {props.allowedFormats.join(', ')} files.
      </span>
    );
  } else if (error.name === MaliciousFileContentError) {
    return <span>File contains suspicious content</span>;
  }

  return null;
}

export function AttachmentInputErrorMessages(props: PropTypes) {
  if (!props.errors || props.errors.length == 0) {
    return null;
  }

  return (
    <Alert type={AlertType.Danger} clearable>
      <ErrorMessage
        errors={props.errors}
        maxFileSizeMb={props.maxFileSizeMb}
        maxFiles={props.maxFiles}
        maxFilesSumSizeMb={props.maxFilesSumSizeMb}
        allowedFormats={props.allowedFormats}
      />
    </Alert>
  );
}
