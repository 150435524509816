import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';
import { Select, SelectOption } from '../../../../../../../components/Select';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import {
  AVAILABLE_CURRENCIES,
  DEFAULT_CURRENCY,
} from '../../../../../../../utils/currency';

interface PropTypes {
  currency?: string;
  salary?: number;
  setCurrency: (currency: string) => void;
  setSalary: (salary: number) => void;
}

export function SalaryInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={'Salary'} isRequired={true} />
      <div className='d-flex flex-nowrap gap-3'>
        <Input
          type='number'
          className='fs-5 w-40'
          placeholder='(e.g. 50000)'
          defaultValue={props.salary}
          onChange={(event) => {
            props.setSalary(event.target.value as unknown as number);
          }}
        />
        <Select
          placeholder={'Select currency'}
          options={AVAILABLE_CURRENCIES}
          onChange={(option: SelectOption) => {
            props.setCurrency(option.value?.toUpperCase());
          }}
          selected={{
            label: props.currency || DEFAULT_CURRENCY.label,
            value: props.currency || DEFAULT_CURRENCY.value,
          }}
          isClearable={false}
          className={classNames(styles['currency-input'])}
        />
      </div>
    </>
  );
}
