import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { EmptyState } from '../../../components/EmptyState';
import { useParams } from 'react-router';
import { Alert, AlertObject } from '../../../components/Alert';
import { JobDetails } from '../../../entities/JobDetails';
import { User } from '../../../entities/User';
import { JobStageService } from '../../../services/JobStageService';
import { JobPipelineBoard } from './JobPipelineBoard';
import { JobService } from '../../../services/applicant_tracking/JobService';
import { PipelineApplication } from '../../../entities/applicant_tracking/PipelineApplication';
import { HeadingRow } from '../HeadingRow';
import { SortFilter } from './SortFilter';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { SortingColumn } from '../../../utils/sorting';
import {
  mapSortingColumnToQueryParam,
  mapSortingDirectionToQueryParam,
  parseSortingColumn,
} from './SortFilter/SortingColumn';
import {
  QueryParam,
  appendQueryParamToCurrentTab,
  removeQueryParamFromCurrentTab,
} from '../../../utils/url';

interface PropTypes {
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobDetails: JobDetails;
  jobPipelineTriggerIconEnabled: boolean;
}

export interface FilterState {
  sortingColumn: SortingColumn;
}

const AVAILABLE_QUERY_PARAMS = ['sorting_column', 'sorting_direction'];

function sortApplications(
  applications: PipelineApplication[],
  filterState: FilterState,
): PipelineApplication[] {
  const sortedApplications = applications.sort(
    (a: PipelineApplication, b: PipelineApplication) => {
      if (filterState.sortingColumn.columnName === 'name') {
        return a.candidate.name.localeCompare(b.candidate.name);
      } else if (filterState.sortingColumn.columnName === 'days_on_stage') {
        return a.daysInCurrentJobStage - b.daysInCurrentJobStage;
      } else if (filterState.sortingColumn.columnName === 'applied_at') {
        return (
          new Date(a.appliedAt).getTime() - new Date(b.appliedAt).getTime()
        );
      } else if (filterState.sortingColumn.columnName === 'pending_actions') {
        return a.totalPendingActions - b.totalPendingActions;
      }
    },
  );

  if (filterState.sortingColumn.direction === 'desc') {
    return sortedApplications.reverse();
  }

  return sortedApplications;
}

export function mapToQueryParams(filterStates: FilterState): QueryParam[] {
  return [
    mapSortingColumnToQueryParam(filterStates.sortingColumn.columnName),
    mapSortingDirectionToQueryParam(filterStates.sortingColumn.direction),
  ].filter((qp) => qp != null);
}

export function updateQueryParams(filterState: FilterState) {
  const mappedQueryParams = mapToQueryParams(filterState);

  mapToQueryParams(filterState)
    .filter((qp) => qp.value)
    .forEach((qp) => {
      appendQueryParamToCurrentTab(qp.key, qp.value as string);
    });

  AVAILABLE_QUERY_PARAMS.forEach((qp) => {
    if (!mappedQueryParams.find((p) => p.key === qp)?.value) {
      removeQueryParamFromCurrentTab(qp);
    }
  });
}

export function parseCurrentFilterState(): FilterState {
  return {
    sortingColumn: parseSortingColumn(),
  };
}

export function PipelineTab(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [applications, setApplications] = useState<PipelineApplication[]>(null);
  const [jobStages, setJobStages] = useState(null);
  const [filterState, setFilterState] = useState<FilterState>(
    parseCurrentFilterState(),
  );

  const jobId = useParams().id;

  const loadData = async () => {
    setIsLoading(true);

    await Promise.all([
      JobService.listApplications(jobId),
      JobStageService.list(props.jobDetails.id.toString()),
    ])
      .then(([pipelineApplications, jobStages]) => {
        setApplications(pipelineApplications.applications);
        setJobStages(jobStages);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
    return;
  }, []);

  useEffect(() => {
    updateQueryParams(filterState);
  }, [filterState]);

  if (!jobStages) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <HeadingRow date={props.jobDetails.updatedAt} tabName='Pipeline'>
        <SortFilter filterState={filterState} setFilterState={setFilterState} />
      </HeadingRow>
      {jobStages?.length > 0 ? (
        <>
          {isLoading && <LoadingSpinner showBackdrop />}
          {alert?.message && (
            <Alert
              type={alert.type}
              autoClearTimeout={4000}
              clearable={true}
              onClose={() => setAlert(null)}
            >
              {alert.message}
            </Alert>
          )}
          <JobPipelineBoard
            applications={sortApplications(applications, filterState)}
            currentUser={props.currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            jobDetails={props.jobDetails}
            jobStages={jobStages}
            reloadJobStages={loadData}
            setAlert={setAlert}
            jobPipelineTriggerIconEnabled={props.jobPipelineTriggerIconEnabled}
          />
        </>
      ) : (
        <EmptyState
          title='No job stages were found for this job'
          text='If it is not expected, please contact our team at support@screenloop.com'
          cardless={true}
        />
      )}
    </>
  );
}
