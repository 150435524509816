import {
  ApprovalAssignedTo,
  ApprovalFlow,
  ApprovalFlowStep,
  ListApprovalFlowsMetadata,
  RequisitionType,
} from '../../../entities/applicant_tracking/ApprovalFlow';
import { ApprovalFlowService } from '../../../services/v1/applicant_tracking/ApprovalFlowService';

function isApprovalStepSavable(approvalStep: ApprovalFlowStep) {
  if (approvalStep.approvers.length === 0) return false;
  if (approvalStep.approvers.some((a) => !a.user)) return false;

  return true;
}

export function isApprovalFlowSavable(
  name: string,
  assignedTo: string,
  organizationFlowExists: boolean,
  assignedLocationOrDepartmentIds: number[],
  approvalSteps: ApprovalFlowStep[],
) {
  if (!name) return false;
  if (assignedTo === 'organization' && organizationFlowExists) return false;
  if (
    assignedTo !== 'organization' &&
    assignedLocationOrDepartmentIds.length === 0
  )
    return false;
  if (approvalSteps.length === 0) return false;
  if (!approvalSteps.every(isApprovalStepSavable)) return false;

  return true;
}

export function loadExistingFlowMetadata(
  requisitionType: RequisitionType,
): Promise<ListApprovalFlowsMetadata> {
  return ApprovalFlowService.getExistingFlowsMetadata(requisitionType);
}

export function mapDataToAPIRequest(
  name: string,
  assignedTo: ApprovalAssignedTo,
  requisitionType: RequisitionType,
  assignedLocationOrDepartmentIds: number[],
  approvalSteps: ApprovalFlowStep[],
  id?: number,
) {
  return {
    id,
    name,
    assignedTo,
    requisitionType: requisitionType,
    approvalFlowSteps: approvalSteps.map((step) => ({
      id: step.id,
      order: step.order,
      user_ids: step.approvers.filter((a) => a.user).map((a) => a.user.id),
    })),
    assignedLocationOrDepartmentIds: assignedLocationOrDepartmentIds,
  };
}

export function loadApprovalFlow(id: number): Promise<ApprovalFlow> {
  return ApprovalFlowService.show({
    id: id,
    expand: [
      'steps',
      'steps.approvers',
      'steps.approvers.user',
      'locations',
      'departments',
      'locations.location',
      'departments.department',
    ],
  });
}

export function mapAssignedLocationOrDepartmentIds(approvalFlow: ApprovalFlow) {
  if (!approvalFlow) return [];

  const locationIds = approvalFlow.locations.map(
    (apfLocation) => apfLocation.location.id,
  );
  const departmentIds = approvalFlow.departments.map(
    (apfDepartment) => apfDepartment.department.id,
  );

  return [...locationIds, ...departmentIds];
}

export function disabledLocationIds(
  approvalFlow: ApprovalFlow,
  disabledLocationIds: number[] = [],
): number[] {
  if (!approvalFlow) return [];
  if (approvalFlow.assignedTo !== 'location') return disabledLocationIds;

  return disabledLocationIds.filter(
    (id) => !approvalFlow.locations.map((l) => l.location.id).includes(id),
  );
}

export function disabledDepartmentIds(
  approvalFlow: ApprovalFlow,
  disabledDepartmentIds: number[] = [],
): number[] {
  if (!approvalFlow) return [];
  if (approvalFlow.assignedTo !== 'department') return disabledDepartmentIds;

  return disabledDepartmentIds.filter(
    (id) => !approvalFlow.departments.map((d) => d.department.id).includes(id),
  );
}
