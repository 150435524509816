import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { JobTitleInput } from './JobTitleInput';
import { DepartmentInput } from './DepartmentInput';
import { JobOpeningsInput } from './JobOpeningsInput';
import {
  HiringTeamInput,
  loadHiringMemberOptions,
  loadJobHiringMemberRoleOptions,
} from './HiringTeamInput';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { SelectOption } from '../../../../../components/Select';
import { JobDetailsHeader } from './JobDetailsHeader';
import { JobAndJobPost, initCreateJobDto } from '..';
import { replaceFinalSegment } from '../../../../../utils/url';
import { getReferrer } from '../referrers';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { FormErrorMessage } from '../../../../../components/FormErrorMessage';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import { JobRequisitionInput } from './JobRequisitionInput';
import { JobRequisition } from '../../../../../entities/v1/applicant_tracking/JobRequisition';

interface PropTypes {
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
  setJobRequisition: (jr: JobRequisition) => void;
}

export function SetupJobDetailsPage(props: PropTypes) {
  const navigate = useNavigate();
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    setError,
    getValues,
    setValue,
    resetField,
  } = useForm<CreateJobDto>({
    mode: 'onChange',
    defaultValues: props.initJobDto,
  });

  useEffect(() => {
    reset(props.initJobDto);
  }, [props.initJobDto]);

  const submitData = async () => {
    try {
      const newstate = { ...state.setupJob, ...getValues() };
      const { job } = await props.onSubmit(newstate);

      actions.updateLocalStorage({ ...getValues(), jobId: job.id });

      return true;
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }

    return false;
  };

  const onSubmit = handleSubmit(async (data: CreateJobDto) => {
    const isSuccessful = await submitData();
    if (!isSuccessful) return;

    props.setSetupStep(props.setupStep + 1, data);
    navigate(replaceFinalSegment('job_post'));
  });

  const onNext = handleSubmit(() => {
    actions.updateLocalStorage({ ...getValues() });

    props.setSetupStep(props.setupStep + 1, state.setupJob);
    navigate(replaceFinalSegment('job_post'));
  });

  const onSave = handleSubmit(async () => {
    const isSuccessful = await submitData();
    if (!isSuccessful) return;

    window.location.href = getReferrer();
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [jobHiringMemberRoleOptions, setJobHiringMemberRoleOptions] = useState<
    SelectOption[]
  >([]);
  const [hiringMemberOptions, setHiringMemberOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    (async () => {
      setHiringMemberOptions(await loadHiringMemberOptions());
      setJobHiringMemberRoleOptions(await loadJobHiringMemberRoleOptions());

      setIsLoading(false);
    })();
  }, []);

  return (
    <DeprecatedPanel className={'px-4 pt-4 pb-5 mb-5'}>
      <form>
        <FormErrorMessage error={formState.errors} />
        <JobDetailsHeader
          formState={formState}
          onCancel={() => {
            actions.updateLocalStorage(initCreateJobDto);
            window.location.href = getReferrer();
          }}
          onSave={onSave}
          onNext={onNext}
          {...props}
          onSubmit={onSubmit}
        />

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <JobRequisitionInput
              isEditFlow={props.isEditFlow}
              formControl={control}
              formWatch={watch}
              formSetValue={setValue}
              setJobRequisition={props.setJobRequisition}
            />

            <JobTitleInput formRegister={register} />

            <DepartmentInput formControl={control} />

            <JobOpeningsInput
              initJobDto={props.initJobDto}
              getValues={getValues}
              isEditFlow={props.isEditFlow}
              formControl={control}
              resetField={resetField}
            />

            <HiringTeamInput
              formControl={control}
              formWatch={watch}
              jobHiringMemberRoleOptions={jobHiringMemberRoleOptions}
              hiringMemberOptions={hiringMemberOptions}
            />
          </>
        )}
      </form>
    </DeprecatedPanel>
  );
}
