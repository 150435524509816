import React, { useEffect, useMemo, useRef } from 'react';
import { JobStageTemplateInput } from '../../../../../../PlanTemplates/SetupPlanTemplatePage';
import { JobStagesList } from '../../../../../../../components/ApplicantTracking/JobStagesList';
import { HorizontalDivider } from '../../../../../../../components/HorizontallDivider';
import { mapJobStageToJobStageInput } from '../../../../../../../utils/applicant_tracking/planTemplate';
import { PlanTemplate } from '../../../../../../../entities/applicant_tracking/PlanTemplate';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  planTemplate: PlanTemplate;
  focusedJobStage: JobStageTemplateInput;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
}

export function PipelineDetail(props: PropTypes) {
  const ref = useRef(null);

  const jobStages = useMemo(
    () => mapJobStageToJobStageInput(props.planTemplate.jobStages),
    [props.planTemplate],
  );

  useEffect(() => {
    if (!props.focusedJobStage) return;

    ref?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [props.focusedJobStage]);

  return (
    <>
      <HorizontalDivider classNames='my-4 py-1' />
      <div className='fw-semibold fs-3 mb-2'>{props.planTemplate.name}</div>
      <div className='fs-6 text-dark-200'>
        Select a stage to view assigned questions and scorecards. To edit go to
        Admin Settings / Job Workflow
      </div>
      <div
        ref={ref}
        className={classNames('fw-semibold fs-3 mb-2', styles['scroll-anchor'])}
      >
        <JobStagesList
          viewMode
          hideScrollbar
          disableViewingNonInterviewStages
          onAddStage={null}
          setJobStages={null}
          focusedJobStage={props.focusedJobStage}
          jobStageHeaderClassName='my-3'
          setFocusedJobStage={props.setFocusedJobStage}
          jobStages={jobStages}
          planTemplate={props.planTemplate}
        />
      </div>
    </>
  );
}
