import React from 'react';
import { ApprovalFlowApprover } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { Avatar } from '../../../../../../components/Avatar';
import { TruncateText } from '../../../../../../components/TruncateText';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { ApproverAnswer } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { User } from '../../../../../../entities/v1/User';

interface PropTypes {
  approver: ApprovalFlowApprover;
  stepOrder: number;
  currentUser?: User;
  declinedStepOrder?: number;
  currentStepOrder?: number;
}

interface AvatarProps {
  name: string;
  type: 'photo' | 'initials' | 'icon';
  className?: string;
  avatarClassName?: string;
  disabled?: boolean;
}

function buildAvatarProps(
  approver: ApprovalFlowApprover,
  stepOrder: number,
  declinedStepOrder?: number,
): AvatarProps {
  if (approver.answer) {
    return {
      name:
        approver.answer === ApproverAnswer.Approved ? 'bi-check-lg' : 'bi-x-lg',
      type: 'icon',
      avatarClassName: classNames(
        approver.answer === ApproverAnswer.Approved
          ? ['text-success', styles['avatar-approved']]
          : ['text-danger', styles['avatar-declined']],
      ),
    };
  }

  const user = approver.user;

  return {
    name: user.photoUrl ?? user.name,
    type: user.photoUrl ? 'photo' : 'initials',
    disabled: declinedStepOrder != null && stepOrder >= declinedStepOrder,
  };
}

function fontColor(avatarProps: AvatarProps, props: PropTypes) {
  if (
    props.currentStepOrder === undefined ||
    props.stepOrder < props.currentStepOrder
  ) {
    return 'text-blue-gray-900';
  }

  if (avatarProps.disabled) {
    return 'text-gray-700';
  } else {
    return 'text-dark-500';
  }
}

export function Approver(props: PropTypes) {
  const avatarProps = buildAvatarProps(
    props.approver,
    props.stepOrder,
    props.declinedStepOrder,
  );
  const userName =
    props.approver.user.name +
    (props.currentUser?.id === props.approver.user.id ? ' (You)' : '');

  return (
    <div className='d-flex align-items-center'>
      <Avatar
        name={avatarProps.name}
        size='lg'
        type={avatarProps.type}
        className={avatarProps.className}
        avatarClassName={avatarProps.avatarClassName}
        disabled={avatarProps.disabled}
      />
      <div
        className={classNames(
          'fs-5 fw-semibold text-nowrap',
          fontColor(avatarProps, props),
        )}
      >
        <TruncateText ellipsize tooltip text={userName} maxChars={25} />
      </div>
    </div>
  );
}
