import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  allowedFormats: string[];
  hideAllowedFormats?: boolean;
  maxFileSizeMb: number;
  hasVirus?: boolean;
}

export function AttachmentInputFooter(props: PropTypes) {
  const hideFormat = props.hideAllowedFormats ?? false;

  return (
    <div
      className={classNames(
        'd-flex',
        'justify-content-between',
        'text-dark-200',
        'mt-2',
      )}
    >
      {!hideFormat && (
        <small>
          {'Allowed file formats: ' + props.allowedFormats.join(', ')}
        </small>
      )}
      {props.hasVirus && (
        <small className='text-danger-500'>
          File contains suspicious content
        </small>
      )}
      <small>{`Max file size: ${props.maxFileSizeMb}MB`}</small>
    </div>
  );
}
