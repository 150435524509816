import React, { useContext, useEffect, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../components/Select';
import {
  CreateJobDto,
  HiringMember,
} from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { JobRequisitionStatus } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionService } from '../../../../../../services/v1/applicant_tracking/JobRequisitionService';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionList } from '../../../../../../entities/v1/applicant_tracking/JobRequisitionList';
import { SetupJobContext } from '../../index';

interface PropTypes {
  isEditFlow: boolean;
  formControl: Control<CreateJobDto>;
  formWatch: UseFormWatch<CreateJobDto>;
  formSetValue: UseFormSetValue<CreateJobDto>;
  setJobRequisition: (jr: JobRequisition) => void;
}

interface JobRequisitionSelectProps {
  disabled: boolean;
  options: SelectOption[];
}

export async function loadJobRequisitions(): Promise<JobRequisitionList> {
  return await JobRequisitionService.list({
    status: JobRequisitionStatus.Approved,
    jobCreationEligible: true,
    expand: [],
  });
}

function buildJobRequisitionSelectOption(
  jobRequisition: JobRequisition,
): SelectOption {
  return {
    label: jobRequisition.uid,
    value: jobRequisition.id.toString(),
  };
}

function buildJobRequisitionSelectProps(
  updateJobRequisitionEnabled: boolean,
  jobRequisitions: JobRequisition[],
): JobRequisitionSelectProps {
  return {
    disabled: !updateJobRequisitionEnabled,
    options: jobRequisitions.map((requisition) =>
      buildJobRequisitionSelectOption(requisition),
    ),
  };
}

function updateJobForm(
  formWatch: UseFormWatch<CreateJobDto>,
  formSetValue: UseFormSetValue<CreateJobDto>,
  jobRequisition?: JobRequisition,
) {
  if (!jobRequisition) {
    return;
  }

  formSetValue('jobRequisitionId', jobRequisition.id.toString());
  formSetValue('jobOpenings', jobRequisition.openings?.toString());
  formSetValue('departmentId', jobRequisition.departmentId.toString());

  if (!formWatch('locationId')) {
    formSetValue('locationId', jobRequisition.locationId.toString());
  }

  if (!formWatch('locationType')) {
    formSetValue('locationType', jobRequisition.locationType);
  }

  if (!formWatch('employmentType')) {
    formSetValue('employmentType', jobRequisition.employmentType);
  }

  if (!formWatch('hiringTeamRecruiterId')) {
    formSetValue(
      'hiringTeamRecruiterId',
      jobRequisition.ownerUserId.toString(),
    );
  }

  if (formWatch('hiringTeamMembers')?.length == 0) {
    const hiringTeamMembers: HiringMember[] = [
      {
        userId: jobRequisition.hiringManagerUserId.toString(),
        roleId: 'hiring_manager',
      },
    ];
    formSetValue('hiringTeamMembers', hiringTeamMembers);
  }
}

function preSelectedJobRequisitionOption(jobRequisitionId: string) {
  return [
    {
      value: jobRequisitionId,
      label: `JREQ-${jobRequisitionId.toString().padStart(2, '0')}`,
    },
  ];
}

export function JobRequisitionInput(props: PropTypes) {
  const setupJobContext = useContext(SetupJobContext);

  if (!setupJobContext.jobRequisitionEnabled) {
    return null;
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobRequisitions, setJobRequisitions] = useState<JobRequisition[]>([]);

  useEffect(() => {
    (async () => {
      if (setupJobContext.updateJobRequisitionEnabled) {
        loadJobRequisitions()
          .then((list) => setJobRequisitions(list.jobRequisitions))
          .finally(() => setIsLoading(false));
      } else {
        setJobRequisitions([setupJobContext.jobRequisition].filter(Boolean));
      }

      setIsLoading(false);
    })();
  }, []);

  const jobRequisitionId = props.formWatch('jobRequisitionId');
  useEffect(() => {
    if (!jobRequisitionId) {
      return;
    }

    const selectedJobRequisition = jobRequisitions.find(
      (requisition) => requisition.id.toString() === jobRequisitionId,
    );

    if (!selectedJobRequisition) {
      return;
    }

    props.setJobRequisition(selectedJobRequisition);

    updateJobForm(props.formWatch, props.formSetValue, selectedJobRequisition);
  }, [jobRequisitionId]);

  const jobRequisitionSelectProps = buildJobRequisitionSelectProps(
    setupJobContext.updateJobRequisitionEnabled,
    jobRequisitions,
  );

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Requisition ID'}
          placeholder={'Select job requisition'}
          formHookContext={{
            controllerName: 'jobRequisitionId',
            formControl: props.formControl,
            required: setupJobContext.jobRequisitionMandatory,
          }}
          options={
            props.isEditFlow
              ? preSelectedJobRequisitionOption(jobRequisitionId)
              : jobRequisitionSelectProps.options
          }
          disabled={jobRequisitionSelectProps.disabled}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
