import React, { useEffect, useState } from 'react';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { TableRowLayout } from './TableRowLayout';
import { Name } from './Name';
import { Jobs } from './Jobs';
import { shortFormatDate } from '../../../../../../../utils/timeFormat';
import { Stages } from './Stages';
import { PendingActions } from './PendingActions';
import { Dropdown } from '../../../../../../../components/Dropdown';
import {
  appendRedirectUri,
  removeTrailingSlashes,
} from '../../../../../../../utils/url';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { ArchiveCandidate } from './ArchiveCandidate';
import classNames from 'classnames';
import { Channel } from './Channel';
import { CheckboxButton } from '../../../../../../../components/CheckboxButton';
import { DeleteCandidate } from './DeleteCandidate';
import { User } from '../../../../../../../entities/User';
import { HrisEmployee } from '../../../../../../../entities/applicant_tracking/HrisEmployees';
import { PushToHrisModal } from '../../../../../../CandidatePage/CandidateActions/PushToHrisModal';
import { HrisProvider } from '../../../../../../../enums/IntegrationProvider/HrisProvider';
import { renderPushToHris } from '../../../../../../CandidatePage/CandidateActions/DropdownActions';
import { AlertObject } from '../../../../../../../components/Alert';
import { SendOfferModal } from '../../../../../Offers/SendOfferModal';
import { EmailAccountIntegration } from '../../../../../../../entities/EmailAccountIntegration';

interface PropTypes {
  candidates: ListCandidate[];
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  selectedCandidates: ListCandidate[];
  setSelectedCandidates: (candidates: ListCandidate[]) => void;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

interface RowActionPropTypes {
  currentUser: User;
  candidate: ListCandidate;
  hrisEmployee?: HrisEmployee;
  hrisProvider?: HrisProvider;
  onClickArchive: () => void;
  onClickDelete: () => void;
  onClickPushToHris: () => void;
  onClickSendOffer: () => void;
}

interface CandidateRowPropTypes {
  candidate: ListCandidate;
  hrisEmployee?: HrisEmployee;
  hrisProvider?: HrisProvider;
  currentUser: User;
  checked: boolean;
  selectedCandidates: ListCandidate[];
  setSelectedCandidates: (candidates: ListCandidate[]) => void;
  setCandidateForAction: (candidate: ListCandidate) => void;
  setArchiveModalActive: (active: boolean) => void;
  setDeleteModalActive: (active: boolean) => void;
  setPushToHrisModalActive: (active: boolean) => void;
  setSendOfferModalActive: (active: boolean) => void;
}

function menuAction(
  action: () => void,
  text: string,
  iconName: string,
  disabled = false,
) {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={text}
        icon={{ name: iconName, className: 'fs-4' }}
        className={classNames(disabled ? 'text-muted' : 'text-blue')}
      />
    ),
    isDisabled: disabled,
  };
}

function getCandidateUrl(candidate: ListCandidate) {
  if (candidate.application == null) return '#';

  return appendRedirectUri(
    `${removeTrailingSlashes(window.location.origin)}/applications/${
      candidate.application.id
    }`,
  );
}

function editCandidateProfile(candidate: ListCandidate) {
  if (!candidate.editCandidatePermitted || candidate.redacted) return null;

  const url = appendRedirectUri(
    `/applicant_tracking/candidates/${candidate.id}/edit`,
  );

  return menuAction(
    () => (window.location.href = url.toString()),
    'Edit Candidate Profile',
    'bi-person',
  );
}

function addJobApplicationAction(candidate: ListCandidate) {
  if (!candidate.newApplicationPermitted || candidate.redacted) return null;

  const url = new URL(
    `/applicant_tracking/applications/new`,
    window.location.origin,
  );
  url.searchParams.set('candidate_id', candidate.id.toString());

  return menuAction(
    () => (window.location.href = appendRedirectUri(url.toString())),
    'Add Job Application',
    'bi-plus-circle',
  );
}

function editJobApplicationAction(candidate: ListCandidate) {
  if (
    !candidate.application ||
    !candidate.application.editApplicationPermitted ||
    candidate.redacted
  )
    return null;

  const url = `/applicant_tracking/applications/${candidate.application.id}/edit`;

  return menuAction(
    () => (window.location.href = appendRedirectUri(url.toString())),
    'Edit Job Application',
    'bi-pencil',
  );
}

function deleteApplicationAction(
  candidate: ListCandidate,
  currentUser: User,
  onClickDelete: () => void,
) {
  if (candidate.status === 'active' || !currentUser.isAdmin) return null;
  if (candidate.application != null) return null;

  return menuAction(() => onClickDelete(), 'Delete', 'bi-trash');
}

function sendOfferAction(onClickSendOffer: () => void, enabled?: boolean) {
  if (!enabled) return null;

  return menuAction(
    () => onClickSendOffer(),
    'Send Offer',
    'bi-file-earmark-medical',
  );
}

function RowActions(props: RowActionPropTypes) {
  const archiveAction =
    props.candidate.status == 'archived' ? 'Unarchive' : 'Archive';

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-5 text-blue',
      }}
      menuActions={[
        menuAction(
          () => (window.location.href = getCandidateUrl(props.candidate)),
          'View',
          'bi-eye',
          props.candidate.application == null,
        ),
        editCandidateProfile(props.candidate),
        addJobApplicationAction(props.candidate),
        editJobApplicationAction(props.candidate),
        props.candidate.archivable &&
          menuAction(
            () => props.onClickArchive(),
            archiveAction,
            'bi-archive',
            !props.candidate.archivable,
          ),
        renderPushToHris(
          () => props.onClickPushToHris(),
          props.candidate.redacted,
          props.candidate?.application?.status,
          props.hrisEmployee?.externalUrl,
          props.candidate.pushCandidateToHrisPermitted
            ? props.hrisProvider
            : null,
        ),
        deleteApplicationAction(
          props.candidate,
          props.currentUser,
          props.onClickDelete,
        ),
        sendOfferAction(
          props.onClickSendOffer,
          props.candidate.application?.sendOfferPermitted &&
            !props.candidate.redacted,
        ),
      ].filter(Boolean)}
    />
  );
}

function getUpdatedAtString(
  applicationUpdatedAt?: Date,
  candidateUpdatedAt?: Date,
): string {
  if (applicationUpdatedAt == null && candidateUpdatedAt == null) {
    return 'N/A';
  }

  if (!applicationUpdatedAt || candidateUpdatedAt > applicationUpdatedAt) {
    return shortFormatDate(candidateUpdatedAt);
  }

  return shortFormatDate(applicationUpdatedAt);
}

function CandidateRow(props: CandidateRowPropTypes) {
  const [checked, setChecked] = useState(props.checked);
  const bulkSelectionEnabled =
    props.candidate.status !== 'draft' &&
    props.candidate.application?.bulkActionsPermitted;

  useEffect(() => setChecked(props.checked), [props.checked]);
  useEffect(() => {
    if (checked) {
      const combinedList = [
        ...props.selectedCandidates,
        props.candidate,
      ].filter(
        (candidate, index, self) =>
          index === self.findIndex((c) => c.id === candidate.id),
      );
      props.setSelectedCandidates(combinedList);
    } else {
      props.setSelectedCandidates(
        props.selectedCandidates.filter((c) => c.id != props.candidate.id),
      );
    }
  }, [checked]);

  return (
    <TableRowLayout classNames={checked && 'bg-light-info'}>
      <CheckboxButton
        checked={checked}
        onChange={() => setChecked(!checked)}
        name={null}
        disabled={!bulkSelectionEnabled}
      />
      <Name
        candidate={props.candidate}
        hrisEmployee={props.hrisEmployee}
        application={props.candidate.application}
      />
      <Jobs candidate={props.candidate} />
      <span>
        {getUpdatedAtString(
          props.candidate.application?.updatedAt,
          props.candidate.updatedAt,
        )}
      </span>
      <Channel application={props.candidate.application} />
      <Stages application={props.candidate.application} />
      <PendingActions application={props.candidate.application} />
      <RowActions
        candidate={props.candidate}
        hrisEmployee={props.hrisEmployee}
        hrisProvider={props.hrisProvider}
        currentUser={props.currentUser}
        onClickArchive={() => {
          props.setCandidateForAction(props.candidate);
          props.setArchiveModalActive(true);
        }}
        onClickDelete={() => {
          props.setCandidateForAction(props.candidate);
          props.setDeleteModalActive(true);
        }}
        onClickPushToHris={() => {
          props.setCandidateForAction(props.candidate);
          props.setPushToHrisModalActive(true);
        }}
        onClickSendOffer={() => {
          props.setCandidateForAction(props.candidate);
          props.setSendOfferModalActive(true);
        }}
      />
    </TableRowLayout>
  );
}

export function TableBody(props: PropTypes) {
  const [archiveModalActive, setArchiveModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [sendOfferModalActive, setSendOfferModalActive] = useState(false);
  const [candidateForAction, setCandidateForAction] =
    useState<ListCandidate>(null);
  const [pushToHrisModalActive, setPushToHrisModalActive] =
    useState<boolean>(false);

  const handlePushToHrisModalClose = () => {
    setPushToHrisModalActive(false);
    props.reloadPage();
  };

  const handleSendOfferClose = () => {
    setSendOfferModalActive(false);
    props.reloadPage();
  };

  return (
    <>
      <ArchiveCandidate
        archiveModalActive={archiveModalActive}
        candidate={candidateForAction}
        onClose={() => setArchiveModalActive(false)}
      />
      <DeleteCandidate
        deleteModalActive={deleteModalActive}
        candidate={candidateForAction}
        onClose={() => setDeleteModalActive(false)}
      />
      <PushToHrisModal
        applicationIds={[candidateForAction?.application?.id]}
        allowedHrisProvider={props.hrisProvider}
        isOpen={pushToHrisModalActive}
        onClose={handlePushToHrisModalClose}
        setAlert={props.setAlert}
        candidateName={candidateForAction?.displayName}
        jobName={candidateForAction?.application?.job?.name}
      />
      <SendOfferModal
        applicationId={candidateForAction?.application?.id}
        to={candidateForAction?.email}
        isOpen={sendOfferModalActive}
        onClose={handleSendOfferClose}
        setAlert={props.setAlert}
        hasEmailConnected={props.currentUser.hasEmailConnected || false}
        authorizeUrl={props.emailAccountIntegration.authorizeUrl}
      />
      <tbody>
        {props.candidates.map((candidate: ListCandidate, idx: number) => {
          return (
            <CandidateRow
              key={idx}
              candidate={candidate}
              hrisEmployee={props.hrisEmployees.find(
                (e) => e.applicationId === candidate.application?.id,
              )}
              hrisProvider={props.hrisProvider}
              currentUser={props.currentUser}
              checked={
                !!props.selectedCandidates.find((c) => c.id == candidate.id)
              }
              selectedCandidates={props.selectedCandidates}
              setArchiveModalActive={setArchiveModalActive}
              setCandidateForAction={setCandidateForAction}
              setDeleteModalActive={setDeleteModalActive}
              setSelectedCandidates={props.setSelectedCandidates}
              setPushToHrisModalActive={setPushToHrisModalActive}
              setSendOfferModalActive={setSendOfferModalActive}
            />
          );
        })}
      </tbody>
    </>
  );
}
