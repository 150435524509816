import { ProductPriceCurrency } from '@vonq/hapi-elements-types';
import { Currency } from '../../../../entities/Currency';

export function mapCurrency(currency?: Currency): ProductPriceCurrency {
  if (currency == null) {
    return ProductPriceCurrency.GBP;
  }

  switch (currency) {
    case 'eur':
      return ProductPriceCurrency.EUR;
    case 'usd':
      return ProductPriceCurrency.USD;
    default:
      return ProductPriceCurrency.GBP;
  }
}
