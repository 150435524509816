import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { SelectOption } from '../../../../components/Select';
import { TopChoiceTablePanel } from '../../shared_panels/TablePanel/TopChoiceTablePanel';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../PageBreak';

export function DecisionAndImprovementTopChoices(props: {
  decision: QuestionTopChoices;
  improvements: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  if (props.decision == null) return null;
  if (props.decision.top_choices.length === 0) return null;

  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        <Col sm='12' lg='6'>
          <TopChoiceTablePanel
            title='Decision'
            subtitle={props.decision.question_text}
            tableClassName={'table-borderless'}
            entries={props.decision.top_choices.map((tc) => ({
              text: tc.name,
              average: parseFloat(tc.weight),
              benchmarkAverage: parseFloat(tc.benchmark_weight),
            }))}
            benchmarkType={props.benchmarkType}
            multiplier={100}
            displayPercentage={true}
            fillColor={stylesheetExports.pink}
          />
        </Col>
        <Col sm='12' lg='6'>
          <TopChoiceTablePanel
            title='Improvements'
            subtitle={props.improvements.question_text}
            tableClassName={'table-borderless'}
            entries={props.improvements.top_choices.map((tc) => ({
              text: tc.name,
              average: parseFloat(tc.weight),
              benchmarkAverage: parseFloat(tc.benchmark_weight),
            }))}
            benchmarkType={props.benchmarkType}
            multiplier={100}
            displayPercentage={true}
            fillColor={stylesheetExports.blue500}
          />
        </Col>
      </Row>
    </>
  );
}
