import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { sizeSmallerThan, useScreenSize } from '../../utils/useScreenSize';

interface PropTypes {
  name: string;
  value: boolean;
  onChange: (v: boolean) => void;
  trueLabel: string;
  falseLabel: string;
  disabled?: boolean;
  className?: string;
}

export function BooleanInput(props: PropTypes) {
  const trueId = `${props.name}-true`;
  const falseId = `${props.name}-false`;
  const size = sizeSmallerThan(useScreenSize(), 'sm') && 'lg';

  return (
    <div className={classNames('d-flex', props.className)}>
      <div className='form-check d-flex align-items-center'>
        <input
          className={classNames('form-check-input me-2 my-0', styles[size])}
          type='radio'
          id={trueId}
          value='true'
          name={props.name}
          onChange={() => props.onChange(true)}
          checked={props.value === true}
          disabled={props.disabled}
        />
        <label className='form-check-label me-4 me-sm-3' htmlFor={trueId}>
          {props.trueLabel}
        </label>
      </div>
      <div className='form-check d-flex align-items-center'>
        <input
          className={classNames('form-check-input me-2 my-0', styles[size])}
          type='radio'
          id={falseId}
          value='false'
          name={props.name}
          onChange={() => props.onChange(false)}
          checked={props.value === false}
          disabled={props.disabled}
        />
        <label className='form-check-label' htmlFor={falseId}>
          {props.falseLabel}
        </label>
      </div>
    </div>
  );
}
