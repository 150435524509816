import React from 'react';
import classNames from 'classnames';
import { Col } from 'reactstrap';
import styles from './styles.module.scss';
import { ContentCircle } from '../../ContentCircle';

interface PropTypes {
  isFirstItem: boolean;
  isLastItem: boolean;
  isActive: boolean;
  step: number;
  title: string;
  subtitle: string;
  slug?: string;
  displayInModal?: boolean;
}

export function StepperItem(props: PropTypes) {
  const itemSeparatorClassName = 'border-start border-blue-gray-200';
  const activeItemStepClassName = 'bg-info-500 text-white';
  const deactiveItemStepClassName = 'bg-light-info text-info';

  return (
    <Col
      className={classNames(
        'p-0',
        !props.isFirstItem ? itemSeparatorClassName : null,
      )}
    >
      <div
        className={classNames(
          props.displayInModal ? 'ps-4' : 'ps-3',
          'py-3',
          'd-flex',
          'align-items-center',
          { 'bg-blue-gray-200': props.isActive },
          !props.displayInModal && props.isFirstItem && styles['first-item'],
          !props.displayInModal && props.isLastItem && styles['last-item'],
          styles['hover-blue'],
        )}
      >
        <ContentCircle
          content={props.step}
          className={classNames(
            props.isActive
              ? activeItemStepClassName
              : deactiveItemStepClassName,
            styles['hover-blue-500'],
          )}
          size={'lg'}
        />
        <div className='ms-3'>
          <h4 className='m-0'>{props.title}</h4>
          <small className={classNames('text-muted', styles['hover-blue'])}>
            {props.subtitle}
          </small>
        </div>
      </div>
    </Col>
  );
}
