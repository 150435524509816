import { Countable, Paginatable } from '../entities/Pagination';

export function mapResultToSelectOption<
  FilterResult extends Paginatable & Countable,
>(
  result: Promise<FilterResult>,
  optGetter: (filter: FilterResult) => { id?: string | number; name: string }[],
  nameAsValue = false,
) {
  return result.then((filter) => ({
    ...filter,
    options: optGetter(filter).map((elem) => ({
      value: !nameAsValue ? elem.id?.toString() : elem.name,
      label: elem.name,
    })),
  }));
}
