import stylesheetExports from '../stylesheets/export.module.scss';
import { WindowWithHapi } from '../containers/JobPage/JobBoardsTab';
import { mapCurrency } from '../containers/JobPage/JobBoardsTab/utils/currency';
import { Currency } from '../entities/Currency';

export function windowHapi() {
  const isBrowser = typeof window !== 'undefined'; // SSR check
  const windowWithHapi = isBrowser
    ? (window as unknown as WindowWithHapi)
    : undefined;

  return windowWithHapi;
}

function applyThemeChanges(window: WindowWithHapi) {
  window.hapi.theming.state.theme = {
    global: {
      borderRadius: stylesheetExports.borderRadius1,
      primaryBackgroundColor: stylesheetExports.white,
      primaryTextColor: stylesheetExports.darkPrimary,
      infoBackgroundColor: stylesheetExports.white,
      infoTextColor: stylesheetExports.blue,
    },
  };

  window.hapi.theming.state.fontOptions = {
    fontFaces: [
      {
        fontFamily: 'Nunito Sans',
        src: `url('https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-tQKr51.woff2')`,
        fontWeight: '400',
        fontSize: 'normal',
      },
      {
        fontFamily: 'Nunito Sans',
        src: `url('https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-tQKr51.woff2')`,
        fontWeight: '600',
        fontSize: 'normal',
      },
      {
        fontFamily: 'Nunito Sans',
        src: `url('https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-tQKr51.woff2')`,
        fontWeight: '700',
        fontSize: 'normal',
      },
    ],
    fontFamily: 'Nunito Sans',
  };

  // Remove the debuggin panel if it exists.
  document.querySelector('#hapi__debug-panel-toggle-button')?.remove();

  window.hapi.basket.service.removeProductOrContractById.onSuccess(() => {
    window.hapi.alert.service.hide(
      window.hapi.alert.utils.alertKeys.productRemoveFromBasket,
    );
  });
}

export async function initialize(token: string, currency?: Currency) {
  const window = windowHapi();

  if (window == null) {
    throw new Error('Could not get the window hapi.');
  }

  try {
    window.hapiInjector.setConfig('clientToken', token);
    window.hapiInjector.setConfig('walletCurrency', mapCurrency(currency));

    if (window.hapiConfig != null) {
      await window.hapiInjector.eject();
    }

    // Inject is a Promise that resolves when HAPI Elements script has successfully loaded
    await window.hapiInjector.inject();

    applyThemeChanges(window);
  } catch (error) {
    // If the elements injector detects a script with id 'vonq-hapi-elements-loader', this will raise an error.
    // We ignore the error since this means that the script was already loaded before.
    console.log(error);
  }
}
