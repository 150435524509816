import classNames from 'classnames';
import React from 'react';
import { IconSpan } from '../../../../../components/IconSpan';
import { Size } from '../../../../../utils/types/Size';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';

interface PropTypes {
  color: string;
  size: Size;
  previewMode?: boolean;
  hidden?: boolean;
  className?: string;
  text?: string;
  onClick: () => void;
}

export function BackToCareersPageButton(props: PropTypes) {
  const previewMode = props.previewMode ?? false;

  return (
    <Button
      color={props.color}
      size={props.size}
      className={classNames(props.className, {
        invisible: props.hidden,
        'py-0': props.hidden,
        [styles['invisible-button']]: props.hidden,
      })}
      type={'button'}
      disabled={previewMode}
      onClick={props.onClick}
    >
      <IconSpan
        spanText={props.text ?? 'Back to Careers Page'}
        icon={{ name: 'bi-arrow-left' }}
        position={'start'}
      />
    </Button>
  );
}
