import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Controller, Path } from 'react-hook-form';
import { ControllerContext } from '../../utils/reactHookForms';
import { Placement } from '../../utils/types/Placement';
import { Size } from '../../utils/types/Size';
import { ToggleButton } from '../ToggleButton';
import styles from './styles.module.scss';
import { Tooltip } from 'reactstrap';

export interface UncontrolledPropTypes {
  checkedLabel?: string;
  uncheckedLabel?: string;
  labelPlacement?: Placement;
  className?: string;
  mandatory?: boolean;
  inputName: string;
  checked?: boolean;
  size?: Size;
  color?: string;
  labelClassnames?: string;
  disabled?: boolean;
  tooltip?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export interface PropTypes extends UncontrolledPropTypes {
  formHookContext?: ControllerContext<any>;
}

function parseLabel(
  checked?: boolean,
  checkedLabel?: string,
  uncheckedLabel?: string,
) {
  const clabel = checkedLabel ?? 'Enabled';
  const ulabel = uncheckedLabel ?? 'Disabled';

  return checked ? clabel : ulabel;
}

function ToggleLabel(props: PropTypes) {
  const label = parseLabel(
    props.checked,
    props.checkedLabel,
    props.uncheckedLabel,
  );
  const labelColour = props.disabled && 'text-muted';

  return (
    <div
      className={classNames(
        props.labelPlacement == 'end' ? 'ms-2' : 'me-2',
        props.size == 'sm' && 'fs-5',
        labelColour,
        props.labelClassnames,
      )}
    >
      {label}
    </div>
  );
}

function UncontrolledToggle(props: UncontrolledPropTypes) {
  const labelPlacement = props.labelPlacement ?? 'start';

  return (
    <div
      className={classNames(
        props.className,
        'd-flex flex-nowrap align-items-center',
      )}
    >
      {labelPlacement == 'start' && <ToggleLabel {...props} />}
      <ToggleButton
        name={props.inputName}
        checked={props.checked}
        color={props.color}
        size={props.size}
        onChange={props.onChange}
        disabled={props.disabled}
        classNames={styles['toggle-button']}
      />
      {labelPlacement == 'end' && <ToggleLabel {...props} />}
    </div>
  );
}

function ToggleWrapper(props: PropTypes) {
  return props.formHookContext != null ? (
    <Controller
      control={props.formHookContext.formControl}
      name={props.formHookContext.controllerName as Path<any>}
      rules={{ required: props.formHookContext.required }}
      render={({ field }) => (
        <UncontrolledToggle
          className={props.className}
          checkedLabel={props.checkedLabel}
          uncheckedLabel={props.uncheckedLabel}
          labelPlacement={props.labelPlacement}
          mandatory={props.mandatory}
          inputName={props.inputName}
          checked={field.value ?? props.checked ?? null}
          size={props.size}
          color={props.color}
          labelClassnames={props.labelClassnames}
          disabled={props.disabled}
          onChange={field.onChange}
        />
      )}
    />
  ) : (
    <UncontrolledToggle
      checkedLabel={props.checkedLabel}
      className={props.className}
      uncheckedLabel={props.uncheckedLabel}
      labelPlacement={props.labelPlacement}
      mandatory={props.mandatory}
      inputName={props.inputName}
      checked={props.checked ?? null}
      size={props.size}
      color={props.color}
      labelClassnames={props.labelClassnames}
      disabled={props.disabled}
      onChange={props.onChange}
    />
  );
}

export function LabelledToggle(props: PropTypes) {
  const ref = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <>
      <div ref={ref}>
        <ToggleWrapper {...props} />
      </div>
      {props.tooltip && (
        <Tooltip
          target={ref}
          placement='top'
          isOpen={isTooltipOpen}
          toggle={() => setIsTooltipOpen(!isTooltipOpen)}
          trigger='hover'
          tooltipStyle={{ maxWidth: '280px' }}
        >
          {props.tooltip}
        </Tooltip>
      )}
    </>
  );
}
