import React, { ReactElement } from 'react';
import {
  ApplicationFeed,
  ApplicationFeedType,
  Simple,
  History,
  Note,
  Interview,
  ScorecardFill,
  PulseSurveyActions,
  ApplicationFeedEmail,
  TriggerActions,
  OfferFeed,
} from '../../../entities/ApplicationFeed';
import { Icon } from '../../../components/Icon';
import moment from 'moment';
import classNames from 'classnames';
import { Application } from '../../../entities/Application';
import { NoteText } from '../../../components/NoteText';
import { ScorecardFillEntry } from './ScorecardFillEntry';
import { DetailsEntry } from './DetailsEntry';
import { User } from '../../../entities/User';
import { CommentSection } from './CommentSection';
import { PulseSurveyCompletedEntry } from './CandidateExperience/PulseSurveyCompletedEntry';
import { VerticalDivider } from '../../../components/VerticalDivider';
import { RcSurveySharedEntry } from './ReferenceCheck/RcSurveySharedEntry';
import { RcSurveySentEntry } from './ReferenceCheck/RcSurveySentEntry';
import { RcSurveyCompletedEntry } from './ReferenceCheck/RcSurveyCompletedEntry';
import { RcSelfAssessmentCompletedEntry } from './ReferenceCheck/RcSelfAssessmentCompletedEntry';
import { PulseSurveySentEntry } from './CandidateExperience/PulseSurveySentEntry';
import { PulseSurveyShareEntry } from './CandidateExperience/PulseSurveyShareEntry';
import { BackgroundCheckInitiatedEntry } from './BackgroundCheck/BackgroundCheckInitiatedEntry';
import { BackgroundCheckCompletedEntry } from './BackgroundCheck/BackgroundCheckCompletedEntry';
import { EmailSentEntry } from './Email/EmailSentEntry';
import { EmailReceivedEntry } from './Email/EmailReceivedEntry';
import { UnrejectionEntry } from './UnrejectionEntry';
import { ActivityButton } from './ActivityButton';
import { appendRedirectUri } from '../../../utils/url';
import { TriggerEmailSent } from './Email/TriggerEmailSent';
import { AutoRejectEntry } from './AutoRejectEntry';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { NoteActionDropdown } from './NoteActionDropdown';
import { AuthorName } from './AuthorName';
import { AlertObject } from '../../../components/Alert';
import { getHrisName } from '../../../utils/hris';
import { Offer } from './Offer';
import { OfferAccepted } from './Offer/OfferAccepted';
import { OfferRejected } from './Offer/OfferRejected';
import { OfferRevoked } from './Offer/OfferRevoked';
import { ATS_MAX_NOTE_LENGTH } from '../../../components/ApplicantTracking/NoteModal/ModalBody';

interface PropTypes {
  application: Application;
  applicationFeeds: ApplicationFeed[];
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  redirectUri?: string;
  setAlert: (data: AlertObject) => void;
  reloadApplication?: () => void;
}

interface EntryPropTypes {
  application: Application;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  isLastItemInPage: boolean;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}

export function GenericEntry(props: {
  application: Application;
  currentUser: User;
  date: Date;
  entry: ApplicationFeed;
  icon: string;
  iconClassName: string;
  isLastItemInPage: boolean;
  title: ReactElement;
  actions?: ReactElement;
  children?: ReactElement;
  emailAccountIntegration?: AccountIntegration;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}) {
  return (
    <div className='mb-2'>
      <div className='d-flex w-100'>
        <div className='d-flex flex-column'>
          <div
            style={{ width: '48px', height: '48px' }}
            className={classNames(
              'd-flex align-items-center justify-content-center rounded-circle flex-shrink-0 me-3',
              props.iconClassName,
            )}
          >
            <Icon name={props.icon} className='fs-2 text-dark-200' />
          </div>
          {!props.isLastItemInPage && (
            <div className='d-flex justify-content-center me-3 mt-2 flex-grow-1'>
              <VerticalDivider />
            </div>
          )}
        </div>
        <div className='w-100 mb-3'>
          <div className='d-flex align-items-center' style={{ height: '48px' }}>
            <div className='me-auto'>{props.title}</div>
            <div className='text-muted text-nowrap ms-3'>
              {moment(props.date).format('MMMM DD, YYYY, hh:mm A')}
            </div>
            {props.actions}
          </div>
          {props.children}
          <CommentSection
            application={props.application}
            currentUser={props.currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            entry={props.entry}
            reloadApplication={props.reloadApplication}
            setAlert={props.setAlert}
          />
        </div>
      </div>
    </div>
  );
}

function NoteEntry(props: EntryPropTypes & { entry: Note }) {
  const noteColor = props.entry.deletedAt ? 'text-dark-200' : 'text-dark-500';
  const noteText = props.entry.deletedAt
    ? 'This note was deleted.'
    : props.entry.note;
  return (
    <GenericEntry
      icon='bi-file-earmark-text'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          {props.entry.authorName ? (
            <span>
              <AuthorName name={props.entry.authorName} /> added a note.
            </span>
          ) : (
            <span>A note has been added.</span>
          )}
          {!props.entry.deletedAt && props.entry.editedAt && (
            <span className='text-dark-200'> (Edited)</span>
          )}
        </>
      }
      actions={
        <NoteActionDropdown
          entry={props.entry}
          currentUser={props.currentUser}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
        />
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div className='rounded rounded-4 bg-gray py-3 px-4'>
        <NoteText
          text={noteText}
          className={noteColor}
          mentionClassName='text-info'
          maxTextLength={ATS_MAX_NOTE_LENGTH}
          mentionTransformer={(mention) => `@${mention}`}
        />
      </div>
    </GenericEntry>
  );
}

function AppliedEntry(props: EntryPropTypes & { entry: Simple }) {
  return (
    <GenericEntry
      icon='bi-briefcase'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          {props.entry.authorName ? (
            <>
              <AuthorName name={props.entry.authorName} /> added{' '}
              <strong>{props.application.candidate.name}</strong>
            </>
          ) : (
            <>
              <strong>{props.application.candidate.name}</strong> applied
            </>
          )}{' '}
          to <strong>{props.entry.jobName || 'other'}</strong> job.
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function StageMoveEntry(props: EntryPropTypes & { entry: History }) {
  return (
    <GenericEntry
      icon='bi-arrow-return-right'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> moved{' '}
          <strong>{props.application.candidate.name}</strong>{' '}
          {props.entry.applicationHistory.fromStage ? (
            <>
              from{' '}
              <strong>{props.entry.applicationHistory.fromStage.name}</strong>{' '}
              to <strong>{props.entry.applicationHistory.toStage.name}.</strong>
            </>
          ) : (
            <>
              to the{' '}
              <strong>{props.entry.applicationHistory.toStage.name}</strong>{' '}
              stage.
            </>
          )}
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function ProfileUpdateEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Simple;
  },
) {
  return (
    <GenericEntry
      icon='bi-person'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> updated{' '}
          <strong>{props.application.candidate.name}</strong>&apos;s profile.
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function RejectionEntry(props: EntryPropTypes & { entry: History }) {
  return (
    <GenericEntry
      icon='bi-file-x'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> rejected{' '}
          <strong>{props.application.candidate.name}</strong> with the reason{' '}
          <strong>
            {props.entry.applicationHistory.rejectReason?.name ?? 'Unknown'}.
          </strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function PushedToHrisEntry(
  props: EntryPropTypes & {
    entry: ApplicationFeed;
  },
) {
  if (!props.application.hrisEmployee) return null;

  const hrisName = getHrisName(props.application.hrisEmployee.provider);

  return (
    <GenericEntry
      icon='bi-journal-plus'
      iconClassName='bg-light-info'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> created{' '}
          <strong>{props.application.candidate.name}</strong> as an employee in{' '}
          <strong>{hrisName}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div>
        {props.application.hrisEmployee.externalUrl && (
          <ActivityButton
            url={props.application.hrisEmployee.externalUrl}
            text={`View in ${hrisName}`}
            icon='bi-box-arrow-up-right'
            openInNewTab={true}
          />
        )}
      </div>
    </GenericEntry>
  );
}

function InterviewScheduleEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
    redirectUri?: string;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar4-week'
      iconClassName='bg-light-info'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> scheduled an interview
          with <strong>{props.application.candidate.name}</strong> for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div>
        {props.entry.interview && (
          <ActivityButton
            url={appendRedirectUri(
              `/applicant_tracking/applications/${props.entry.applicationId}/interview_stages/${props.entry.interview.interviewStage.id}?interview_id=${props.entry.interview.id}`,
              props.redirectUri,
            )}
            text='View Interview Kit'
            icon='bi-credit-card-2-front'
          />
        )}
      </div>
    </GenericEntry>
  );
}

function InterviewScheduleByCandidateEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar4-week'
      iconClassName='bg-light-info'
      title={
        <>
          <strong>{props.application.candidate.name}</strong> scheduled an
          interview for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div>
        {props.entry.interview && (
          <ActivityButton
            url={`/applicant_tracking/applications/${props.entry.applicationId}/interview_stages/${props.entry.interview.interviewStage.id}?interview=${props.entry.interview.id}`}
            text='View Interview Kit'
            icon='bi-credit-card-2-front'
          />
        )}
      </div>
    </GenericEntry>
  );
}

function InterviewRescheduleEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar4-week'
      iconClassName='bg-light-info'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> rescheduled an interview
          with <strong>{props.application.candidate.name}</strong> for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function InterviewCancelEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar2-x'
      iconClassName='bg-light-info'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> cancelled the interview
          with <strong>{props.application.candidate.name}</strong> for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function InterviewCompleteEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
    redirectUri?: string;
  },
) {
  return (
    <GenericEntry
      icon='bi-file-earmark-play'
      iconClassName='bg-light-info'
      title={
        <>
          The interview with <strong>{props.application.candidate.name}</strong>{' '}
          for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}</strong> is
          completed and available.
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div>
        {props.entry.interview?.id && (
          <ActivityButton
            url={appendRedirectUri(
              `/applicant_tracking/interviews/${props.entry.interview.id}`,
              props.redirectUri,
            )}
            text='View Interview'
            icon='bi-play-btn'
          />
        )}
      </div>
    </GenericEntry>
  );
}

function InterviewAutomatedSelfScheduleEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar2-plus'
      iconClassName='bg-light-info'
      title={
        <>
          An automatic self-scheduling link was sent to{' '}
          <strong>{props.application.candidate.name}</strong> for the stage{' '}
          <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function InterviewSelfScheduleEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: Interview;
  },
) {
  return (
    <GenericEntry
      icon='bi-calendar2-plus'
      iconClassName='bg-light-info'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> shared a self-scheduling
          link with <strong>{props.application.candidate.name}</strong> for the
          stage <strong>{props.entry.interview.interviewStage.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}

function FeedEntry(
  props: EntryPropTypes & {
    application: Application;
    entry: ApplicationFeed;
    redirectUri?: string;
    applicationStylesPath: string;
  },
) {
  switch (props.entry.entryType) {
    case ApplicationFeedType.Note:
      return <NoteEntry {...props} entry={props.entry as Note} />;
    case ApplicationFeedType.Applied:
      return <AppliedEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.StageMove:
      return <StageMoveEntry {...props} entry={props.entry as History} />;
    case ApplicationFeedType.ProfileUpdate:
      return <ProfileUpdateEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.Rejection:
      return <RejectionEntry {...props} entry={props.entry as History} />;
    case ApplicationFeedType.Unrejection:
      return <UnrejectionEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.ScorecardFill:
    case ApplicationFeedType.ScorecardEdit:
      return (
        <ScorecardFillEntry
          {...props}
          entry={props.entry as ScorecardFill}
          editedScorecard={
            props.entry.entryType == ApplicationFeedType.ScorecardEdit
          }
        />
      );
    case ApplicationFeedType.Details:
      return <DetailsEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.Offer:
      return <Offer {...props} entry={props.entry as OfferFeed} />;
    case ApplicationFeedType.OfferAccepted:
      return <OfferAccepted {...props} entry={props.entry as OfferFeed} />;
    case ApplicationFeedType.OfferRejected:
      return <OfferRejected {...props} entry={props.entry as OfferFeed} />;
    case ApplicationFeedType.OfferRevoked:
      return <OfferRevoked {...props} entry={props.entry as OfferFeed} />;
    case ApplicationFeedType.PushedToHris:
      return <PushedToHrisEntry {...props} entry={props.entry} />;
    case ApplicationFeedType.InterviewSchedule:
      return (
        <InterviewScheduleEntry
          {...props}
          entry={props.entry as Interview}
          redirectUri={props.redirectUri}
        />
      );
    case ApplicationFeedType.InterviewScheduleByCandidate:
      return (
        <InterviewScheduleByCandidateEntry
          {...props}
          entry={props.entry as Interview}
        />
      );
    case ApplicationFeedType.InterviewReschedule:
      return (
        <InterviewRescheduleEntry {...props} entry={props.entry as Interview} />
      );
    case ApplicationFeedType.InterviewCancel:
      return (
        <InterviewCancelEntry {...props} entry={props.entry as Interview} />
      );
    case ApplicationFeedType.InterviewComplete:
      return (
        <InterviewCompleteEntry {...props} entry={props.entry as Interview} />
      );
    case ApplicationFeedType.InterviewAutomatedSelfSchedule:
      return (
        <InterviewAutomatedSelfScheduleEntry
          {...props}
          entry={props.entry as Interview}
        />
      );
    case ApplicationFeedType.InterviewSelfSchedule:
      return (
        <InterviewSelfScheduleEntry
          {...props}
          entry={props.entry as Interview}
        />
      );
    case ApplicationFeedType.PulseSurveyShare:
      return (
        <PulseSurveyShareEntry
          {...props}
          entry={props.entry as PulseSurveyActions}
        />
      );
    case ApplicationFeedType.PulseSurveySent:
      return (
        <PulseSurveySentEntry
          {...props}
          entry={props.entry as PulseSurveyActions}
        />
      );
    case ApplicationFeedType.PulseSurveyCompleted:
      return (
        <PulseSurveyCompletedEntry
          {...props}
          entry={props.entry as PulseSurveyActions}
        />
      );
    case ApplicationFeedType.RcSurveyShared:
      return <RcSurveySharedEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.RcSurveySent:
      return <RcSurveySentEntry {...props} entry={props.entry as Simple} />;
    case ApplicationFeedType.RcSurveyCompleted:
      return (
        <RcSurveyCompletedEntry {...props} entry={props.entry as Simple} />
      );
    case ApplicationFeedType.RcSelfAssessmentCompleted:
      return (
        <RcSelfAssessmentCompletedEntry
          {...props}
          entry={props.entry as Simple}
        />
      );
    case ApplicationFeedType.BackgroundCheckInitiated:
      return (
        <BackgroundCheckInitiatedEntry
          {...props}
          entry={props.entry as Simple}
        />
      );
    case ApplicationFeedType.BackgroundCheckCompleted:
      return (
        <BackgroundCheckCompletedEntry
          {...props}
          entry={props.entry as Simple}
        />
      );
    case ApplicationFeedType.EmailSent:
      return (
        <EmailSentEntry
          {...props}
          entry={props.entry as ApplicationFeedEmail}
          applicationStylesPath={props.applicationStylesPath}
        />
      );
    case ApplicationFeedType.EmailReceived:
      return (
        <EmailReceivedEntry
          {...props}
          entry={props.entry as ApplicationFeedEmail}
          applicationStylesPath={props.applicationStylesPath}
        />
      );
    case ApplicationFeedType.TriggerEmailSent:
      return (
        <TriggerEmailSent {...props} entry={props.entry as TriggerActions} />
      );
    case ApplicationFeedType.TriggerRejection:
      return (
        <AutoRejectEntry {...props} entry={props.entry as TriggerActions} />
      );
  }
}

export function ActivityContent(props: PropTypes) {
  return (
    <div className='d-flex flex-column fs-5'>
      {props.applicationFeeds.map((entry, i) => (
        <FeedEntry
          key={i}
          redirectUri={props.redirectUri}
          application={props.application}
          currentUser={props.currentUser}
          entry={entry}
          setAlert={props.setAlert}
          reloadApplication={props.reloadApplication}
          isLastItemInPage={i === props.applicationFeeds.length - 1}
          emailAccountIntegration={props.emailAccountIntegration}
          applicationStylesPath={props.applicationStylesPath}
        />
      ))}
    </div>
  );
}
