import { useState, useEffect } from 'react';
import { Size } from './types/Size';

const GRID_BREAKPOINTS: Record<Size, number> = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};

export function useScreenSize(): Size {
  const [size, setSize] = useState<Size>('sm');

  useEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      const newSize = Object.keys(GRID_BREAKPOINTS).find(
        (key: Size) => width >= GRID_BREAKPOINTS[key],
      ) as Size;

      if (newSize) {
        setSize(newSize);
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export function sizeSmallerThan(value: Size, reference: Size): boolean {
  return GRID_BREAKPOINTS[value] < GRID_BREAKPOINTS[reference];
}
