import React from 'react';
import { ApprovalAssignedTo } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { LocationOrDepartmentSelectInput } from './LocationOrDepartmentSelectInput';
import classNames from 'classnames';
import { AlertMessage } from '../../../../../components/AlertMessage';

interface PropTypes {
  assignedTo: ApprovalAssignedTo;
  disabledLocationIds: number[];
  selectedIds: number[];
  disabledDepartmentIds: number[];
  organizationFlowExists: boolean;
  readOnly?: boolean;
  onAssignedToChange: (value: ApprovalAssignedTo) => void;
  setSelectedIds: (ids: number[]) => void;
}

function RadioInput(props: {
  checked: boolean;
  disabled: boolean;
  label: string;
  onClick: () => void;
}) {
  return (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        checked={props.checked}
        onChange={props.onClick}
        disabled={props.disabled}
      />
      <label
        className={classNames(
          'form-check-label',
          props.disabled && 'text-gray-700',
        )}
        onClick={props.disabled ? null : props.onClick}
      >
        {props.label}
      </label>
    </div>
  );
}

function SelectOrganizationAlert() {
  return (
    <AlertMessage
      text={
        <>
          Start by creating a default approval flow for your organisation. You
          can then set up department or location specific approval flows.
        </>
      }
      icon={{
        name: 'bi-info-circle',
      }}
      hasOverlay={false}
      textClassName='ms-2h'
      className='bg-light-info fs-5 mt-4 pt-2 pb-2'
    />
  );
}

export function AssignedToSelector(props: PropTypes) {
  const onClickOrganization = () => props.onAssignedToChange('organization');
  const onClickDepartment = () => props.onAssignedToChange('department');
  const onClickLocation = () => props.onAssignedToChange('location');

  return (
    <>
      <div className='fw-semibold fs-5 mt-4 mb-1'>Assign to</div>
      <div className='d-flex align-items-center fs-5'>
        <RadioInput
          checked={props.assignedTo === 'organization'}
          disabled={true}
          label='Organization'
          onClick={onClickOrganization}
        />
        <RadioInput
          checked={props.assignedTo === 'location'}
          disabled={!props.organizationFlowExists || props.readOnly}
          label='Location'
          onClick={onClickLocation}
        />
        <RadioInput
          checked={props.assignedTo === 'department'}
          disabled={!props.organizationFlowExists || props.readOnly}
          label='Department'
          onClick={onClickDepartment}
        />
      </div>
      <LocationOrDepartmentSelectInput
        assignedTo={props.assignedTo}
        readOnly={props.readOnly}
        selectedIds={props.selectedIds}
        disabledLocationIds={props.disabledLocationIds}
        disabledDepartmentIds={props.disabledDepartmentIds}
        setSelectedIds={props.setSelectedIds}
      />
      {!props.organizationFlowExists && <SelectOrganizationAlert />}
    </>
  );
}
