import React, { ReactNode } from 'react';

interface PropTypes {
  title: string;
  titleChip?: ReactNode;
  subtitle: string;
}

export function Header(props: PropTypes) {
  return (
    <>
      <div className='d-flex flex-nowrap align-items-center gap-2 mb-2'>
        <h4 className='fw-semibold mb-0'>{props.title}</h4>
        {props.titleChip}
      </div>
      <p className='text-dark-200 fw-normal fs-5'>{props.subtitle}</p>
    </>
  );
}
