import React from 'react';
import { Nps } from '../../../../../entities/survey_engine/Nps';
import { formatNumber } from '../../../../../utils/formatNumber';
import {
  CPS_RATING_LEGEND,
  CPS_RATING_LEGEND_GAUGE_CHART,
} from '../../../RatingLegend';
import { GaugeChart } from '../../../GaugeChart';
import { CircleChart } from '../../../CircleChart';

interface PropTypes {
  nps: Nps;
  standardNpsCalculation: boolean;
  hasCandidatePulseCnps?: boolean;
}

export function OverallNpsCharts(props: PropTypes) {
  return props.standardNpsCalculation ? (
    <GaugeChart
      valueAsPercentage={parseInt(props.nps.value)}
      text={formatNumber(Number(props.nps.value)).toString()}
      rating_legend={CPS_RATING_LEGEND_GAUGE_CHART}
      hasCandidatePulseCnps={props.hasCandidatePulseCnps}
    />
  ) : (
    <CircleChart
      valueAsPercentage={parseInt(props.nps.value, 10) * 10}
      text={Number(props.nps.value).toFixed(1)}
      rating_legend={CPS_RATING_LEGEND}
    />
  );
}
