import React, { useEffect } from 'react';
import { Controller, FormState, useForm } from 'react-hook-form';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import { SelectOption } from '../../../../components/Select';
import { Col, Row } from 'reactstrap';
import { Chip } from '../../../../components/Chip';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { CandidateResumeInput } from '../CandidateResumeInput';
import { CandidateCoverLetterInput } from '../CandidateCoverLetterInput';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { CandidateAcademicInput } from '../CandidateAcademicInput';
import { ApplicationSalaryInput } from '../ApplicationSalaryInput';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { SourceInput } from '../SourceInput';
import { CandidateCurrentPositionInput } from '../CandidateCurrentPositionInput';
import { CandidateYearsExperienceInput } from '../CandidateYearsExperienceInput';
import { CandidatePortfolioInput } from '../CandidatePortfolioInput';
import { CandidateDetailInput } from '../CandidateDetailInput';
import { Source } from '../../../../entities/applicant_tracking/Source';
import { HasWorkingVisaInput } from '../HasWorkingVisaInput';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { FormErrorMessage } from '../../../../components/FormErrorMessage';
import { Application } from '../../../../entities/applicant_tracking/Application';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { HiringMember } from '../../../../entities/applicant_tracking/HiringMember';
import { ReferralInputData } from '../ReferralInput/ReferralInputData';
import { ReferralKnowledge } from '../ReferralInput/ReferralConsentKnowledge';

const REFERRAL_VALUE = 'referral_not_actually_a_source';

interface PropTypes {
  candidateId: number;
  applicationId?: number;
  jobPosts: JobPost[] | null;
  sources: Source[] | null;
  hiringMembers: HiringMember[] | null;
  onSuccessfulSubmit: (application: Application) => void;
  headerFactory: (formState: FormState<CandidateDto>) => React.ReactNode;
  candidateDto?: CandidateDto;
}

export function ApplicationForm(props: PropTypes) {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    watch,
    resetField,
    formState,
    setError,
  } = useForm<CandidateDto>({
    defaultValues: props.candidateDto,
    mode: 'onChange',
  });

  async function onSubmit(data: CandidateDto, _e: React.SyntheticEvent) {
    try {
      const application = await upsert(
        props.candidateId,
        props.applicationId,
        data,
      );
      props.onSuccessfulSubmit(application);
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }
  }

  const jobPostOptions = props.jobPosts?.map((jobPost) =>
    mapJobPostToOption(jobPost),
  );

  const jobPostId = watch('jobPosts')?.[0];
  const jobPost = props.jobPosts?.find(
    (jobPost) => jobPost.id.toString() === jobPostId?.toString(),
  );

  const hiringMemberOptions = props.hiringMembers?.map((hiringMember) => ({
    value: hiringMember.id.toString(),
    label: hiringMember.name,
  }));
  const initSourceId =
    props.candidateDto?.referrerId || props.candidateDto?.referrerEmail
      ? REFERRAL_VALUE
      : props.candidateDto?.sourceId;
  const watchResumeContent = watch('resumeContent');

  useEffect(() => {
    setValue('sourceId', initSourceId);
  }, [initSourceId]);

  useEffect(() => {
    if (!formState.dirtyFields.resumeContent) return;

    setValue('resumeId', null);
  }, [watchResumeContent]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormErrorMessage error={formState.errors} />
      {props.headerFactory(formState)}
      <div className='d-flex flex-column gap-4'>
        <Row>
          <Col xs={3}>
            <Controller
              name='jobPosts'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <LabelledSelect
                  options={jobPostOptions}
                  selected={jobPostOptions?.find(
                    (option) => option.value === field.value?.[0]?.toString(),
                  )}
                  label='Job'
                  onChange={(newOption) => field.onChange([newOption.value])}
                  className='fs-5'
                  labelClassName='fw-semibold'
                  mandatory={true}
                  disabled={props.applicationId != null}
                  isLoading={props.jobPosts == null}
                />
              )}
            />
          </Col>
        </Row>
        <CandidateResumeInput
          formRegister={register}
          getValues={getValues}
          setValue={setValue}
        />
        <CandidateCoverLetterInput
          formRegister={register}
          getValues={getValues}
          setValue={setValue}
        />
        <CandidateAcademicInput formControl={control} />
        <ApplicationSalaryInput
          width={6}
          formHeaderClassNames={'fw-semibold mb-2'}
          formControl={control}
          watch={watch}
          formRegister={register}
          formResetField={resetField}
          errors={formState.errors}
          setValue={setValue}
          getValues={getValues}
          shouldRender={true}
          disabled={false}
          required={false}
        />
        <div>
          <FormFieldHeader
            fieldName={'Candidate Source'}
            classNames={'fw-semibold mb-2'}
            isRequired={true}
          />
          <Row>
            <Col xs={3}>
              <SourceInput
                label={'Where did you find the candidate?'}
                labelClassName={'text-dark-200 fs-6'}
                sources={props.sources}
                formControl={control}
                shouldRender={true}
                disabled={false}
                initSourceId={initSourceId}
                required={true}
                isLoading={props.sources == null}
                extraOptions={[
                  {
                    label: 'Referrals',
                    options: [
                      { label: 'Employee Referral', value: REFERRAL_VALUE },
                    ],
                  },
                ]}
              />
            </Col>
          </Row>
          {watch('sourceId')?.toString() === REFERRAL_VALUE && (
            <div className='mt-3'>
              <ReferralKnowledge formRegister={register} />
              <FormFieldHeader
                classNames='mt-4 fs-5 fw-semibold'
                fieldName='Who gets the credit'
                isRequired={true}
              />
              <ReferralInputData
                formControl={control}
                hiringMemberOptions={hiringMemberOptions}
                errors={formState.errors}
                formRegister={register}
                watch={watch}
                getValues={getValues}
                isLoading={props.hiringMembers == null}
                formResetField={resetField}
              />
            </div>
          )}
        </div>
        <CandidateCurrentPositionInput formRegister={register} />
        <CandidateYearsExperienceInput formControl={control} />
        <HasWorkingVisaInput
          label={`The candidate has legal right to work in ${
            jobPost?.location?.country ?? 'job location'
          }?`}
          formRegister={register}
          formControl={control}
          shouldRender={true}
          required={false}
          disabled={false}
        />
        <CandidatePortfolioInput formRegister={register} />
        <CandidateDetailInput formRegister={register} getValues={getValues} />
      </div>
    </form>
  );
}

function mapJobPostToOption(jobPost: JobPost): SelectOption {
  return {
    value: jobPost.id.toString(),
    label: jobPost.name,
    subLabel: (
      <Col xs='4'>
        <Chip className='d-block mw-100 text-truncate text-primary bg-gray fw-semibold'>
          {jobPost.location.name}
        </Chip>
      </Col>
    ),
  };
}

async function upsert(
  candidateId: number,
  applicationId: number,
  data: CandidateDto,
) {
  const applications = await ApplicationService.upsert(
    {
      candidateId: candidateId,
      resumeFilename: data.resumeFilename,
      resumeContent: data.resumeContent,
      resumeId: data.resumeId,
      coverLetterFilename: data.coverLetterFilename,
      coverLetterContent: data.coverLetterContent,
      company: data.company,
      jobTitle: data.jobTitle,
      yearsExperience: data.yearsExperience,
      academicDegree: data.academicDegree,
      linkedinUrl: data.linkedinUrl,
      portfolioUrl: data.portfolioUrl,
      jobPosts: data.jobPosts,
      salaryExpectation: data.salaryExpectation,
      salaryCurrency: data.salaryCurrency,
      candidateDetail: data.candidateDetail,
      sourceId: data.sourceId,
      hasWorkingVisa: data.hasWorkingVisa,
      referralKnowledge:
        data.sourceId.toString() == REFERRAL_VALUE
          ? data.referralKnowledge
          : null,
      referrerId:
        data.sourceId.toString() == REFERRAL_VALUE ? data.referrerId : null,
      referrerEmail:
        data.sourceId.toString() == REFERRAL_VALUE ? data.referrerEmail : null,
      // Meta
      skipDeletion: true,
    },
    applicationId,
  );
  return applications[0];
}
