import * as queryString from 'query-string';

export function getNamesAndIdsFromHref(
  href: string,
  idsKey: string | number,
  namesKey: string | number,
) {
  const parsed = queryString.parse(href, { arrayFormat: 'bracket' });

  const ids = parsed[idsKey] || [];
  const names = parsed[namesKey] || [];

  return ids.map((id, index) => ({
    value: id,
    label: names[index],
  }));
}
